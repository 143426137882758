.mu-footer {
  display: none;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
  color: #ffffff;
  background-color: #171717;

  a {
    color: $gray-700;
    transition: $transition;

    &:hover {
      color: $gray-500;
      text-decoration: none;
    }
  }

  .footer-title {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 15px;
    margin-bottom: 20px;

    h4 {
      font-size: 17px;
      margin-bottom: 0;
    }

    h5 {
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 0;
      padding-left: 15px;
    }
  }

  .footer-contact {
    padding-right: 95px;

    .footer-contact-content {
      h5 {
        font-size: 16px;
        letter-spacing: 1px;
      }

      h4 {
        font-size: 18px;
        margin-bottom: 0;
        padding-left: 10px;
      }

      .footer-icon {
        padding: 10px 0;
      }
    }
  }

  .footer-nav {
    padding-left: 95px;
    padding-right: 25px;

    a {
      display: block;
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 10px;
    }
  }

  .footer-erweima {
    padding-left: 150px;

    .erweima {
      margin: 0 auto;
      width: 120px;
      height: 120px;
      overflow: hidden;
    }

    p {
      margin-bottom: 0;
      font-size: 15px;
      padding-top: 10px;
    }
  }

  .footer-bottom {
    background-color: #000000;

    p {
      margin-bottom: 0;
    }
  }

  // 992px以下不显示
  @media (min-width: 992px) {
    display: flex;
  }

  //1400以下
  @media (max-width: 1399px) {
    .footer-contact {
      padding-right: 60px;

    }

    .footer-nav {
      padding-left: 60px;
      padding-right: 15px;
    }

    .footer-erweima {
      padding-left: 100px;

    }

    .footer-divider {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $gray-800;
    }
  }
}

.mu-footer-wap {
  padding: $spacer;
  font-size: $font-size-sm;
  text-align: center;
  color: $gray-600;
  margin-bottom: 3rem;

  p {
    margin-bottom: 0;
  }

  a {
    color: $gray-900;
    font-weight: bold;
  }

  // 992px以上不显示
  @media (min-width: 992px) {
    display: none;
  }
}