.mu-sidebtn {
  position: fixed;
  top: 50%;
  right: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: $sidebtn-border-radius;
  box-shadow: $box-shadow-sm;
  z-index: 2;

  &__item {
    display: block;
    width: $sidebtn-item-width;
    height: $sidebtn-item-height;
    line-height: $sidebtn-item-line-height;
    font-size: 14px;
    text-align: center;
    color: $sidebtn-color;
    background-color: $sidebtn-bg-color;
    border-width: 1px;
    border-style: solid;
    border-color: $sidebtn-border-color;
    border-top-width: 0;
    transition: $transition;

    &:first-child {
      border-top-width: 1px;
    }

    i {
      font-size: 16px;
    }

    &:hover {
      color: $sidebtn-hover-color;
      background-color: $sidebtn-hover-bg-color;
      border-color: $sidebtn-hover-border-color;
    }
  }
}

.scroll-to-top {
  display: none;
}
