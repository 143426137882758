.mu-list-group {
  a.list-group-item {
    display: flex;
    flex-direction: row;
    padding: 1.5rem;
    color: $dark;
    border-color: $gray-200;
    transition: $transition;

    .list-img-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 300px;
      margin-right: 1.5rem;
      .list-img {
        width: 100%;
      }
    }

    .list-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .list-title {
        margin-bottom: 0.75rem;
        transition: $transition;
      }
      .list-text {
        color: $gray-600;
        margin-bottom: 0.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .list-more {
        display: inline-block;
        padding: 0.25rem 1.25rem;
        border: 1px solid $gray-200;
        color: $gray-600;
        transition: $transition;
      }
    }

    &:hover {
      color: $dark;
      text-decoration: none;
      .list-body {
        .list-title {
          color: $primary;
        }
        .list-more {
          color: $white;
          background-color: $primary;
          border-color: $primary;
        }
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      .list-img-left {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

.mu-list-group-card {
  a.list-group-item {
    margin-bottom: 2rem;
    border: 1px solid $gray-200;

    &:hover {
      box-shadow: $box-shadow;
    }
  }
}

.mu-list-group-flush {
  a.list-group-item {
    padding: 1.5rem 0;
    border-left: 0;
    border-right: 0;
    &:first-child {
      border-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
