.card {
  transition: $transition;

  &:hover {
    box-shadow: $box-shadow;
  }

  .card-img-top {
    overflow: hidden;
  }

  @media (max-width: 992px) {
    box-shadow: none;
  }
}

a.card {
  color: $gray-900;

  .card-text {
    color: $gray-600;
  }

  &:hover {
    text-decoration: none;
  }
}

.mu-card {
  margin-bottom: 2rem;

  @media (max-width: 992px) {
    margin-bottom: 1rem;
    .card-body {
      padding: 0.5rem;

      .card-title {
        font-size: 16px;
        margin-bottom: 0.5rem;
        height: 20px;
      }

      .card-text {
        font-size: 12px;
        margin-bottom: 0.5rem;

        &:last-child {
          line-height: initial;
          height: initial;
          margin-bottom: 0;
        }
      }
    }
  }
}

.mu-row {
  margin-right: -0.75rem;
  margin-left: -0.75rem;

  [class^="col-"] {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  @media (max-width: 768px) {
    margin-right: -0.5rem;
    margin-left: -0.5rem;

    [class^="col-"] {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}
