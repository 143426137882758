.mu-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: $white;
  background: linear-gradient(90deg, rgba(232, 228, 225, 0.4), rgba(89, 89, 89, .8));
  transition: all .36s ease;

  // 导航logo
  .navbar-brand {
    img {
      height: 30px;

      @media (min-width: 992px) {
        height: 54px;
      }
    }
  }

  .navbar-nav {


    .nav-item {
      position: relative;

      &:hover {
        .dropDown {
          max-height: 500px;
          opacity: 1;
          transition: all .6s ease;
        }
      }

      &:nth-child(1) {
        .nav-link {

          &:before {
            display: none;
          }
        }
      }

      .nav-link {
        color: #ffffff;
        font-size: 17px;
        height: 114px;
        line-height: 114px;
        padding: 0 1.25rem;
        transition: $transition;
        letter-spacing: 1px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 57px;
          left: -3px;
          width: 12px;
          height: 1px;
          background: #ffffff;
        }

      }

      .dropDown {
        transition: $transition;
        padding: 0;
        width: 100%;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        box-sizing: border-box;
        position: absolute;
        top: 114px;
        left: 0;
        background-color: rgba($color: #000000, $alpha: .5);
        z-index: 9999;

        a {
          border-radius: 0;
          transition: $transition;
          padding: 15px 0;
          font-size: 16px;
          text-decoration: none;
          text-align: center;

          p {
            margin-bottom: 0;
            color: #ffffff;
          }

          &:hover {
            transition: all .36s ease;
            background-color: rgba($color: #000000, $alpha: .7);

            p {
              color: #ffffff;
            }
          }
        }
      }

      // 导航链接选中状态
      &.active {
        .nav-link {
          // color: $primary;
        }
      }


    }
  }

  .dropdown:hover {
    &>.nav-link {
      color: $primary;
    }
  }

  // 导航下拉菜单
  .dropdown-menu {
    margin-top: -0.5rem;

    .dropdown-item {
      font-size: 15px;
    }
  }

  // 联系电话
  // 992px以下会隐藏
  .navbar-phone {
    display: none;
    font-size: 17px;
    color: $gray-800;
    padding: 0 1.25rem;

    i {
      font-size: 15px;
      color: $gray-600;
      margin-right: 5px;
    }

    @media (min-width: 992px) {
      display: block;
    }
  }

  // 导航菜单展开按钮
  // 992px以下会显示
  .navbar-toggler {
    height: 50px;
    line-height: 50px;
    padding: 0;
    border: 0;
    outline: 0;

    @media (min-width: 992px) {
      height: 64px;
      line-height: 64px;
    }
  }

  // 导航菜单
  // 992px以下会隐藏
  .navbar-menu {
    display: none;
    align-items: center;
    flex-direction: row;

    @media (min-width: 992px) {
      display: flex;
    }
  }
}

.change {
  background: linear-gradient(90deg, rgba(232, 228, 225, 1), rgba(89, 89, 89, 1));
  transition: all .36s ease;
}