body {
  width: 100%;
  min-height: 100vh;
  font-family: "Microsoft YaHei", $font-family-base;
  // 开启追悼模式
  // filter: grayscale(95%);
}

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  // padding-top: 50px;

  // @media (min-width: 992px) {
  //   padding-top: 64px;
  // }
}

.page-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: block;
  background-color: rgba($black, 1);
  transition: all 0.3s ease;
  opacity: 0;
  z-index: 1000;
}

.page-fill {
  flex: 1;
}

.page-banner {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  position: relative;

  img {
    width: 100%;
  }

  .page-banner-container {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    user-select: none;
  }

  .page-banner-content {
    color: $white;


    h1 {
      font-size: 50px;
      font-weight: bold;
      letter-spacing: 7px;
    }

    h4 {
      font-size: 25px;
      text-transform: uppercase;

    }
  }

  .mu-breadcrumb {
    position: absolute;
    bottom: 0;

    hr {
      background-color: #ffffff;
      width: 100px;
      margin: 20px 0;
    }

    .breadcrumb {
      background: none;
      color: #ffffff;
      font-size: 16px;

      .breadcrumb-item {
        color: #ffffff;
        font-size: 16px;

        a {
          color: #ffffff;
        }

        &::before {
          color: #ffffff;
        }
      }
    }
  }

  //1400以下
  @media (max-width: 1399px) {

    .page-banner-content {

      h1 {
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 5px;
      }

      h4 {
        font-size: 20px;
      }
    }

    .mu-breadcrumb {

      hr {
        margin: 10px 0;
      }

      .breadcrumb {
        background: none;
        color: #ffffff;
        font-size: 15px;

        .breadcrumb-item {
          color: #ffffff;
          font-size: 15px;
        }
      }
    }

  }

  //992以下
  @media (max-width: 992px) {
    .img {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 50vw;
      width: 100%;
      overflow: hidden;

      img {
        width: auto;
        height: 100%;
      }
    }


    .page-banner-content {

      h1 {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 4px;
      }

      h4 {
        font-size: 14px;
        text-transform: uppercase;

      }
    }

    .mu-breadcrumb {
      hr {
        background-color: #ffffff;
        width: 15vw;
        margin: 0 0;
      }

      .breadcrumb {
        font-size: 13px;
        padding: 2vw 0;

        .breadcrumb-item {
          font-size: 13px;
        }
      }
    }
  }
}



.page-title {
  text-align: center;
}

.page-content {
  padding: 0;
  margin: 0;
  font-size: 15px;
  text-indent: 2em;
  margin-bottom: 1rem;

  img {
    display: inherit;
    max-width: 100%;
    height: auto;
  }
}

// 页面内上下页切换按钮
.page-arrows {
  display: flex;
  background-color: $light;

  .page-arrow {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    padding: 0.75rem 1rem;
    color: $gray-800;
    background-color: $light;
    font-size: 15px;
    overflow: hidden;
    transition: $transition;

    i {
      font-size: 17px;
    }

    &:hover {
      color: #ffffff;
      background-color: $primary;
      text-decoration: none;
    }

    &.disabled {
      color: $gray-500;
      background-color: $light;
      cursor: not-allowed;
      pointer-events: none;

      &:hover {
        color: $gray-500;
        background-color: $light;
      }
    }
  }
}

// 当body增加modal-open时，header、sidebtn、menu因fixed属性
// 会导致以上三个组件产生滑动，统一增加right:17px;
// right属性增加17px
@media (min-width: 992px) {
  body.modal-open {

    .mu-header,
    .mu-menu {
      right: 17px;
    }

    .mu-sidebtn {
      right: 33px;
    }
  }
}