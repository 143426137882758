// Colors
$gray-100: #fafafa;
$gray-200: #f5f5f5;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;

$blue: #409EFF;
$azure: #45aaf2;
$indigo: #6574cd;
$purple: #a55eea;
$pink: #f66d9b;
$red: #cd201f;
$orange: #fd9644;
$yellow: #f1c40f;
$lime: #7bd235;
$green: #5eba00;
$teal: #2bcbba;
$cyan: #17a2b8;

$text-muted: $gray-600;
$text-muted-dark: $gray-700;

$white: #fff;
$black: #000;

$primary: #cbb796;
$secondary: #32373b;
$success: $green;
$info: $azure;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$colors: ("blue": $blue,
  "azure": $azure,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "lime": $lime,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
);

$theme-colors: ("primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

// Fonts
$font-size-base: 0.875rem;
$font-size-lg: 1rem;
$font-size-sm: 0.75rem;

$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl:1720px) !default;


// Container
$container-max-widths: (sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl:1720px) !default;

// Box Shadow

// Transition
$transition: all 0.3s ease;

// Breadcrumb
$breadcrumb-divider: quote(">");
$breadcrumb-padding-y: 1rem;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: $white;
$breadcrumb-border-radius: 0;

// Dropdowns
$dropdown-min-width: 12rem;
$dropdown-padding-y: 0;
$dropdown-color: $gray-800;
$dropdown-border-width: 0;
$dropdown-link-active-color: $gray-800;
$dropdown-link-active-bg: $gray-100;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $gray-500;

// Sidebtn
$sidebtn-border-radius: 0px;
$sidebtn-item-width: 40px;
$sidebtn-item-height: 40px;
$sidebtn-item-line-height: 40px;
$sidebtn-color: $white;
$sidebtn-bg-color: $primary;
$sidebtn-border-color: $primary;
$sidebtn-hover-color: $white;
$sidebtn-hover-bg-color: darken($primary, 5%);
$sidebtn-hover-border-color: $primary;

// Navbar
$navbar-padding-y: 0;

// Cards
$card-border-radius: 0;
$card-border-color: $gray-200;

// Forms
$input-border-color: $gray-300;
$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$input-focus-border-color: $gray-400;
$custom-select-border-radius: 0;

// List group
$list-group-border-radius: 0;