.mu-pagination {
  display: none;

  .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 0;

    .page-item {
      margin: 0 0.25rem;

      &.active .page-link {
        color: #ffffff;
        border-color: $primary;
        background-color: $primary;
      }

      &.disabled .page-link {
        color: $gray-500;
      }

      .page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 38px;
        height: 38px;
        padding: 0;
        color: $primary;
        border-color: $gray-200;
        border-radius: 0;
        transition: $transition;

        &:hover {
          color: #ffffff;
          background-color: $primary;
          border-color: $primary;
        }

        &:hover,
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      &:first-child .page-link,
      &:last-child .page-link {
        border-radius: 0;
        i {
          font-size: 17px;
        }
      }
    }
  }

  @media (min-width: 992px) {
    display: block;
  }
}

.mu-pagination-wap {
  .pagination {
    display: flex;
    margin-bottom: 0;

    .page-item {
      flex: 1;

      .page-link {
        color: $gray-800;
        text-align: center;
      }

      &.disabled .page-link {
        color: $gray-500;
      }
    }
  }

  @media (min-width: 992px) {
    display: none;
  }
}
