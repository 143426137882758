@charset "UTF-8";
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #409EFF;
  --indigo: #6574cd;
  --purple: #a55eea;
  --pink: #f66d9b;
  --red: #cd201f;
  --orange: #fd9644;
  --yellow: #f1c40f;
  --green: #5eba00;
  --teal: #2bcbba;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #757575;
  --gray-dark: #424242;
  --azure: #45aaf2;
  --lime: #7bd235;
  --primary: #cbb796;
  --secondary: #32373b;
  --success: #5eba00;
  --info: #45aaf2;
  --warning: #f1c40f;
  --danger: #cd201f;
  --light: #fafafa;
  --dark: #424242;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --breakpoint-xxxl: 1720px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212121;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #cbb796;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #b29463;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #757575;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.1875rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.53125rem; }

h4, .h4 {
  font-size: 1.3125rem; }

h5, .h5 {
  font-size: 1.09375rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #757575; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #757575; }

code {
  font-size: 87.5%;
  color: #f66d9b;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212121;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212121; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 576px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 768px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 992px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }
  @media (min-width: 1400px) {
    .container {
      max-width: 1400px; } }
  @media (min-width: 1720px) {
    .container {
      max-width: 1720px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl, .container-xxxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 576px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 768px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 992px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1400px; } }

@media (min-width: 1720px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl, .container-xxxl {
    max-width: 1720px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto, .col-xxxl-1, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl,
.col-xxxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1720px) {
  .col-xxxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxxl-first {
    order: -1; }
  .order-xxxl-last {
    order: 13; }
  .order-xxxl-0 {
    order: 0; }
  .order-xxxl-1 {
    order: 1; }
  .order-xxxl-2 {
    order: 2; }
  .order-xxxl-3 {
    order: 3; }
  .order-xxxl-4 {
    order: 4; }
  .order-xxxl-5 {
    order: 5; }
  .order-xxxl-6 {
    order: 6; }
  .order-xxxl-7 {
    order: 7; }
  .order-xxxl-8 {
    order: 8; }
  .order-xxxl-9 {
    order: 9; }
  .order-xxxl-10 {
    order: 10; }
  .order-xxxl-11 {
    order: 11; }
  .order-xxxl-12 {
    order: 12; }
  .offset-xxxl-0 {
    margin-left: 0; }
  .offset-xxxl-1 {
    margin-left: 8.33333%; }
  .offset-xxxl-2 {
    margin-left: 16.66667%; }
  .offset-xxxl-3 {
    margin-left: 25%; }
  .offset-xxxl-4 {
    margin-left: 33.33333%; }
  .offset-xxxl-5 {
    margin-left: 41.66667%; }
  .offset-xxxl-6 {
    margin-left: 50%; }
  .offset-xxxl-7 {
    margin-left: 58.33333%; }
  .offset-xxxl-8 {
    margin-left: 66.66667%; }
  .offset-xxxl-9 {
    margin-left: 75%; }
  .offset-xxxl-10 {
    margin-left: 83.33333%; }
  .offset-xxxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212121; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e0e0e0; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e0e0e0; }
  .table tbody + tbody {
    border-top: 2px solid #e0e0e0; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e0e0e0; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e0e0e0; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212121;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f0ebe2; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #e4dac8; }

.table-hover .table-primary:hover {
  background-color: #e7dfd1; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #e7dfd1; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c6c7c8; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #949799; }

.table-hover .table-secondary:hover {
  background-color: #b9babb; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b9babb; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d2ecb8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #abdb7a; }

.table-hover .table-success:hover {
  background-color: #c5e7a4; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #c5e7a4; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #cbe7fb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #9ed3f8; }

.table-hover .table-info:hover {
  background-color: #b3dcf9; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b3dcf9; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbeebc; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f8e082; }

.table-hover .table-warning:hover {
  background-color: #fae8a4; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fae8a4; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f1c1c0; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e58b8b; }

.table-hover .table-danger:hover {
  background-color: #ecacab; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ecacab; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfc; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cacaca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9d9d9d; }

.table-hover .table-dark:hover {
  background-color: #bdbdbd; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bdbdbd; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #424242;
  border-color: #555555; }

.table .thead-light th {
  color: #616161;
  background-color: #f5f5f5;
  border-color: #e0e0e0; }

.table-dark {
  color: #fff;
  background-color: #424242; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #555555; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

@media (max-width: 1719.98px) {
  .table-responsive-xxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #616161;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #616161; }
  .form-control:focus {
    color: #616161;
    background-color: #fff;
    border-color: #bdbdbd;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(203, 183, 150, 0.25); }
  .form-control::placeholder {
    color: #757575;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #f5f5f5;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #616161;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.75rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212121;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #757575; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #5eba00; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(94, 186, 0, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #5eba00;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235eba00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #5eba00;
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #5eba00;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23424242' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235eba00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #5eba00;
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #5eba00; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #5eba00; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #5eba00; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #78ed00;
  background-color: #78ed00; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #5eba00; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #5eba00; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #5eba00;
  box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #cd201f; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(205, 32, 31, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #cd201f;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23cd201f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23cd201f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #cd201f;
    box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #cd201f;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23424242' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23cd201f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23cd201f' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #cd201f;
    box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #cd201f; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #cd201f; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #cd201f; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e23e3d;
  background-color: #e23e3d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #cd201f; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #cd201f; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #cd201f;
  box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212121;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212121;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(203, 183, 150, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212121;
  background-color: #cbb796;
  border-color: #cbb796; }
  .btn-primary:hover {
    color: #212121;
    background-color: #bea57c;
    border-color: #baa074; }
  .btn-primary:focus, .btn-primary.focus {
    color: #212121;
    background-color: #bea57c;
    border-color: #baa074;
    box-shadow: 0 0 0 0.2rem rgba(178, 161, 132, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212121;
    background-color: #cbb796;
    border-color: #cbb796; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #212121;
    background-color: #baa074;
    border-color: #b69a6b; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(178, 161, 132, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #32373b;
  border-color: #32373b; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #202426;
    border-color: #1b1d1f; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #202426;
    border-color: #1b1d1f;
    box-shadow: 0 0 0 0.2rem rgba(81, 85, 88, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #32373b;
    border-color: #32373b; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1b1d1f;
    border-color: #151718; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(81, 85, 88, 0.5); }

.btn-success {
  color: #fff;
  background-color: #5eba00;
  border-color: #5eba00; }
  .btn-success:hover {
    color: #fff;
    background-color: #4b9400;
    border-color: #448700; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #4b9400;
    border-color: #448700;
    box-shadow: 0 0 0 0.2rem rgba(118, 196, 38, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #5eba00;
    border-color: #5eba00; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #448700;
    border-color: #3e7a00; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(118, 196, 38, 0.5); }

.btn-info {
  color: #fff;
  background-color: #45aaf2;
  border-color: #45aaf2; }
  .btn-info:hover {
    color: #fff;
    background-color: #219af0;
    border-color: #1594ef; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #219af0;
    border-color: #1594ef;
    box-shadow: 0 0 0 0.2rem rgba(97, 183, 244, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #45aaf2;
    border-color: #45aaf2; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1594ef;
    border-color: #108ee7; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(97, 183, 244, 0.5); }

.btn-warning {
  color: #212121;
  background-color: #f1c40f;
  border-color: #f1c40f; }
  .btn-warning:hover {
    color: #212121;
    background-color: #cea70c;
    border-color: #c29d0b; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212121;
    background-color: #cea70c;
    border-color: #c29d0b;
    box-shadow: 0 0 0 0.2rem rgba(210, 172, 18, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212121;
    background-color: #f1c40f;
    border-color: #f1c40f; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212121;
    background-color: #c29d0b;
    border-color: #b6940b; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(210, 172, 18, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #cd201f;
  border-color: #cd201f; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ac1b1a;
    border-color: #a11918; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #ac1b1a;
    border-color: #a11918;
    box-shadow: 0 0 0 0.2rem rgba(213, 65, 65, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #a11918;
    border-color: #961717; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 65, 65, 0.5); }

.btn-light {
  color: #212121;
  background-color: #fafafa;
  border-color: #fafafa; }
  .btn-light:hover {
    color: #212121;
    background-color: #e7e7e7;
    border-color: #e1e1e1; }
  .btn-light:focus, .btn-light.focus {
    color: #212121;
    background-color: #e7e7e7;
    border-color: #e1e1e1;
    box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212121;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212121;
    background-color: #e1e1e1;
    border-color: #dadada; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #424242;
  border-color: #424242; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2f2f2f;
    border-color: #292929; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #2f2f2f;
    border-color: #292929;
    box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #292929;
    border-color: #222222; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5); }

.btn-outline-primary {
  color: #cbb796;
  border-color: #cbb796; }
  .btn-outline-primary:hover {
    color: #212121;
    background-color: #cbb796;
    border-color: #cbb796; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(203, 183, 150, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #cbb796;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212121;
    background-color: #cbb796;
    border-color: #cbb796; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(203, 183, 150, 0.5); }

.btn-outline-secondary {
  color: #32373b;
  border-color: #32373b; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #32373b;
    border-color: #32373b; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(50, 55, 59, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #32373b;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #32373b;
    border-color: #32373b; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(50, 55, 59, 0.5); }

.btn-outline-success {
  color: #5eba00;
  border-color: #5eba00; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #5eba00;
    border-color: #5eba00; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #5eba00;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #5eba00;
    border-color: #5eba00; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.5); }

.btn-outline-info {
  color: #45aaf2;
  border-color: #45aaf2; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #45aaf2;
    border-color: #45aaf2; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 170, 242, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #45aaf2;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #45aaf2;
    border-color: #45aaf2; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(69, 170, 242, 0.5); }

.btn-outline-warning {
  color: #f1c40f;
  border-color: #f1c40f; }
  .btn-outline-warning:hover {
    color: #212121;
    background-color: #f1c40f;
    border-color: #f1c40f; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 196, 15, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f1c40f;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212121;
    background-color: #f1c40f;
    border-color: #f1c40f; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 196, 15, 0.5); }

.btn-outline-danger {
  color: #cd201f;
  border-color: #cd201f; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #cd201f;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.5); }

.btn-outline-light {
  color: #fafafa;
  border-color: #fafafa; }
  .btn-outline-light:hover {
    color: #212121;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fafafa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212121;
    background-color: #fafafa;
    border-color: #fafafa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-outline-dark {
  color: #424242;
  border-color: #424242; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #424242;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.btn-link {
  font-weight: 400;
  color: #cbb796;
  text-decoration: none; }
  .btn-link:hover {
    color: #b29463;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #757575;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 12rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #424242;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1720px) {
  .dropdown-menu-xxxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f5f5f5; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.75rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212121;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .dropdown-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #141414;
    text-decoration: none;
    background-color: #fafafa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #424242;
    text-decoration: none;
    background-color: #fafafa; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #757575;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0 1.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #9e9e9e;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.75rem 1.5rem;
  color: #212121; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #616161;
  text-align: center;
  white-space: nowrap;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #cbb796;
    background-color: #cbb796; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(203, 183, 150, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #bdbdbd; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    border-color: white; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #757575; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #f5f5f5; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #9e9e9e solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #cbb796;
  background-color: #cbb796; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(203, 183, 150, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(203, 183, 150, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(203, 183, 150, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #9e9e9e;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(203, 183, 150, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #616161;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23424242' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #bdbdbd;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(203, 183, 150, 0.25); }
    .custom-select:focus::-ms-value {
      color: #616161;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #757575;
    background-color: #f5f5f5; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #616161; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.75rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #bdbdbd;
    box-shadow: 0 0 0 0.2rem rgba(203, 183, 150, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #f5f5f5; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #616161;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #616161;
    content: "Browse";
    background-color: #f5f5f5;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(203, 183, 150, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(203, 183, 150, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(203, 183, 150, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #cbb796;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e0e0e0;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #cbb796;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e0e0e0;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #cbb796;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #e0e0e0;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #e0e0e0;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #9e9e9e; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #9e9e9e; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #9e9e9e; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #757575;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #e0e0e0; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #f5f5f5 #f5f5f5 #e0e0e0; }
    .nav-tabs .nav-link.disabled {
      color: #757575;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #616161;
    background-color: #fff;
    border-color: #e0e0e0 #e0e0e0 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #cbb796; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl, .navbar .container-xxxl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl, .navbar-expand-sm > .container-xxxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl, .navbar-expand-sm > .container-xxxl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl, .navbar-expand-md > .container-xxxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl, .navbar-expand-md > .container-xxxl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl, .navbar-expand-lg > .container-xxxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl, .navbar-expand-lg > .container-xxxl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl, .navbar-expand-xl > .container-xxxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl, .navbar-expand-xl > .container-xxxl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl, .navbar-expand-xxl > .container-xxxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl, .navbar-expand-xxl > .container-xxxl {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

@media (max-width: 1719.98px) {
  .navbar-expand-xxxl > .container,
  .navbar-expand-xxxl > .container-fluid, .navbar-expand-xxxl > .container-sm, .navbar-expand-xxxl > .container-md, .navbar-expand-xxxl > .container-lg, .navbar-expand-xxxl > .container-xl, .navbar-expand-xxxl > .container-xxl, .navbar-expand-xxxl > .container-xxxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1720px) {
  .navbar-expand-xxxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxxl > .container,
    .navbar-expand-xxxl > .container-fluid, .navbar-expand-xxxl > .container-sm, .navbar-expand-xxxl > .container-md, .navbar-expand-xxxl > .container-lg, .navbar-expand-xxxl > .container-xl, .navbar-expand-xxxl > .container-xxl, .navbar-expand-xxxl > .container-xxxl {
      flex-wrap: nowrap; }
    .navbar-expand-xxxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl, .navbar-expand > .container-xxxl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl, .navbar-expand > .container-xxxl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #f5f5f5;
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #f5f5f5; }
  .card-header:first-child {
    border-radius: -1px -1px 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #f5f5f5; }
  .card-footer:last-child {
    border-radius: 0 0 -1px -1px; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: -1px;
  border-top-right-radius: -1px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: -1px;
  border-bottom-left-radius: -1px; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  margin-bottom: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #757575;
    content: ">"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #757575; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #cbb796;
  background-color: #fff;
  border: 1px solid #e0e0e0; }
  .page-link:hover {
    z-index: 2;
    color: #b29463;
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #e0e0e0; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(203, 183, 150, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #cbb796;
  border-color: #cbb796; }

.page-item.disabled .page-link {
  color: #757575;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e0e0e0; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212121;
  background-color: #cbb796; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212121;
    background-color: #baa074; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(203, 183, 150, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #32373b; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #1b1d1f; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 55, 59, 0.5); }

.badge-success {
  color: #fff;
  background-color: #5eba00; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #448700; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.5); }

.badge-info {
  color: #fff;
  background-color: #45aaf2; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #1594ef; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(69, 170, 242, 0.5); }

.badge-warning {
  color: #212121;
  background-color: #f1c40f; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212121;
    background-color: #c29d0b; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(241, 196, 15, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #cd201f; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #a11918; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.5); }

.badge-light {
  color: #212121;
  background-color: #fafafa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212121;
    background-color: #e1e1e1; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #424242; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #292929; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f5f5f5;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #6a5f4e;
  background-color: #f5f1ea;
  border-color: #f0ebe2; }
  .alert-primary hr {
    border-top-color: #e7dfd1; }
  .alert-primary .alert-link {
    color: #4d4538; }

.alert-secondary {
  color: #1a1d1f;
  background-color: #d6d7d8;
  border-color: #c6c7c8; }
  .alert-secondary hr {
    border-top-color: #b9babb; }
  .alert-secondary .alert-link {
    color: #030303; }

.alert-success {
  color: #316100;
  background-color: #dff1cc;
  border-color: #d2ecb8; }
  .alert-success hr {
    border-top-color: #c5e7a4; }
  .alert-success .alert-link {
    color: #172e00; }

.alert-info {
  color: #24587e;
  background-color: #daeefc;
  border-color: #cbe7fb; }
  .alert-info hr {
    border-top-color: #b3dcf9; }
  .alert-info .alert-link {
    color: #193c56; }

.alert-warning {
  color: #7d6608;
  background-color: #fcf3cf;
  border-color: #fbeebc; }
  .alert-warning hr {
    border-top-color: #fae8a4; }
  .alert-warning .alert-link {
    color: #4d3f05; }

.alert-danger {
  color: #6b1110;
  background-color: #f5d2d2;
  border-color: #f1c1c0; }
  .alert-danger hr {
    border-top-color: #ecacab; }
  .alert-danger .alert-link {
    color: #3f0a09; }

.alert-light {
  color: #828282;
  background-color: #fefefe;
  border-color: #fefefe; }
  .alert-light hr {
    border-top-color: #f1f1f1; }
  .alert-light .alert-link {
    color: dimgray; }

.alert-dark {
  color: #222222;
  background-color: #d9d9d9;
  border-color: #cacaca; }
  .alert-dark hr {
    border-top-color: #bdbdbd; }
  .alert-dark .alert-link {
    color: #090909; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #f5f5f5;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #cbb796;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #616161;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #616161;
    text-decoration: none;
    background-color: #fafafa; }
  .list-group-item-action:active {
    color: #212121;
    background-color: #f5f5f5; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #757575;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #cbb796;
    border-color: #cbb796; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1720px) {
  .list-group-horizontal-xxxl {
    flex-direction: row; }
    .list-group-horizontal-xxxl .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxxl .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxxl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxxl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxxl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #6a5f4e;
  background-color: #f0ebe2; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #6a5f4e;
    background-color: #e7dfd1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #6a5f4e;
    border-color: #6a5f4e; }

.list-group-item-secondary {
  color: #1a1d1f;
  background-color: #c6c7c8; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #1a1d1f;
    background-color: #b9babb; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #1a1d1f;
    border-color: #1a1d1f; }

.list-group-item-success {
  color: #316100;
  background-color: #d2ecb8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #316100;
    background-color: #c5e7a4; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #316100;
    border-color: #316100; }

.list-group-item-info {
  color: #24587e;
  background-color: #cbe7fb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #24587e;
    background-color: #b3dcf9; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #24587e;
    border-color: #24587e; }

.list-group-item-warning {
  color: #7d6608;
  background-color: #fbeebc; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7d6608;
    background-color: #fae8a4; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7d6608;
    border-color: #7d6608; }

.list-group-item-danger {
  color: #6b1110;
  background-color: #f1c1c0; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6b1110;
    background-color: #ecacab; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #6b1110;
    border-color: #6b1110; }

.list-group-item-light {
  color: #828282;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #828282;
    background-color: #f1f1f1; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #828282;
    border-color: #828282; }

.list-group-item-dark {
  color: #222222;
  background-color: #cacaca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #222222;
    background-color: #bdbdbd; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #757575;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e0e0e0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e0e0e0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212121; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #cbb796 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #baa074 !important; }

.bg-secondary {
  background-color: #32373b !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #1b1d1f !important; }

.bg-success {
  background-color: #5eba00 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #448700 !important; }

.bg-info {
  background-color: #45aaf2 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1594ef !important; }

.bg-warning {
  background-color: #f1c40f !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c29d0b !important; }

.bg-danger {
  background-color: #cd201f !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #a11918 !important; }

.bg-light {
  background-color: #fafafa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e1e1e1 !important; }

.bg-dark {
  background-color: #424242 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #292929 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e0e0e0 !important; }

.border-top {
  border-top: 1px solid #e0e0e0 !important; }

.border-right {
  border-right: 1px solid #e0e0e0 !important; }

.border-bottom {
  border-bottom: 1px solid #e0e0e0 !important; }

.border-left {
  border-left: 1px solid #e0e0e0 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #cbb796 !important; }

.border-secondary {
  border-color: #32373b !important; }

.border-success {
  border-color: #5eba00 !important; }

.border-info {
  border-color: #45aaf2 !important; }

.border-warning {
  border-color: #f1c40f !important; }

.border-danger {
  border-color: #cd201f !important; }

.border-light {
  border-color: #fafafa !important; }

.border-dark {
  border-color: #424242 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1720px) {
  .d-xxxl-none {
    display: none !important; }
  .d-xxxl-inline {
    display: inline !important; }
  .d-xxxl-inline-block {
    display: inline-block !important; }
  .d-xxxl-block {
    display: block !important; }
  .d-xxxl-table {
    display: table !important; }
  .d-xxxl-table-row {
    display: table-row !important; }
  .d-xxxl-table-cell {
    display: table-cell !important; }
  .d-xxxl-flex {
    display: flex !important; }
  .d-xxxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1720px) {
  .flex-xxxl-row {
    flex-direction: row !important; }
  .flex-xxxl-column {
    flex-direction: column !important; }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxxl-center {
    justify-content: center !important; }
  .justify-content-xxxl-between {
    justify-content: space-between !important; }
  .justify-content-xxxl-around {
    justify-content: space-around !important; }
  .align-items-xxxl-start {
    align-items: flex-start !important; }
  .align-items-xxxl-end {
    align-items: flex-end !important; }
  .align-items-xxxl-center {
    align-items: center !important; }
  .align-items-xxxl-baseline {
    align-items: baseline !important; }
  .align-items-xxxl-stretch {
    align-items: stretch !important; }
  .align-content-xxxl-start {
    align-content: flex-start !important; }
  .align-content-xxxl-end {
    align-content: flex-end !important; }
  .align-content-xxxl-center {
    align-content: center !important; }
  .align-content-xxxl-between {
    align-content: space-between !important; }
  .align-content-xxxl-around {
    align-content: space-around !important; }
  .align-content-xxxl-stretch {
    align-content: stretch !important; }
  .align-self-xxxl-auto {
    align-self: auto !important; }
  .align-self-xxxl-start {
    align-self: flex-start !important; }
  .align-self-xxxl-end {
    align-self: flex-end !important; }
  .align-self-xxxl-center {
    align-self: center !important; }
  .align-self-xxxl-baseline {
    align-self: baseline !important; }
  .align-self-xxxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

@media (min-width: 1720px) {
  .float-xxxl-left {
    float: left !important; }
  .float-xxxl-right {
    float: right !important; }
  .float-xxxl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

@media (min-width: 1720px) {
  .m-xxxl-0 {
    margin: 0 !important; }
  .mt-xxxl-0,
  .my-xxxl-0 {
    margin-top: 0 !important; }
  .mr-xxxl-0,
  .mx-xxxl-0 {
    margin-right: 0 !important; }
  .mb-xxxl-0,
  .my-xxxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxxl-0,
  .mx-xxxl-0 {
    margin-left: 0 !important; }
  .m-xxxl-1 {
    margin: 0.25rem !important; }
  .mt-xxxl-1,
  .my-xxxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxxl-1,
  .mx-xxxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxxl-1,
  .my-xxxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxxl-1,
  .mx-xxxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxxl-2 {
    margin: 0.5rem !important; }
  .mt-xxxl-2,
  .my-xxxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxxl-2,
  .mx-xxxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxxl-2,
  .my-xxxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxxl-2,
  .mx-xxxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxxl-3 {
    margin: 1rem !important; }
  .mt-xxxl-3,
  .my-xxxl-3 {
    margin-top: 1rem !important; }
  .mr-xxxl-3,
  .mx-xxxl-3 {
    margin-right: 1rem !important; }
  .mb-xxxl-3,
  .my-xxxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxxl-3,
  .mx-xxxl-3 {
    margin-left: 1rem !important; }
  .m-xxxl-4 {
    margin: 1.5rem !important; }
  .mt-xxxl-4,
  .my-xxxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxxl-4,
  .mx-xxxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxxl-4,
  .my-xxxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxxl-4,
  .mx-xxxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxxl-5 {
    margin: 3rem !important; }
  .mt-xxxl-5,
  .my-xxxl-5 {
    margin-top: 3rem !important; }
  .mr-xxxl-5,
  .mx-xxxl-5 {
    margin-right: 3rem !important; }
  .mb-xxxl-5,
  .my-xxxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxxl-5,
  .mx-xxxl-5 {
    margin-left: 3rem !important; }
  .p-xxxl-0 {
    padding: 0 !important; }
  .pt-xxxl-0,
  .py-xxxl-0 {
    padding-top: 0 !important; }
  .pr-xxxl-0,
  .px-xxxl-0 {
    padding-right: 0 !important; }
  .pb-xxxl-0,
  .py-xxxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxxl-0,
  .px-xxxl-0 {
    padding-left: 0 !important; }
  .p-xxxl-1 {
    padding: 0.25rem !important; }
  .pt-xxxl-1,
  .py-xxxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxxl-1,
  .px-xxxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxxl-1,
  .py-xxxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxxl-1,
  .px-xxxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxxl-2 {
    padding: 0.5rem !important; }
  .pt-xxxl-2,
  .py-xxxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxxl-2,
  .px-xxxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxxl-2,
  .py-xxxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxxl-2,
  .px-xxxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxxl-3 {
    padding: 1rem !important; }
  .pt-xxxl-3,
  .py-xxxl-3 {
    padding-top: 1rem !important; }
  .pr-xxxl-3,
  .px-xxxl-3 {
    padding-right: 1rem !important; }
  .pb-xxxl-3,
  .py-xxxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxxl-3,
  .px-xxxl-3 {
    padding-left: 1rem !important; }
  .p-xxxl-4 {
    padding: 1.5rem !important; }
  .pt-xxxl-4,
  .py-xxxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxxl-4,
  .px-xxxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxxl-4,
  .py-xxxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxxl-4,
  .px-xxxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxxl-5 {
    padding: 3rem !important; }
  .pt-xxxl-5,
  .py-xxxl-5 {
    padding-top: 3rem !important; }
  .pr-xxxl-5,
  .px-xxxl-5 {
    padding-right: 3rem !important; }
  .pb-xxxl-5,
  .py-xxxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxxl-5,
  .px-xxxl-5 {
    padding-left: 3rem !important; }
  .m-xxxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxxl-n1,
  .my-xxxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxxl-n1,
  .mx-xxxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxxl-n1,
  .my-xxxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxxl-n1,
  .mx-xxxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxxl-n2,
  .my-xxxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxxl-n2,
  .mx-xxxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxxl-n2,
  .my-xxxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxxl-n2,
  .mx-xxxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxxl-n3 {
    margin: -1rem !important; }
  .mt-xxxl-n3,
  .my-xxxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxxl-n3,
  .mx-xxxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxxl-n3,
  .my-xxxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxxl-n3,
  .mx-xxxl-n3 {
    margin-left: -1rem !important; }
  .m-xxxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxxl-n4,
  .my-xxxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxxl-n4,
  .mx-xxxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxxl-n4,
  .my-xxxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxxl-n4,
  .mx-xxxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxxl-n5 {
    margin: -3rem !important; }
  .mt-xxxl-n5,
  .my-xxxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxxl-n5,
  .mx-xxxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxxl-n5,
  .my-xxxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxxl-n5,
  .mx-xxxl-n5 {
    margin-left: -3rem !important; }
  .m-xxxl-auto {
    margin: auto !important; }
  .mt-xxxl-auto,
  .my-xxxl-auto {
    margin-top: auto !important; }
  .mr-xxxl-auto,
  .mx-xxxl-auto {
    margin-right: auto !important; }
  .mb-xxxl-auto,
  .my-xxxl-auto {
    margin-bottom: auto !important; }
  .ml-xxxl-auto,
  .mx-xxxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1720px) {
  .text-xxxl-left {
    text-align: left !important; }
  .text-xxxl-right {
    text-align: right !important; }
  .text-xxxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #cbb796 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #b29463 !important; }

.text-secondary {
  color: #32373b !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #0f1012 !important; }

.text-success {
  color: #5eba00 !important; }

a.text-success:hover, a.text-success:focus {
  color: #376e00 !important; }

.text-info {
  color: #45aaf2 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f86db !important; }

.text-warning {
  color: #f1c40f !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #aa8a0a !important; }

.text-danger {
  color: #cd201f !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #8b1615 !important; }

.text-light {
  color: #fafafa !important; }

a.text-light:hover, a.text-light:focus {
  color: #d4d4d4 !important; }

.text-dark {
  color: #424242 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1c1c1c !important; }

.text-body {
  color: #212121 !important; }

.text-muted {
  color: #757575 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #9e9e9e;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e0e0e0 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #e0e0e0; }
  .table .thead-dark th {
    color: inherit;
    border-color: #e0e0e0; } }

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.form-control:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.breadcrumb-item + .breadcrumb-item::before {
  font-family: \5b8b\4f53; }

.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }

.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 300px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none; }

.slideout-menu-left {
  left: 0; }

.slideout-menu-right {
  right: 0; }

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: #fff;
  /* A background-color is required */
  min-height: 100vh; }

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden; }

.slideout-open .slideout-menu {
  display: block; }

body {
  width: 100%;
  min-height: 100vh;
  font-family: "Microsoft YaHei", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1; }

.page-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: block;
  background-color: black;
  transition: all 0.3s ease;
  opacity: 0;
  z-index: 1000; }

.page-fill {
  flex: 1; }

.page-banner {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  position: relative; }
  .page-banner img {
    width: 100%; }
  .page-banner .page-banner-container {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    user-select: none; }
  .page-banner .page-banner-content {
    color: #fff; }
    .page-banner .page-banner-content h1 {
      font-size: 50px;
      font-weight: bold;
      letter-spacing: 7px; }
    .page-banner .page-banner-content h4 {
      font-size: 25px;
      text-transform: uppercase; }
  .page-banner .mu-breadcrumb {
    position: absolute;
    bottom: 0; }
    .page-banner .mu-breadcrumb hr {
      background-color: #ffffff;
      width: 100px;
      margin: 20px 0; }
    .page-banner .mu-breadcrumb .breadcrumb {
      background: none;
      color: #ffffff;
      font-size: 16px; }
      .page-banner .mu-breadcrumb .breadcrumb .breadcrumb-item {
        color: #ffffff;
        font-size: 16px; }
        .page-banner .mu-breadcrumb .breadcrumb .breadcrumb-item a {
          color: #ffffff; }
        .page-banner .mu-breadcrumb .breadcrumb .breadcrumb-item::before {
          color: #ffffff; }
  @media (max-width: 1399px) {
    .page-banner .page-banner-content h1 {
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 5px; }
    .page-banner .page-banner-content h4 {
      font-size: 20px; }
    .page-banner .mu-breadcrumb hr {
      margin: 10px 0; }
    .page-banner .mu-breadcrumb .breadcrumb {
      background: none;
      color: #ffffff;
      font-size: 15px; }
      .page-banner .mu-breadcrumb .breadcrumb .breadcrumb-item {
        color: #ffffff;
        font-size: 15px; } }
  @media (max-width: 992px) {
    .page-banner .img {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 50vw;
      width: 100%;
      overflow: hidden; }
      .page-banner .img img {
        width: auto;
        height: 100%; }
    .page-banner .page-banner-content h1 {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 4px; }
    .page-banner .page-banner-content h4 {
      font-size: 14px;
      text-transform: uppercase; }
    .page-banner .mu-breadcrumb hr {
      background-color: #ffffff;
      width: 15vw;
      margin: 0 0; }
    .page-banner .mu-breadcrumb .breadcrumb {
      font-size: 13px;
      padding: 2vw 0; }
      .page-banner .mu-breadcrumb .breadcrumb .breadcrumb-item {
        font-size: 13px; } }

.page-title {
  text-align: center; }

.page-content {
  padding: 0;
  margin: 0;
  font-size: 15px;
  text-indent: 2em;
  margin-bottom: 1rem; }
  .page-content img {
    display: inherit;
    max-width: 100%;
    height: auto; }

.page-arrows {
  display: flex;
  background-color: #fafafa; }
  .page-arrows .page-arrow {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    padding: 0.75rem 1rem;
    color: #424242;
    background-color: #fafafa;
    font-size: 15px;
    overflow: hidden;
    transition: all 0.3s ease; }
    .page-arrows .page-arrow i {
      font-size: 17px; }
    .page-arrows .page-arrow:hover {
      color: #ffffff;
      background-color: #cbb796;
      text-decoration: none; }
    .page-arrows .page-arrow.disabled {
      color: #9e9e9e;
      background-color: #fafafa;
      cursor: not-allowed;
      pointer-events: none; }
      .page-arrows .page-arrow.disabled:hover {
        color: #9e9e9e;
        background-color: #fafafa; }

@media (min-width: 992px) {
  body.modal-open .mu-header,
  body.modal-open .mu-menu {
    right: 17px; }
  body.modal-open .mu-sidebtn {
    right: 33px; } }

.mu-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;
  background: linear-gradient(90deg, rgba(232, 228, 225, 0.4), rgba(89, 89, 89, 0.8));
  transition: all .36s ease; }
  .mu-header .navbar-brand img {
    height: 30px; }
    @media (min-width: 992px) {
      .mu-header .navbar-brand img {
        height: 54px; } }
  .mu-header .navbar-nav .nav-item {
    position: relative; }
    .mu-header .navbar-nav .nav-item:hover .dropDown {
      max-height: 500px;
      opacity: 1;
      transition: all .6s ease; }
    .mu-header .navbar-nav .nav-item:nth-child(1) .nav-link:before {
      display: none; }
    .mu-header .navbar-nav .nav-item .nav-link {
      color: #ffffff;
      font-size: 17px;
      height: 114px;
      line-height: 114px;
      padding: 0 1.25rem;
      transition: all 0.3s ease;
      letter-spacing: 1px;
      position: relative; }
      .mu-header .navbar-nav .nav-item .nav-link:before {
        content: "";
        position: absolute;
        top: 57px;
        left: -3px;
        width: 12px;
        height: 1px;
        background: #ffffff; }
    .mu-header .navbar-nav .nav-item .dropDown {
      transition: all 0.3s ease;
      padding: 0;
      width: 100%;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      box-sizing: border-box;
      position: absolute;
      top: 114px;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 9999; }
      .mu-header .navbar-nav .nav-item .dropDown a {
        border-radius: 0;
        transition: all 0.3s ease;
        padding: 15px 0;
        font-size: 16px;
        text-decoration: none;
        text-align: center; }
        .mu-header .navbar-nav .nav-item .dropDown a p {
          margin-bottom: 0;
          color: #ffffff; }
        .mu-header .navbar-nav .nav-item .dropDown a:hover {
          transition: all .36s ease;
          background-color: rgba(0, 0, 0, 0.7); }
          .mu-header .navbar-nav .nav-item .dropDown a:hover p {
            color: #ffffff; }
  .mu-header .dropdown:hover > .nav-link {
    color: #cbb796; }
  .mu-header .dropdown-menu {
    margin-top: -0.5rem; }
    .mu-header .dropdown-menu .dropdown-item {
      font-size: 15px; }
  .mu-header .navbar-phone {
    display: none;
    font-size: 17px;
    color: #424242;
    padding: 0 1.25rem; }
    .mu-header .navbar-phone i {
      font-size: 15px;
      color: #757575;
      margin-right: 5px; }
    @media (min-width: 992px) {
      .mu-header .navbar-phone {
        display: block; } }
  .mu-header .navbar-toggler {
    height: 50px;
    line-height: 50px;
    padding: 0;
    border: 0;
    outline: 0; }
    @media (min-width: 992px) {
      .mu-header .navbar-toggler {
        height: 64px;
        line-height: 64px; } }
  .mu-header .navbar-menu {
    display: none;
    align-items: center;
    flex-direction: row; }
    @media (min-width: 992px) {
      .mu-header .navbar-menu {
        display: flex; } }

.change {
  background: linear-gradient(90deg, #e8e4e1, #595959);
  transition: all .36s ease; }

.mu-footer {
  display: none;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
  color: #ffffff;
  background-color: #171717; }
  .mu-footer a {
    color: #616161;
    transition: all 0.3s ease; }
    .mu-footer a:hover {
      color: #9e9e9e;
      text-decoration: none; }
  .mu-footer .footer-title {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 15px;
    margin-bottom: 20px; }
    .mu-footer .footer-title h4 {
      font-size: 17px;
      margin-bottom: 0; }
    .mu-footer .footer-title h5 {
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 0;
      padding-left: 15px; }
  .mu-footer .footer-contact {
    padding-right: 95px; }
    .mu-footer .footer-contact .footer-contact-content h5 {
      font-size: 16px;
      letter-spacing: 1px; }
    .mu-footer .footer-contact .footer-contact-content h4 {
      font-size: 18px;
      margin-bottom: 0;
      padding-left: 10px; }
    .mu-footer .footer-contact .footer-contact-content .footer-icon {
      padding: 10px 0; }
  .mu-footer .footer-nav {
    padding-left: 95px;
    padding-right: 25px; }
    .mu-footer .footer-nav a {
      display: block;
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 10px; }
  .mu-footer .footer-erweima {
    padding-left: 150px; }
    .mu-footer .footer-erweima .erweima {
      margin: 0 auto;
      width: 120px;
      height: 120px;
      overflow: hidden; }
    .mu-footer .footer-erweima p {
      margin-bottom: 0;
      font-size: 15px;
      padding-top: 10px; }
  .mu-footer .footer-bottom {
    background-color: #000000; }
    .mu-footer .footer-bottom p {
      margin-bottom: 0; }
  @media (min-width: 992px) {
    .mu-footer {
      display: flex; } }
  @media (max-width: 1399px) {
    .mu-footer .footer-contact {
      padding-right: 60px; }
    .mu-footer .footer-nav {
      padding-left: 60px;
      padding-right: 15px; }
    .mu-footer .footer-erweima {
      padding-left: 100px; }
    .mu-footer .footer-divider {
      display: block;
      width: 100%;
      height: 1px;
      background-color: #424242; } }

.mu-footer-wap {
  padding: 1rem;
  font-size: 0.75rem;
  text-align: center;
  color: #757575;
  margin-bottom: 3rem; }
  .mu-footer-wap p {
    margin-bottom: 0; }
  .mu-footer-wap a {
    color: #212121;
    font-weight: bold; }
  @media (min-width: 992px) {
    .mu-footer-wap {
      display: none; } }

.mu-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  padding: 0;
  background-color: #fff;
  z-index: 2; }
  .mu-menu .container {
    padding: 0; }
  .mu-menu .nav {
    font-size: 14px;
    width: 100%; }
    .mu-menu .nav .nav-link {
      flex: 1 1 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      color: #424242;
      border-top: 1px solid #e0e0e0;
      border-left: 1px solid #e0e0e0; }
      .mu-menu .nav .nav-link i {
        padding-right: 5px; }
      .mu-menu .nav .nav-link:first-child {
        border-left: 0; }
      @media (min-width: 576px) {
        .mu-menu .nav .nav-link:first-child {
          border-left: 1px solid #e0e0e0; } }
    .mu-menu .nav .nav-btn {
      flex: 1 1 40%;
      color: #fff;
      background: #cbb796;
      border: 0; }
  @media (min-width: 992px) {
    .mu-menu {
      display: none; } }

.mu-sidebar {
  display: none;
  background-color: #212121; }
  .mu-sidebar .nav {
    flex-direction: column;
    padding: 40px 0; }
    .mu-sidebar .nav .nav-item .nav-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #9e9e9e;
      padding: 0.75rem 0.75rem 0.75rem 1.25rem;
      border-bottom: 1px solid #353535; }
      .mu-sidebar .nav .nav-item .nav-link.active {
        color: #cbb796; }
      .mu-sidebar .nav .nav-item .nav-link i {
        font-size: 17px;
        margin-right: 0.25rem; }
    .mu-sidebar .nav .nav-item .nav-submenu {
      display: flex;
      flex-direction: column;
      background-color: #353535;
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s ease; }
      .mu-sidebar .nav .nav-item .nav-submenu .nav-submenu-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #9e9e9e;
        padding: 0.75rem 0.75rem 0.75rem 1.25rem; }
        .mu-sidebar .nav .nav-item .nav-submenu .nav-submenu-item.active {
          color: #cbb796; }
        .mu-sidebar .nav .nav-item .nav-submenu .nav-submenu-item:hover {
          text-decoration: none; }
      .mu-sidebar .nav .nav-item .nav-submenu.show {
        max-height: 500px; }

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center; }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  opacity: 1;
  border: 2px solid transparent;
  background-color: rgba(0, 0, 0, 0.1);
  outline: none; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

.swiper-pagination-bullet-active {
  background-color: transparent;
  border-color: #cbb796;
  width: 12px;
  height: 12px; }

.mu-banner .swiper-pagination {
  bottom: 1rem; }

.mu-banner .swiper-pagination-bullet {
  width: 30px;
  height: 14px;
  border: 0;
  border-bottom: 2px solid #fff;
  border-radius: 0;
  background: 0 0;
  opacity: 0.4; }

.mu-banner .swiper-pagination-bullet-active {
  opacity: 1; }

.mu-banner .swiper-slide {
  position: relative; }
  .mu-banner .swiper-slide::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5; }

.mu-banner .swiper-button-prev {
  background-image: url(../images/angle_left.svg);
  background-size: 24px 40px;
  left: 1rem;
  width: 24px;
  height: 40px;
  margin-top: 0;
  transform: translateY(-20px);
  outline: none; }

.mu-banner .swiper-button-next {
  background-image: url(../images/angle_right.svg);
  background-size: 24px 40px;
  right: 1rem;
  width: 24px;
  height: 40px;
  margin-top: 0;
  transform: translateY(-20px);
  outline: none; }

.mu-banner .swiper-slide-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none; }

.dropdown:hover > .dropdown-item {
  background-color: #fafafa; }

.dropdown-menu {
  margin-top: -1px; }
  .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.dropdown-submenu .dropdown-menu {
  margin-top: 0;
  margin-left: -0.5rem; }

.mu-nav-pills .nav {
  display: flex;
  justify-content: center; }
  .mu-nav-pills .nav .nav-item {
    position: relative;
    margin-right: 5px;
    margin-bottom: 5px; }
    .mu-nav-pills .nav .nav-item .nav-link {
      padding: 0.5rem 1.5rem;
      color: #757575;
      border: 1px solid #e0e0e0;
      border-radius: 0;
      transition: all 0.3s ease; }
      .mu-nav-pills .nav .nav-item .nav-link:hover, .mu-nav-pills .nav .nav-item .nav-link:focus, .mu-nav-pills .nav .nav-item .nav-link.active {
        color: #fff;
        background-color: #cbb796;
        border-color: #cbb796; }
      .mu-nav-pills .nav .nav-item .nav-link i {
        margin-left: 5px; }
    .mu-nav-pills .nav .nav-item.dropdown:hover > .nav-link {
      color: #fff;
      background-color: #cbb796;
      border-color: #cbb796; }
    .mu-nav-pills .nav .nav-item:last-child {
      margin-right: 0; }

@media (max-width: 992px) {
  .mu-nav-pills .nav {
    justify-content: left; } }

@media (max-width: 576px) {
  .mu-nav-pills .dropdown-menu {
    position: static;
    float: none;
    margin-left: 0 !important; } }

.click-count {
  font-size: 12px; }

.mu-pagination {
  display: none; }
  .mu-pagination .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 0; }
    .mu-pagination .pagination .page-item {
      margin: 0 0.25rem; }
      .mu-pagination .pagination .page-item.active .page-link {
        color: #ffffff;
        border-color: #cbb796;
        background-color: #cbb796; }
      .mu-pagination .pagination .page-item.disabled .page-link {
        color: #9e9e9e; }
      .mu-pagination .pagination .page-item .page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 38px;
        height: 38px;
        padding: 0;
        color: #cbb796;
        border-color: #f5f5f5;
        border-radius: 0;
        transition: all 0.3s ease; }
        .mu-pagination .pagination .page-item .page-link:hover {
          color: #ffffff;
          background-color: #cbb796;
          border-color: #cbb796; }
        .mu-pagination .pagination .page-item .page-link:hover, .mu-pagination .pagination .page-item .page-link:focus {
          box-shadow: none;
          outline: none; }
      .mu-pagination .pagination .page-item:first-child .page-link,
      .mu-pagination .pagination .page-item:last-child .page-link {
        border-radius: 0; }
        .mu-pagination .pagination .page-item:first-child .page-link i,
        .mu-pagination .pagination .page-item:last-child .page-link i {
          font-size: 17px; }
  @media (min-width: 992px) {
    .mu-pagination {
      display: block; } }

.mu-pagination-wap .pagination {
  display: flex;
  margin-bottom: 0; }
  .mu-pagination-wap .pagination .page-item {
    flex: 1; }
    .mu-pagination-wap .pagination .page-item .page-link {
      color: #424242;
      text-align: center; }
    .mu-pagination-wap .pagination .page-item.disabled .page-link {
      color: #9e9e9e; }

@media (min-width: 992px) {
  .mu-pagination-wap {
    display: none; } }

.card {
  transition: all 0.3s ease; }
  .card:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .card .card-img-top {
    overflow: hidden; }
  @media (max-width: 992px) {
    .card {
      box-shadow: none; } }

a.card {
  color: #212121; }
  a.card .card-text {
    color: #757575; }
  a.card:hover {
    text-decoration: none; }

.mu-card {
  margin-bottom: 2rem; }
  @media (max-width: 992px) {
    .mu-card {
      margin-bottom: 1rem; }
      .mu-card .card-body {
        padding: 0.5rem; }
        .mu-card .card-body .card-title {
          font-size: 16px;
          margin-bottom: 0.5rem;
          height: 20px; }
        .mu-card .card-body .card-text {
          font-size: 12px;
          margin-bottom: 0.5rem; }
          .mu-card .card-body .card-text:last-child {
            line-height: initial;
            height: initial;
            margin-bottom: 0; } }

.mu-row {
  margin-right: -0.75rem;
  margin-left: -0.75rem; }
  .mu-row [class^="col-"] {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  @media (max-width: 768px) {
    .mu-row {
      margin-right: -0.5rem;
      margin-left: -0.5rem; }
      .mu-row [class^="col-"] {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }

.mu-sidebtn {
  position: fixed;
  top: 50%;
  right: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 2; }
  .mu-sidebtn__item {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    color: #fff;
    background-color: #cbb796;
    border-width: 1px;
    border-style: solid;
    border-color: #cbb796;
    border-top-width: 0;
    transition: all 0.3s ease; }
    .mu-sidebtn__item:first-child {
      border-top-width: 1px; }
    .mu-sidebtn__item i {
      font-size: 16px; }
    .mu-sidebtn__item:hover {
      color: #fff;
      background-color: #c3ab85;
      border-color: #cbb796; }

.scroll-to-top {
  display: none; }

.mu-list-group a.list-group-item {
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  color: #424242;
  border-color: #f5f5f5;
  transition: all 0.3s ease; }
  .mu-list-group a.list-group-item .list-img-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 300px;
    margin-right: 1.5rem; }
    .mu-list-group a.list-group-item .list-img-left .list-img {
      width: 100%; }
  .mu-list-group a.list-group-item .list-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .mu-list-group a.list-group-item .list-body .list-title {
      margin-bottom: 0.75rem;
      transition: all 0.3s ease; }
    .mu-list-group a.list-group-item .list-body .list-text {
      color: #757575;
      margin-bottom: 0.5rem; }
      .mu-list-group a.list-group-item .list-body .list-text:last-child {
        margin-bottom: 0; }
    .mu-list-group a.list-group-item .list-body .list-more {
      display: inline-block;
      padding: 0.25rem 1.25rem;
      border: 1px solid #f5f5f5;
      color: #757575;
      transition: all 0.3s ease; }
  .mu-list-group a.list-group-item:hover {
    color: #424242;
    text-decoration: none; }
    .mu-list-group a.list-group-item:hover .list-body .list-title {
      color: #cbb796; }
    .mu-list-group a.list-group-item:hover .list-body .list-more {
      color: #fff;
      background-color: #cbb796;
      border-color: #cbb796; }
  @media (max-width: 767px) {
    .mu-list-group a.list-group-item {
      flex-direction: column; }
      .mu-list-group a.list-group-item .list-img-left {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 1rem; } }

.mu-list-group-card a.list-group-item {
  margin-bottom: 2rem;
  border: 1px solid #f5f5f5; }
  .mu-list-group-card a.list-group-item:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }

.mu-list-group-flush a.list-group-item {
  padding: 1.5rem 0;
  border-left: 0;
  border-right: 0; }
  .mu-list-group-flush a.list-group-item:first-child {
    border-top: 0; }
  .mu-list-group-flush a.list-group-item:last-child {
    border-bottom: 0; }

.mu-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.mu-multi-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.mu-multi-ellipsis--l3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.mu-multi-ellipsis--l4 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; }

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.link {
  color: #757575;
  transition: all 0.3s ease; }
  .link:hover {
    color: #cbb796;
    text-decoration: none; }

.sk-highlight {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1); }

.index .model-more button {
  background: linear-gradient(90deg, #7e7874, #b1a49a);
  padding: 1rem 1rem;
  width: 170px;
  border-radius: 0;
  transition: all .36s ease;
  outline: none; }
  .index .model-more button h5 {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 17px;
    letter-spacing: 3px; }
  .index .model-more button:hover {
    background: linear-gradient(90deg, black, #746b65);
    transition: all .36s ease; }

.index .about {
  width: 1400px;
  margin: 0 auto;
  background: url(../images/about-back.jpg) no-repeat top center;
  padding: 50px 0; }
  .index .about .title h1 {
    font-weight: bold;
    font-size: 25px; }
    .index .about .title h1 span {
      font-size: 35px;
      font-weight: bold;
      letter-spacing: 5px;
      background: linear-gradient(to right, #000000, #686868);
      -webkit-background-clip: text;
      color: transparent; }
  .index .about .title h5 {
    font-size: 18px;
    letter-spacing: 20px;
    margin-top: 15px;
    background: linear-gradient(to right, #595959, #c9c1ba);
    -webkit-background-clip: text;
    color: transparent; }
  .index .about .content {
    margin-top: 40px; }
    .index .about .content p {
      text-indent: 2rem;
      font-size: 17px;
      line-height: 40px;
      margin-bottom: 0; }
  .index .about .about-right {
    padding-left: 100px;
    position: relative; }
    .index .about .about-right .english-title {
      position: absolute;
      top: -101px;
      z-index: 9; }

.index .professional {
  padding: 3rem 0 6rem; }
  .index .professional .content {
    position: relative; }
    .index .professional .content .swiper-button-prev {
      outline: none;
      top: 98%;
      background-image: none;
      background-color: #b1a49a;
      color: #ffffff;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      left: 18%; }
      .index .professional .content .swiper-button-prev:hover {
        background-color: #373737; }
    .index .professional .content .swiper-button-next {
      outline: none;
      top: 98%;
      background-image: none;
      background-color: #b1a49a;
      color: #ffffff;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      right: 18%; }
      .index .professional .content .swiper-button-next:hover {
        background-color: #373737; }
    .index .professional .content .gallery-top .img {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 608px; }
      .index .professional .content .gallery-top .img img {
        height: 100%; }
    .index .professional .content .professional-thumb {
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      bottom: -50px;
      overflow: hidden; }
    .index .professional .content .gallery-thumbs {
      width: 50%;
      margin: 0 auto; }
      .index .professional .content .gallery-thumbs .swiper-slide {
        height: 100%;
        opacity: 0.8; }
      .index .professional .content .gallery-thumbs .swiper-slide-thumb-active {
        opacity: 1; }
      .index .professional .content .gallery-thumbs .img {
        width: 120px;
        height: 120px;
        overflow: hidden;
        text-align: center;
        border-radius: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        transition: all .36s ease; }
        .index .professional .content .gallery-thumbs .img img {
          height: 100%;
          transition: all .36s ease; }

.index .news {
  /* ********************************* 定宽布局 ********************************* */
  /* ********************************* 通用flex布局 ********************************* */
  /* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素space-between */
  /* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素space-around */
  /* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚中靠拢 */
  /* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚左靠拢 */
  /* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚右靠拢 */
  /* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素space-between */
  /* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素space-around */
  /* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚中靠拢 */
  /* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚上靠拢 */
  /* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚下靠拢 */
  /* ********************************* 通用行数限制 ********************************* */ }
  .index .news .w {
    width: 100%;
    min-width: 1720px;
    overflow: hidden; }
  .index .news .sw {
    width: 1720px;
    margin: 0 auto; }
  .index .news .flex_row_sb {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .index .news .flex_row_sa {
    display: flex;
    justify-content: space-around;
    align-items: center; }
  .index .news .flex_row_center {
    display: flex;
    justify-content: center;
    align-items: center; }
  .index .news .flex_row_left {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
  .index .news .flex_row_right {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .index .news .flex_column_sb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
  .index .news .flex_column_sa {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; }
  .index .news .flex_column_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .index .news .flex_column_top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
  .index .news .flex_column_bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center; }
  .index .news .text_of_1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .index .news .text_of_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .index .news .text_of_3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .index .news .text_of_4 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .index .news .text_of_5 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .index .news a {
    text-decoration: none !important; }
  .index .news .pc-news .indexNews {
    min-height: 898px; }
    .index .news .pc-news .indexNews .indexNews_c {
      height: auto;
      align-items: flex-start; }
      .index .news .pc-news .indexNews .indexNews_c .news_L {
        height: 810px;
        width: 552px;
        box-sizing: border-box;
        padding-top: 50px; }
        .index .news .pc-news .indexNews .indexNews_c .news_L .news-title {
          padding-bottom: 70px; }
          .index .news .pc-news .indexNews .indexNews_c .news_L .news-title h1 {
            font-size: 35px;
            font-weight: bold;
            margin-bottom: 10px;
            letter-spacing: 3px; }
          .index .news .pc-news .indexNews .indexNews_c .news_L .news-title h5 {
            color: #717171;
            font-size: 15px;
            text-transform: uppercase; }
        .index .news .pc-news .indexNews .indexNews_c .news_L .newsL_content {
          width: 100%;
          padding-bottom: 15px;
          transition: all 1s ease;
          bottom: 0px;
          cursor: pointer;
          position: relative; }
          .index .news .pc-news .indexNews .indexNews_c .news_L .newsL_content:hover {
            box-shadow: -5px 5px 10px #ccc;
            bottom: 20px; }
          .index .news .pc-news .indexNews .indexNews_c .news_L .newsL_content .lMask {
            width: 100%;
            height: 342px;
            overflow: hidden; }
            .index .news .pc-news .indexNews .indexNews_c .news_L .newsL_content .lMask > img {
              height: 100%;
              width: auto; }
          .index .news .pc-news .indexNews .indexNews_c .news_L .newsL_content .newslText {
            height: auto;
            width: 100%; }
            .index .news .pc-news .indexNews .indexNews_c .news_L .newsL_content .newslText .news_time {
              font-size: 16px;
              color: #000;
              line-height: 55px; }
            .index .news .pc-news .indexNews .indexNews_c .news_L .newsL_content .newslText .news_title {
              font-size: 20px;
              color: #000;
              font-weight: bold;
              width: 90%;
              margin-bottom: 15px;
              transition: all 0.5s ease; }
            .index .news .pc-news .indexNews .indexNews_c .news_L .newsL_content .newslText .news_content {
              font-size: 16px;
              color: #676767;
              letter-spacing: 2px;
              width: 98%;
              line-height: 25px;
              margin-bottom: 40px; }
          .index .news .pc-news .indexNews .indexNews_c .news_L .newsL_content .newslText {
            box-sizing: border-box;
            padding: 0 10px;
            position: relative; }
          .index .news .pc-news .indexNews .indexNews_c .news_L .newsL_content .public_more {
            width: 100%;
            padding-left: 10px;
            box-sizing: border-box; }
      .index .news .pc-news .indexNews .indexNews_c .news_R {
        align-items: flex-start; }
        .index .news .pc-news .indexNews .indexNews_c .news_R .newsBtn {
          height: 750px;
          width: 80px;
          position: relative;
          box-sizing: border-box;
          padding: 50px 0px; }
          .index .news .pc-news .indexNews .indexNews_c .news_R .newsBtn .Btn_r {
            height: 100%;
            position: absolute;
            border-right: 1px solid #eee;
            top: 0;
            right: 1px;
            z-index: 9; }
          .index .news .pc-news .indexNews .indexNews_c .news_R .newsBtn .Btn_box {
            height: 120px;
            width: 100%;
            box-sizing: border-box;
            border-right: 3px solid #fff;
            cursor: pointer;
            transition: all 0.5s ease;
            z-index: 8; }
            .index .news .pc-news .indexNews .indexNews_c .news_R .newsBtn .Btn_box .btnContent {
              width: 20px;
              font-size: 18px;
              line-height: 18px; }
          .index .news .pc-news .indexNews .indexNews_c .news_R .newsBtn .activated_btn {
            border-color: #3f4f74;
            z-index: 10; }
            .index .news .pc-news .indexNews .indexNews_c .news_R .newsBtn .activated_btn .btnContent {
              font-weight: bold; }
        .index .news .pc-news .indexNews .indexNews_c .news_R .newsList {
          width: 1027px;
          height: 810px;
          position: relative; }
          .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent {
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 0;
            opacity: 0;
            transition: all 0.5s ease; }
            .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf {
              width: 100%;
              height: 750px;
              box-sizing: border-box;
              padding: 70px 0px 30px;
              padding-left: 145px; }
              .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox {
                width: 100%; }
                .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox:nth-child(2) {
                  margin-left: -180px; }
                .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox:hover .nlseMask .lsabfs_te {
                  margin-bottom: -10px;
                  margin-right: -25px; }
                .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox:hover .newslText {
                  border-color: #caba80;
                  box-shadow: 3px 3px 10px #ccc; }
                  .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox:hover .newslText .news_title {
                    color: #bdae89; }
                .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .nlseMask {
                  width: 167px;
                  height: 167px;
                  position: relative;
                  border: 1px dashed #241b1b;
                  border-radius: 100%; }
                  .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .nlseMask .lsabfs_te {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: absolute;
                    border-radius: 100%;
                    bottom: 10px;
                    right: 25px;
                    transition: all 0.5s ease; }
                    .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .nlseMask .lsabfs_te > img {
                      height: 100%;
                      width: auto; }
                .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .newslText {
                  width: 640px;
                  box-sizing: border-box;
                  padding: 10px;
                  border: 1px solid #fff;
                  transition: all 0.5s ease; }
                  .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .newslText .news_time {
                    line-height: 16px;
                    margin-bottom: 10px; }
                  .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .newslText .news_content {
                    margin-bottom: 0px; }
          .index .news .pc-news .indexNews .indexNews_c .news_R .newsList .activateds {
            z-index: 1;
            opacity: 1; }
  .index .news .wap-news {
    display: none; }

@media (max-width: 1719px) {
  .index .professional .content {
    position: relative; }
    .index .professional .content .swiper-button-prev {
      top: 100%; }
    .index .professional .content .swiper-button-next {
      top: 100%; }
    .index .professional .content .gallery-top .img {
      height: 493px; }
    .index .professional .content .gallery-thumbs {
      width: 55%;
      margin: 0 auto; }
      .index .professional .content .gallery-thumbs .img {
        width: 100px;
        height: 100px; }
  .index .news .w {
    width: 100%;
    min-width: 1400px;
    overflow: hidden; }
  .index .news .sw {
    width: 1400px;
    margin: 0 auto; }
  .index .news .indexNews {
    min-height: 898px; }
    .index .news .indexNews .indexNews_c {
      height: auto;
      align-items: flex-start; }
      .index .news .indexNews .indexNews_c .news_L {
        height: 810px;
        width: 522px;
        box-sizing: border-box;
        padding-top: 50px; }
        .index .news .indexNews .indexNews_c .news_L .news-title {
          padding-bottom: 70px; }
          .index .news .indexNews .indexNews_c .news_L .news-title h1 {
            font-size: 35px;
            font-weight: bold;
            margin-bottom: 10px;
            letter-spacing: 3px; }
          .index .news .indexNews .indexNews_c .news_L .news-title h5 {
            color: #717171;
            font-size: 15px;
            text-transform: uppercase; }
        .index .news .indexNews .indexNews_c .news_L .newsL_content {
          width: 100%;
          padding-bottom: 15px;
          transition: all 1s ease;
          bottom: 0px;
          cursor: pointer;
          position: relative; }
          .index .news .indexNews .indexNews_c .news_L .newsL_content:hover {
            box-shadow: -5px 5px 10px #ccc;
            bottom: 20px; }
          .index .news .indexNews .indexNews_c .news_L .newsL_content .lMask {
            width: 100%;
            height: 342px;
            overflow: hidden; }
            .index .news .indexNews .indexNews_c .news_L .newsL_content .lMask > img {
              height: 100%;
              width: auto; }
          .index .news .indexNews .indexNews_c .news_L .newsL_content .newslText {
            height: auto;
            width: 100%; }
            .index .news .indexNews .indexNews_c .news_L .newsL_content .newslText .news_time {
              font-size: 16px;
              color: #000;
              line-height: 55px; }
            .index .news .indexNews .indexNews_c .news_L .newsL_content .newslText .news_title {
              font-size: 20px;
              color: #000;
              font-weight: bold;
              width: 90%;
              margin-bottom: 15px;
              transition: all 0.5s ease; }
            .index .news .indexNews .indexNews_c .news_L .newsL_content .newslText .news_content {
              font-size: 16px;
              color: #676767;
              letter-spacing: 2px;
              width: 98%;
              line-height: 25px;
              margin-bottom: 40px; }
          .index .news .indexNews .indexNews_c .news_L .newsL_content .newslText {
            box-sizing: border-box;
            padding: 0 10px;
            position: relative; }
          .index .news .indexNews .indexNews_c .news_L .newsL_content .public_more {
            width: 100%;
            padding-left: 10px;
            box-sizing: border-box; }
      .index .news .indexNews .indexNews_c .news_R {
        align-items: flex-start; }
        .index .news .indexNews .indexNews_c .news_R .newsBtn {
          height: 705px;
          width: 80px;
          position: relative;
          box-sizing: border-box;
          padding: 50px 0px; }
          .index .news .indexNews .indexNews_c .news_R .newsBtn .Btn_r {
            height: 100%;
            position: absolute;
            border-right: 1px solid #eee;
            top: 0;
            right: 1px;
            z-index: 9; }
          .index .news .indexNews .indexNews_c .news_R .newsBtn .Btn_box {
            height: 120px;
            width: 100%;
            box-sizing: border-box;
            border-right: 3px solid #fff;
            cursor: pointer;
            transition: all 0.5s ease;
            z-index: 8; }
            .index .news .indexNews .indexNews_c .news_R .newsBtn .Btn_box .btnContent {
              width: 20px;
              font-size: 18px;
              line-height: 18px; }
          .index .news .indexNews .indexNews_c .news_R .newsBtn .activated_btn {
            border-color: #3f4f74;
            z-index: 10; }
            .index .news .indexNews .indexNews_c .news_R .newsBtn .activated_btn .btnContent {
              font-weight: bold; }
        .index .news .indexNews .indexNews_c .news_R .newsList {
          width: 740px;
          height: 810px;
          position: relative; }
          .index .news .indexNews .indexNews_c .news_R .newsList .listCntent {
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 0;
            opacity: 0;
            transition: all 0.5s ease; }
            .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf {
              width: 100%;
              height: 750px;
              box-sizing: border-box;
              padding: 70px 0px 30px;
              padding-left: 145px; }
              .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox {
                width: 100%; }
                .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox:nth-child(2) {
                  margin-left: -180px; }
                .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox:hover .nlseMask .lsabfs_te {
                  margin-bottom: -10px;
                  margin-right: -25px; }
                .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox:hover .newslText {
                  border-color: #caba80;
                  box-shadow: 3px 3px 10px #ccc; }
                  .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox:hover .newslText .news_title {
                    color: #bdae89; }
                .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .nlseMask {
                  width: 167px;
                  height: 167px;
                  position: relative;
                  border: 1px dashed #241b1b;
                  border-radius: 100%; }
                  .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .nlseMask .lsabfs_te {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: absolute;
                    border-radius: 100%;
                    bottom: 10px;
                    right: 25px;
                    transition: all 0.5s ease; }
                    .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .nlseMask .lsabfs_te > img {
                      height: 100%;
                      width: auto; }
                .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .newslText {
                  width: 405px;
                  box-sizing: border-box;
                  padding: 10px;
                  border: 1px solid #fff;
                  transition: all 0.5s ease; }
                  .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .newslText .news_time {
                    line-height: 16px;
                    margin-bottom: 10px; }
                  .index .news .indexNews .indexNews_c .news_R .newsList .listCntent .coansf .newsBox .newslText .news_content {
                    margin-bottom: 0px; }
          .index .news .indexNews .indexNews_c .news_R .newsList .activateds {
            z-index: 1;
            opacity: 1; } }

@media (max-width: 1399px) {
  .index .professional .content {
    position: relative; }
    .index .professional .content .swiper-button-prev {
      top: 100%; }
    .index .professional .content .swiper-button-next {
      top: 100%; }
    .index .professional .content .gallery-top .img {
      height: 421px; }
    .index .professional .content .gallery-thumbs {
      width: 52%;
      margin: 0 auto; }
      .index .professional .content .gallery-thumbs .img {
        width: 90px;
        height: 90px; } }

@media (max-width: 992px) {
  .index {
    padding-bottom: 15vw; }
    .index .model-more button {
      background: linear-gradient(90deg, #7e7874, #b1a49a);
      padding: 3vw 7vw;
      width: 45vw; }
      .index .model-more button h5 {
        margin-bottom: 0;
        color: #ffffff;
        font-size: 14px;
        letter-spacing: 1px; }
      .index .model-more button img {
        width: 5vw; }
    .index .about {
      width: 100%;
      margin: 0 auto;
      background: none;
      padding: 5vw 0; }
      .index .about .title h1 {
        font-weight: bold;
        font-size: 15px; }
        .index .about .title h1 span {
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 1px;
          background: linear-gradient(to right, #000000, #686868);
          -webkit-background-clip: text;
          color: transparent; }
      .index .about .title h5 {
        font-size: 15px;
        letter-spacing: 6px;
        margin-top: 2vw;
        background: linear-gradient(to right, #595959, #c9c1ba);
        -webkit-background-clip: text;
        color: transparent; }
      .index .about .content {
        margin-top: 4vw; }
        .index .about .content p {
          text-indent: 2rem;
          font-size: 15px;
          line-height: 7vw;
          margin-bottom: 0; }
        .index .about .content button {
          background: linear-gradient(90deg, #7e7874, #b1a49a);
          padding: .75rem 1rem;
          width: 35vw;
          border-radius: 0;
          transition: all .36s ease;
          outline: none; }
          .index .about .content button h5 {
            margin-bottom: 0;
            color: #ffffff;
            font-size: 15px;
            letter-spacing: 1px; }
          .index .about .content button:hover {
            background: linear-gradient(90deg, black, #746b65);
            transition: all .36s ease;
            transform: scale(1.05); }
      .index .about .about-right {
        padding-left: 100px;
        position: relative; }
        .index .about .about-right .english-title {
          position: absolute;
          top: -101px; }
    .index .professional {
      padding: 5vw 0; }
      .index .professional .container {
        padding: 0; }
      .index .professional .content {
        position: relative; }
        .index .professional .content .swiper-button-prev {
          outline: none;
          top: 60%;
          background-image: none;
          background-color: #b1a49a;
          color: #ffffff;
          width: 7vw;
          height: 7vw;
          border-radius: 100%;
          left: 1vw; }
          .index .professional .content .swiper-button-prev:hover {
            background-color: #373737; }
        .index .professional .content .swiper-button-next {
          outline: none;
          top: 60%;
          background-image: none;
          background-color: #b1a49a;
          color: #ffffff;
          width: 7vw;
          height: 7vw;
          border-radius: 100%;
          right: 1vw; }
          .index .professional .content .swiper-button-next:hover {
            background-color: #373737; }
        .index .professional .content .gallery-top .img {
          height: 36vw; }
          .index .professional .content .gallery-top .img img {
            height: 100%; }
        .index .professional .content .professional-thumb {
          opacity: 0; }
    .index .news {
      padding: 5vw 0; }
      .index .news .pc-news {
        display: none; }
      .index .news .wap-news {
        display: block; }
        .index .news .wap-news .news-title {
          padding-bottom: 3vw; }
          .index .news .wap-news .news-title h1 {
            font-size: 17px;
            font-weight: bold; }
          .index .news .wap-news .news-title h5 {
            font-size: 11px;
            text-transform: uppercase;
            margin-bottom: 0;
            color: #717171; }
        .index .news .wap-news .detail1 {
          margin-bottom: 5vw; }
          .index .news .wap-news .detail1 .img {
            height: 60vw;
            overflow: hidden; }
            .index .news .wap-news .detail1 .img img {
              width: 100%; }
          .index .news .wap-news .detail1 .text {
            background-color: #eeeeee;
            padding: 5vw 3vw; }
            .index .news .wap-news .detail1 .text h5 {
              font-size: 15px;
              color: #000000;
              font-weight: bold;
              width: 65vw;
              margin-bottom: 3vw; }
            .index .news .wap-news .detail1 .text h4 {
              font-size: 12px;
              color: #000000;
              margin-bottom: 0;
              width: 20vw; }
            .index .news .wap-news .detail1 .text p {
              font-size: 12px;
              margin-bottom: 0;
              color: #000000; }
        .index .news .wap-news .detail2 {
          margin-bottom: 3vw; }
          .index .news .wap-news .detail2 .news-img {
            width: 22vw;
            height: 22vw;
            box-sizing: border-box;
            border: 1px dashed #ccc;
            border-radius: 100%;
            position: relative; }
            .index .news .wap-news .detail2 .news-img .img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              overflow: hidden;
              position: absolute;
              bottom: 1.5vw;
              right: 3.2vw;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center; }
          .index .news .wap-news .detail2 .news-right h5 {
            font-size: 15px;
            color: #000000;
            font-weight: bold;
            margin-bottom: 1vw;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden; }
          .index .news .wap-news .detail2 .news-right h4 {
            font-size: 12px;
            color: #000000;
            margin-bottom: 0;
            width: 20vw; }
          .index .news .wap-news .detail2 .news-right p {
            font-size: 12px;
            margin-bottom: 0;
            color: #000000;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden; } }

.inside-page {
  background: url(../images/page-footer-back.jpg) no-repeat bottom center; }
  .inside-page .list-nav {
    background: url(../images/list-nav-back.png) no-repeat center right;
    margin: 30px 0; }
    .inside-page .list-nav .nav .nav-item {
      background-color: #ffffff; }
      .inside-page .list-nav .nav .nav-item:nth-child(1) .nav-link::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        width: 1px;
        height: 35px;
        background: #535353; }
      .inside-page .list-nav .nav .nav-item:nth-last-child(1) {
        padding-right: 50px; }
      .inside-page .list-nav .nav .nav-item .nav-link {
        text-align: center;
        position: relative;
        padding: 10px 25px; }
        .inside-page .list-nav .nav .nav-item .nav-link h5 {
          font-size: 20px;
          margin-bottom: 0;
          color: #535353; }
        .inside-page .list-nav .nav .nav-item .nav-link p {
          text-transform: uppercase;
          font-size: 12px;
          color: #535353;
          margin-bottom: 0; }
        .inside-page .list-nav .nav .nav-item .nav-link::after {
          content: "";
          position: absolute;
          top: 10px;
          right: 0;
          width: 1px;
          height: 35px;
          background: #535353; }
      .inside-page .list-nav .nav .nav-item .active h5 {
        color: #da261e; }
      .inside-page .list-nav .nav .nav-item .active p {
        color: #da261e; }
  @media (max-width: 992px) {
    .inside-page {
      margin-bottom: 3rem; }
      .inside-page .list-nav {
        margin: 3vw 0;
        background: none; }
        .inside-page .list-nav .nav .nav-item {
          background-color: #ffffff; }
          .inside-page .list-nav .nav .nav-item:nth-child(1) .nav-link::before {
            content: "";
            position: absolute;
            top: 4vw;
            left: 0;
            width: 1px;
            height: 6vw;
            background: #535353; }
          .inside-page .list-nav .nav .nav-item:nth-last-child(1) {
            padding-right: 0; }
          .inside-page .list-nav .nav .nav-item .nav-link {
            text-align: center;
            position: relative;
            padding: 3vw; }
            .inside-page .list-nav .nav .nav-item .nav-link h5 {
              font-size: 14px;
              margin-bottom: 0;
              color: #535353; }
            .inside-page .list-nav .nav .nav-item .nav-link p {
              text-transform: uppercase;
              font-size: 7px;
              color: #535353;
              margin-bottom: 0; }
            .inside-page .list-nav .nav .nav-item .nav-link::after {
              content: "";
              position: absolute;
              top: 4vw;
              right: 0;
              width: 1px;
              height: 6vw;
              background: #535353; }
          .inside-page .list-nav .nav .nav-item .active h5 {
            color: #da261e; }
          .inside-page .list-nav .nav .nav-item .active p {
            color: #da261e; } }

.page-introduction {
  padding-bottom: 235px; }
  .page-introduction .model-title h1 {
    font-weight: bold;
    font-size: 27px;
    text-transform: uppercase;
    margin-bottom: 0;
    line-height: 35px; }
  .page-introduction .model-title h2 {
    font-size: 30px;
    padding: 10px 0 20px;
    margin-bottom: 0;
    letter-spacing: 2px; }
  .page-introduction .model-title hr {
    background-color: #000000;
    width: 150px;
    height: 1px;
    margin: 0; }
  .page-introduction .company {
    background-color: #f3f3f3;
    padding: 3rem 0;
    position: relative; }
    .page-introduction .company .row .left .content {
      padding-top: 1rem; }
      .page-introduction .company .row .left .content p {
        text-indent: 2rem;
        line-height: 37px;
        font-size: 16px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 11;
        -webkit-box-orient: vertical;
        overflow: hidden; }
    .page-introduction .company .row .right-img {
      position: absolute;
      right: 0;
      top: 3rem;
      width: 850px; }
      .page-introduction .company .row .right-img img {
        width: 100%; }
  .page-introduction .office {
    padding: 8rem 0 3rem; }
    .page-introduction .office .row {
      position: relative; }
      .page-introduction .office .row .left {
        padding-right: 70px; }
        .page-introduction .office .row .left .office-top .img {
          overflow: hidden;
          height: 655px; }
          .page-introduction .office .row .left .office-top .img img {
            width: 100%; }
      .page-introduction .office .row .right .model-title {
        padding-bottom: 1rem; }
      .page-introduction .office .row .right p {
        text-indent: 2rem;
        line-height: 37px;
        font-size: 16px; }
      .page-introduction .office .row .office-thumbs {
        position: absolute;
        right: 0;
        bottom: 30px;
        width: 62%; }
        .page-introduction .office .row .office-thumbs .img {
          overflow: hidden; }
          .page-introduction .office .row .office-thumbs .img img {
            width: 100%; }
      .page-introduction .office .row .office-thumbs .swiper-slide {
        height: 100%;
        position: relative; }
        .page-introduction .office .row .office-thumbs .swiper-slide .hover {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background-color: rgba(0, 0, 0, 0.5); }
      .page-introduction .office .row .office-thumbs .swiper-slide-thumb-active .hover {
        background-color: rgba(0, 0, 0, 0); }
    .page-introduction .office .swiper-button-prev {
      outline: none;
      top: 47%;
      background-image: none;
      background-color: #c6bba7;
      color: #000000;
      width: 100px;
      height: 45px;
      border-radius: 0;
      text-align: center;
      left: 89%;
      font-size: 30px; }
      .page-introduction .office .swiper-button-prev:hover {
        background-color: #32373b;
        color: #ffffff; }
    .page-introduction .office .swiper-button-next {
      outline: none;
      top: 47%;
      color: #000000;
      background-image: none;
      background-color: #c6bba7;
      width: 100px;
      height: 45px;
      border-radius: 0;
      text-align: center;
      right: 0;
      font-size: 30px; }
      .page-introduction .office .swiper-button-next:hover {
        background-color: #32373b;
        color: #ffffff; }
  .page-introduction .conference {
    padding: 5rem 0 5rem; }
    .page-introduction .conference .row {
      position: relative; }
      .page-introduction .conference .row .left .model-title {
        padding-bottom: 1rem; }
      .page-introduction .conference .row .left p {
        text-indent: 2rem;
        line-height: 37px;
        font-size: 16px; }
      .page-introduction .conference .row .right {
        padding-left: 70px; }
        .page-introduction .conference .row .right .office-top .img {
          overflow: hidden;
          height: 655px; }
          .page-introduction .conference .row .right .office-top .img img {
            width: 100%; }
      .page-introduction .conference .row .conference-thumbs {
        position: absolute;
        left: 0;
        bottom: 30px;
        width: 62%; }
        .page-introduction .conference .row .conference-thumbs .img {
          overflow: hidden; }
          .page-introduction .conference .row .conference-thumbs .img img {
            width: 100%; }
      .page-introduction .conference .row .conference-thumbs .swiper-slide {
        height: 100%;
        position: relative; }
        .page-introduction .conference .row .conference-thumbs .swiper-slide .hover {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background-color: rgba(0, 0, 0, 0.5); }
      .page-introduction .conference .row .conference-thumbs .swiper-slide-thumb-active .hover {
        background-color: rgba(0, 0, 0, 0); }
    .page-introduction .conference .swiper-button-prev {
      outline: none;
      top: 47%;
      background-image: none;
      background-color: #c6bba7;
      color: #000000;
      width: 100px;
      height: 45px;
      border-radius: 0;
      text-align: center;
      left: 0;
      font-size: 30px; }
      .page-introduction .conference .swiper-button-prev:hover {
        background-color: #32373b;
        color: #ffffff; }
    .page-introduction .conference .swiper-button-next {
      outline: none;
      top: 47%;
      color: #000000;
      background-image: none;
      background-color: #c6bba7;
      width: 100px;
      height: 45px;
      border-radius: 0;
      text-align: center;
      right: 89%;
      font-size: 30px; }
      .page-introduction .conference .swiper-button-next:hover {
        background-color: #32373b;
        color: #ffffff; }
  .page-introduction .qualification {
    background: url(../images/introduction/qualification-back.jpg) no-repeat center center;
    padding: 3rem 0; }
    .page-introduction .qualification .title h1 {
      font-weight: bold;
      font-size: 27px;
      text-transform: uppercase;
      margin-bottom: 0;
      line-height: 35px; }
    .page-introduction .qualification .title h3 {
      font-size: 25px;
      padding: 10px 0 20px;
      margin-bottom: 0;
      letter-spacing: 2px; }
    .page-introduction .qualification .title hr {
      background-color: #000000;
      width: 120px;
      height: 1px;
      margin: 0 auto; }
    .page-introduction .qualification .pc-content {
      padding-top: 3rem; }
      .page-introduction .qualification .pc-content .qualification-pc-container {
        padding: 0 30px; }
        .page-introduction .qualification .pc-content .qualification-pc-container .detail {
          display: block;
          text-decoration: none; }
          .page-introduction .qualification .pc-content .qualification-pc-container .detail .img {
            width: 325px;
            height: 467px;
            overflow: hidden;
            transition: all .36s ease;
            margin: 0 auto; }
            .page-introduction .qualification .pc-content .qualification-pc-container .detail .img img {
              width: 100%; }
          .page-introduction .qualification .pc-content .qualification-pc-container .detail .text h5 {
            margin-bottom: 0;
            padding: 20px 0 0;
            font-size: 17px;
            color: #000000; }
          .page-introduction .qualification .pc-content .qualification-pc-container .detail:hover .img {
            box-shadow: 0px 0px 15px #9e9e9e;
            transition: all .36s ease; }
        .page-introduction .qualification .pc-content .qualification-pc-container .swiper-button-prev {
          outline: none;
          text-align: center;
          font-size: 45px;
          top: 45%;
          background-image: none;
          background-color: #bebebe;
          color: #ffffff;
          width: 40px;
          height: 70px;
          border-radius: 10px;
          left: 0px; }
        .page-introduction .qualification .pc-content .qualification-pc-container .swiper-button-next {
          outline: none;
          text-align: center;
          font-size: 45px;
          top: 45%;
          background-image: none;
          background-color: #bebebe;
          color: #ffffff;
          width: 40px;
          height: 70px;
          border-radius: 10px;
          right: 0px; }
    .page-introduction .qualification .wap-content {
      display: none; }
  .page-introduction .culture {
    background: url(../images/introduction/culture-pc-back.jpg) no-repeat center center;
    padding: 3rem 0; }
    .page-introduction .culture .left {
      padding-right: 80px; }
      .page-introduction .culture .left .write {
        padding-top: 2rem; }
        .page-introduction .culture .left .write p {
          text-indent: 2rem;
          line-height: 40px;
          font-size: 16px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 11;
          -webkit-box-orient: vertical;
          overflow: hidden;
          letter-spacing: 2px; }
    .page-introduction .culture .right {
      padding-left: 40px; }
      .page-introduction .culture .right .detail {
        margin: 10px 0 20px;
        height: 190px;
        overflow: hidden; }
        .page-introduction .culture .right .detail .one {
          transition: all 1s ease;
          margin: 0; }
        .page-introduction .culture .right .detail .icon {
          border: 2px solid #ffffff;
          border-radius: 100%;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
          width: 105px;
          height: 105px;
          margin: 0 auto 15px; }
        .page-introduction .culture .right .detail h4 {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 0;
          line-height: 35px;
          letter-spacing: 3px; }
        .page-introduction .culture .right .detail h5 {
          font-size: 14px;
          text-transform: uppercase;
          margin-bottom: 0;
          color: #ffffff;
          line-height: 35px;
          letter-spacing: 2px; }
        .page-introduction .culture .right .detail .hover .icon {
          border-color: #cbb796; }
        .page-introduction .culture .right .detail h3 {
          font-size: 20px;
          font-weight: bold;
          color: #cbb796;
          margin-bottom: 0;
          line-height: 35px;
          letter-spacing: 0; }
        .page-introduction .culture .right .detail p {
          font-size: 16px;
          color: #cbb796;
          margin-bottom: 0;
          line-height: 35px;
          padding: 0 30px;
          height: 70px;
          overflow: hidden; }
        .page-introduction .culture .right .detail:hover .one {
          margin-top: -190px;
          transition: all 1s ease; }
  @media (max-width: 1719px) {
    .page-introduction {
      padding-bottom: 210px; }
      .page-introduction .company .row .right-img {
        width: 700px; }
        .page-introduction .company .row .right-img img {
          width: 100%; }
      .page-introduction .office {
        padding: 5rem 0 3rem; }
        .page-introduction .office .row {
          position: relative; }
          .page-introduction .office .row .left {
            padding-right: 40px; }
            .page-introduction .office .row .left .office-top .img {
              overflow: hidden;
              height: 540px; }
              .page-introduction .office .row .left .office-top .img img {
                width: 100%; }
          .page-introduction .office .row .right .model-title {
            padding-bottom: 1rem; }
          .page-introduction .office .row .right p {
            text-indent: 2rem;
            line-height: 37px;
            font-size: 16px; }
        .page-introduction .office .swiper-button-prev {
          outline: none;
          top: 57%;
          background-image: none;
          background-color: #c6bba7;
          color: #000000;
          width: 100px;
          height: 45px;
          border-radius: 0;
          text-align: center;
          left: 86%;
          font-size: 30px; }
          .page-introduction .office .swiper-button-prev:hover {
            background-color: #32373b;
            color: #ffffff; }
        .page-introduction .office .swiper-button-next {
          outline: none;
          top: 57%;
          color: #000000;
          background-image: none;
          background-color: #c6bba7;
          width: 100px;
          height: 45px;
          border-radius: 0;
          text-align: center;
          right: 0;
          font-size: 30px; }
          .page-introduction .office .swiper-button-next:hover {
            background-color: #32373b;
            color: #ffffff; }
      .page-introduction .conference {
        padding: 5rem 0 5rem; }
        .page-introduction .conference .row .right {
          padding-left: 40px; }
          .page-introduction .conference .row .right .conference-top .img {
            overflow: hidden;
            height: 540px; }
            .page-introduction .conference .row .right .conference-top .img img {
              width: 100%; }
        .page-introduction .conference .row .left .model-title {
          padding-bottom: 1rem; }
        .page-introduction .conference .row .left p {
          text-indent: 2rem;
          line-height: 37px;
          font-size: 16px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden; }
        .page-introduction .conference .swiper-button-prev {
          top: 57%;
          width: 100px;
          height: 45px;
          border-radius: 0;
          left: 0;
          font-size: 30px; }
        .page-introduction .conference .swiper-button-next {
          top: 57%;
          width: 100px;
          height: 45px;
          border-radius: 0;
          right: 86%; } }
  @media (max-width: 1399px) {
    .page-introduction .company .row .right-img {
      width: 600px; } }
  @media (max-width: 992px) {
    .page-introduction {
      padding-bottom: 0; }
      .page-introduction .model-title h1 {
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 0;
        line-height: 6.5vw; }
      .page-introduction .model-title h2 {
        font-size: 19px;
        padding: 2vw 0 3vw;
        margin-bottom: 0;
        letter-spacing: 2px; }
      .page-introduction .model-title hr {
        background-color: #000000;
        width: 25vw;
        height: 1px;
        margin: 0; }
      .page-introduction .company {
        background-color: #f3f3f3;
        padding: 5vw 0;
        position: relative; }
        .page-introduction .company .row .left .content {
          padding-top: 1rem; }
          .page-introduction .company .row .left .content p {
            text-indent: 2rem;
            line-height: 7vw;
            font-size: 14px; }
      .page-introduction .office {
        padding: 7vw 0 5vw; }
        .page-introduction .office .container {
          overflow: hidden; }
        .page-introduction .office .row {
          position: relative;
          overflow: hidden; }
          .page-introduction .office .row .left {
            padding-right: 0; }
            .page-introduction .office .row .left .office-top .img {
              overflow: hidden;
              height: 66vw; }
              .page-introduction .office .row .left .office-top .img img {
                width: 100%; }
          .page-introduction .office .row .right .model-title {
            padding-bottom: 5vw; }
          .page-introduction .office .row .right p {
            text-indent: 2rem;
            line-height: 7vw;
            font-size: 14px; }
          .page-introduction .office .row .office-thumbs {
            opacity: 0; }
        .page-introduction .office .swiper-button-prev {
          top: 75%;
          width: 15vw;
          height: 7vw;
          left: 15px;
          font-size: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center; }
        .page-introduction .office .swiper-button-next {
          top: 75%;
          width: 15vw;
          height: 7vw;
          right: 15px;
          font-size: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center; }
      .page-introduction .conference {
        padding: 7vw 0 5vw; }
        .page-introduction .conference .container {
          overflow: hidden; }
        .page-introduction .conference .row {
          position: relative;
          overflow: hidden; }
          .page-introduction .conference .row .right {
            padding-left: 0; }
            .page-introduction .conference .row .right .conference-top .img {
              overflow: hidden;
              height: 66vw; }
              .page-introduction .conference .row .right .conference-top .img img {
                width: 100%; }
          .page-introduction .conference .row .left .model-title {
            padding-bottom: 5vw; }
          .page-introduction .conference .row .left p {
            text-indent: 2rem;
            line-height: 7vw;
            font-size: 14px; }
          .page-introduction .conference .row .conference-thumbs {
            opacity: 0; }
        .page-introduction .conference .swiper-button-prev {
          top: 75%;
          width: 15vw;
          height: 7vw;
          left: 15px;
          font-size: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center; }
        .page-introduction .conference .swiper-button-next {
          top: 75%;
          width: 15vw;
          height: 7vw;
          right: 15px;
          font-size: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center; }
      .page-introduction .qualification {
        background: url(../images/introduction/qualification-back.jpg) no-repeat center center;
        padding: 7vw 0; }
        .page-introduction .qualification .title h1 {
          font-weight: bold;
          font-size: 18px;
          text-transform: uppercase;
          margin-bottom: 0;
          line-height: 6.5vw; }
        .page-introduction .qualification .title h3 {
          font-size: 20px;
          padding: 2vw 0 3vw;
          margin-bottom: 0;
          letter-spacing: 2px; }
        .page-introduction .qualification .title hr {
          background-color: #000000;
          width: 25vw;
          height: 1px;
          margin: 0 auto; }
        .page-introduction .qualification .pc-content {
          display: none; }
        .page-introduction .qualification .wap-content {
          display: block;
          padding: 5vw 15vw 0;
          position: relative; }
          .page-introduction .qualification .wap-content .qualification-wap-container {
            padding: 0;
            overflow: hidden; }
            .page-introduction .qualification .wap-content .qualification-wap-container .detail {
              display: block;
              text-decoration: none; }
              .page-introduction .qualification .wap-content .qualification-wap-container .detail .img {
                width: auto;
                height: 89vw;
                overflow: hidden;
                margin: 0 auto; }
                .page-introduction .qualification .wap-content .qualification-wap-container .detail .img img {
                  width: 100%; }
              .page-introduction .qualification .wap-content .qualification-wap-container .detail .text h5 {
                margin-bottom: 0;
                padding: 3vw 0 0;
                font-size: 14px;
                color: #000000; }
          .page-introduction .qualification .wap-content .swiper-button-prev {
            outline: none;
            text-align: center;
            font-size: 25px;
            top: 45%;
            background-image: none;
            background-color: #bebebe;
            color: #ffffff;
            width: 7vw;
            height: 15vw;
            border-radius: 10px;
            left: 0px; }
          .page-introduction .qualification .wap-content .swiper-button-next {
            outline: none;
            text-align: center;
            font-size: 25px;
            top: 45%;
            background-image: none;
            background-color: #bebebe;
            color: #ffffff;
            width: 7vw;
            height: 15vw;
            border-radius: 10px;
            right: 0px; }
      .page-introduction .culture {
        background: none;
        padding: 0; }
        .page-introduction .culture .container {
          padding: 0; }
        .page-introduction .culture .left {
          padding: 5vw 5vw;
          background: #c6bba7; }
          .page-introduction .culture .left .write {
            padding-top: 5vw; }
            .page-introduction .culture .left .write p {
              text-indent: 2rem;
              line-height: 7vw;
              font-size: 14px;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 11;
              -webkit-box-orient: vertical;
              overflow: hidden;
              letter-spacing: 0px; }
        .page-introduction .culture .right {
          background: url(../images/introduction/culture-wap-back.jpg) no-repeat center center;
          padding: 5vw 5vw; }
          .page-introduction .culture .right .detail {
            margin: 3vw 0 5vw;
            height: 36vw;
            overflow: hidden; }
            .page-introduction .culture .right .detail .one {
              transition: all 1s ease;
              margin: 0; }
            .page-introduction .culture .right .detail .icon {
              border: 2px solid #ffffff;
              border-radius: 100%;
              width: 20vw;
              height: 20vw;
              margin: 0 auto 3vw; }
              .page-introduction .culture .right .detail .icon img {
                width: 7vw; }
            .page-introduction .culture .right .detail h4 {
              font-size: 17px;
              font-weight: bold;
              color: #ffffff;
              margin-bottom: 0;
              line-height: 7vw;
              letter-spacing: 1px; }
            .page-introduction .culture .right .detail h5 {
              font-size: 10px;
              text-transform: uppercase;
              margin-bottom: 0;
              color: #ffffff;
              line-height: 7vw;
              letter-spacing: 0px; }
            .page-introduction .culture .right .detail .hover .icon {
              border-color: #cbb796; }
            .page-introduction .culture .right .detail h3 {
              font-size: 17px;
              font-weight: bold;
              color: #cbb796;
              margin-bottom: 0;
              line-height: 7vw;
              letter-spacing: 0; }
            .page-introduction .culture .right .detail p {
              font-size: 12px;
              color: #cbb796;
              margin-bottom: 0;
              line-height: 7vw;
              padding: 0 2vw;
              height: 14vw;
              overflow: hidden; }
            .page-introduction .culture .right .detail:hover .one {
              margin-top: -36vw;
              transition: all 1s ease; } }

.case-list .case-list-carousel {
  margin-bottom: 50px; }
  .case-list .case-list-carousel .case-list-pc-container .detail {
    position: relative; }
    .case-list .case-list-carousel .case-list-pc-container .detail .img {
      height: 440px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center; }
      .case-list .case-list-carousel .case-list-pc-container .detail .img img {
        height: 100%; }
    .case-list .case-list-carousel .case-list-pc-container .detail .hover {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      transition: all .36s ease;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: #ffffff;
      padding: 330px 0 0 50px; }
      .case-list .case-list-carousel .case-list-pc-container .detail .hover h3 {
        font-size: 24px;
        letter-spacing: 2px;
        font-weight: bold;
        margin-bottom: 0; }
      .case-list .case-list-carousel .case-list-pc-container .detail .hover h4 {
        font-size: 16px;
        padding: 10px 0;
        margin-bottom: 0; }
      .case-list .case-list-carousel .case-list-pc-container .detail .hover h5 {
        font-size: 16px;
        margin-bottom: 0;
        text-align: right;
        max-width: 85px;
        transition: all 1s ease; }
    .case-list .case-list-carousel .case-list-pc-container .detail:hover .hover {
      background-color: rgba(0, 0, 0, 0.5);
      transition: all .36s ease; }
      .case-list .case-list-carousel .case-list-pc-container .detail:hover .hover h5 {
        max-width: 700px;
        transition: all 1s ease; }
  .case-list .case-list-carousel .case-list-wap-container {
    display: none; }

.case-list .case-list-content .row {
  margin-right: -20px;
  margin-left: -20px; }
  .case-list .case-list-content .row [class^="col-"] {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px; }

.case-list .case-list-content .detail {
  position: relative; }
  .case-list .case-list-content .detail .img {
    height: 440px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; }
    .case-list .case-list-content .detail .img img {
      height: 100%; }
  .case-list .case-list-content .detail .hover {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all .36s ease;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #ffffff;
    padding: 330px 0 0 30px; }
    .case-list .case-list-content .detail .hover h3 {
      font-size: 24px;
      letter-spacing: 2px;
      font-weight: bold;
      margin-bottom: 0; }
    .case-list .case-list-content .detail .hover h4 {
      font-size: 16px;
      padding: 10px 0;
      margin-bottom: 0; }
    .case-list .case-list-content .detail .hover h5 {
      font-size: 16px;
      margin-bottom: 0;
      text-align: right;
      max-width: 85px;
      transition: all 1s ease; }
  .case-list .case-list-content .detail:hover .hover {
    opacity: 1;
    transition: all .36s ease; }
    .case-list .case-list-content .detail:hover .hover h5 {
      max-width: 470px;
      transition: all 1s ease; }

@media (max-width: 1719px) {
  .case-list .case-list-carousel .case-list-pc-container .detail:hover .hover h5 {
    max-width: 550px;
    transition: all 1s ease; }
  .case-list .case-list-carousel .case-list-wap-container {
    display: none; }
  .case-list .case-list-content .detail:hover .hover h5 {
    max-width: 370px;
    transition: all 1s ease; } }

@media (max-width: 1399px) {
  .case-list .case-list-carousel .case-list-pc-container .detail:hover .hover h5 {
    max-width: 450px;
    transition: all 1s ease; }
  .case-list .case-list-content .detail:hover .hover h5 {
    max-width: 300px;
    transition: all 1s ease; } }

@media (max-width: 992px) {
  .case-list .case-list-carousel {
    margin-bottom: 5vw; }
    .case-list .case-list-carousel .case-list-pc-container {
      display: none; }
    .case-list .case-list-carousel .case-list-wap-container {
      display: block; }
      .case-list .case-list-carousel .case-list-wap-container .detail {
        position: relative; }
        .case-list .case-list-carousel .case-list-wap-container .detail .img {
          height: 49vw;
          overflow: hidden; }
          .case-list .case-list-carousel .case-list-wap-container .detail .img img {
            width: 100%; }
        .case-list .case-list-carousel .case-list-wap-container .detail .hover {
          position: absolute;
          background-color: rgba(0, 0, 0, 0.2);
          transition: all .36s ease;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: #ffffff;
          padding: 29vw 0 0 5vw; }
          .case-list .case-list-carousel .case-list-wap-container .detail .hover h3 {
            font-size: 16px;
            letter-spacing: 2px;
            font-weight: bold;
            margin-bottom: 0; }
          .case-list .case-list-carousel .case-list-wap-container .detail .hover h4 {
            font-size: 12px;
            padding: 1.5vw 0;
            margin-bottom: 0; }
          .case-list .case-list-carousel .case-list-wap-container .detail .hover h5 {
            font-size: 12px;
            margin-bottom: 0;
            text-align: left; }
  .case-list .case-list-content .row {
    margin-right: -2vw;
    margin-left: -2vw; }
    .case-list .case-list-content .row [class^="col-"] {
      padding-left: 2vw;
      padding-right: 2vw;
      margin-bottom: 4vw; }
  .case-list .case-list-content .detail {
    position: relative; }
    .case-list .case-list-content .detail .img {
      height: 40vw; }
    .case-list .case-list-content .detail .hover {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      opacity: 1;
      padding: 27vw 0 0 3vw; }
      .case-list .case-list-content .detail .hover h3 {
        font-size: 15px;
        letter-spacing: 2px;
        font-weight: bold;
        margin-bottom: 0; }
      .case-list .case-list-content .detail .hover h4 {
        font-size: 12px;
        padding: 1.5vw 0;
        margin-bottom: 0;
        display: none; }
      .case-list .case-list-content .detail .hover h5 {
        font-size: 12px;
        margin-bottom: 0;
        text-align: left;
        padding-top: 2vw; } }

.page-contact .contact-container {
  background-color: #f6f6f6;
  padding: 30px 80px 50px;
  margin-top: 70px; }
  .page-contact .contact-container .title h1 {
    font-size: 30px;
    color: #000;
    font-weight: bold; }
    .page-contact .contact-container .title h1 span {
      padding-right: 10px; }
  .page-contact .contact-container .title hr {
    margin: 20px auto; }
  .page-contact .contact-container .row {
    margin-right: -70px;
    margin-left: -70px; }
    .page-contact .contact-container .row [class^="col-"] {
      padding-left: 70px;
      padding-right: 70px; }
    .page-contact .contact-container .row .text {
      padding: 10px 0; }
      .page-contact .contact-container .row .text h5 {
        font-size: 17px;
        color: #757575;
        margin-bottom: 10px; }
      .page-contact .contact-container .row .text h1 {
        font-size: 33px;
        font-weight: bold;
        font-family: fantasy; }
    .page-contact .contact-container .row .erweima {
      text-align: center; }
      .page-contact .contact-container .row .erweima .img {
        width: 120px;
        height: 120px;
        margin: 0 auto; }
        .page-contact .contact-container .row .erweima .img img {
          width: 100%; }
      .page-contact .contact-container .row .erweima h5 {
        font-size: 17px;
        color: #757575;
        margin-top: 10px; }

.page-contact .contact-message {
  padding: 3rem 0; }
  .page-contact .contact-message .title h1 {
    font-size: 60px;
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 10px;
    color: #000;
    font-style: italic; }
  .page-contact .contact-message .title h5 {
    font-size: 15px;
    color: #000; }
  .page-contact .contact-message form {
    padding-top: 40px; }
    .page-contact .contact-message form .form-group {
      margin-bottom: 30px; }
      .page-contact .contact-message form .form-group .form-control1 {
        height: 50px; }
      .page-contact .contact-message form .form-group .form-control2 {
        line-height: 30px; }
    .page-contact .contact-message form button {
      background: linear-gradient(180deg, #b6a68b, #3a434e);
      border: none;
      padding: 10px 50px;
      font-size: 17px;
      letter-spacing: 2px;
      border-radius: 0;
      margin: 20px 0 30px; }

@media (max-width: 1719px) {
  .page-contact .contact-container .row {
    margin-right: -20px;
    margin-left: -20px; }
    .page-contact .contact-container .row [class^="col-"] {
      padding-left: 20px;
      padding-right: 20px; } }

@media (max-width: 1399px) {
  .page-contact .contact-container .row {
    margin-right: -20px;
    margin-left: -20px; }
    .page-contact .contact-container .row [class^="col-"] {
      padding-left: 20px;
      padding-right: 20px; } }

@media (max-width: 992px) {
  .page-contact .contact-container {
    background-color: #f6f6f6;
    padding: 5vw 5vw;
    margin-top: 5vw; }
    .page-contact .contact-container .title h1 {
      font-size: 21px;
      color: #000;
      font-weight: bold; }
      .page-contact .contact-container .title h1 span {
        padding-right: 2vw; }
    .page-contact .contact-container .title hr {
      margin: 5vw auto; }
    .page-contact .contact-container .row {
      margin-right: -3vw;
      margin-left: -3vw; }
      .page-contact .contact-container .row [class^="col-"] {
        padding-left: 3vw;
        padding-right: 3vw; }
      .page-contact .contact-container .row .text {
        padding: 3vw 0; }
        .page-contact .contact-container .row .text h5 {
          font-size: 14px;
          color: #757575;
          margin-bottom: 10px; }
        .page-contact .contact-container .row .text h1 {
          font-size: 17px;
          font-weight: bold;
          font-family: fantasy; }
      .page-contact .contact-container .row .erweima {
        text-align: center; }
        .page-contact .contact-container .row .erweima .img {
          width: 30vw;
          height: 30vw;
          margin: 0 auto; }
          .page-contact .contact-container .row .erweima .img img {
            width: 100%; }
        .page-contact .contact-container .row .erweima h5 {
          font-size: 13px;
          color: #757575;
          margin-top: 3vw; }
  .page-contact .contact-message {
    padding: 5vw 0; }
    .page-contact .contact-message .title h1 {
      font-size: 35px;
      font-family: 'Times New Roman', Times, serif;
      margin-bottom: 10px;
      color: #000;
      font-style: italic; }
    .page-contact .contact-message .title h5 {
      font-size: 12px;
      color: #000; }
    .page-contact .contact-message form {
      padding-top: 3vw; }
      .page-contact .contact-message form .form-group {
        margin-bottom: 3vw; }
        .page-contact .contact-message form .form-group .form-control1 {
          height: 10vw; }
        .page-contact .contact-message form .form-group .form-control2 {
          line-height: 30px; }
      .page-contact .contact-message form button {
        background: linear-gradient(180deg, #b6a68b, #3a434e);
        border: none;
        padding: 2vw 5vw;
        font-size: 14px;
        letter-spacing: 2px;
        border-radius: 0;
        margin: 3vw 0 5vw; } }

.case-view .view-content {
  box-shadow: 0px 2px 15px #dadada;
  padding: 30px; }
  .case-view .view-content .left {
    position: relative; }
  .case-view .view-content .case-view-gallery-top .swiper-slide .img {
    overflow: hidden; }
    .case-view .view-content .case-view-gallery-top .swiper-slide .img img {
      width: 100%; }
  .case-view .view-content .case-swiper-thumb {
    padding: 20px 75px 0; }
    .case-view .view-content .case-swiper-thumb .case-view-gallery-thumbs .swiper-slide {
      height: 100%;
      position: relative; }
      .case-view .view-content .case-swiper-thumb .case-view-gallery-thumbs .swiper-slide .img {
        height: 165px;
        overflow: hidden;
        border-radius: 20px; }
        .case-view .view-content .case-swiper-thumb .case-view-gallery-thumbs .swiper-slide .img img {
          width: 100%; }
      .case-view .view-content .case-swiper-thumb .case-view-gallery-thumbs .swiper-slide .hover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.5); }
    .case-view .view-content .case-swiper-thumb .case-view-gallery-thumbs .swiper-slide-thumb-active .hover {
      background-color: rgba(0, 0, 0, 0); }
  .case-view .view-content .swiper-button-prev {
    outline: none;
    top: auto;
    bottom: 0;
    background-image: none;
    background-color: #c6bba7;
    color: #32373b;
    width: 60px;
    height: 165px;
    border-radius: 20px;
    text-align: center;
    left: 0;
    font-size: 50px; }
    .case-view .view-content .swiper-button-prev:hover {
      background-color: #32373b;
      color: #ffffff; }
  .case-view .view-content .swiper-button-next {
    outline: none;
    top: auto;
    bottom: 0;
    color: #32373b;
    background-image: none;
    background-color: #c6bba7;
    width: 60px;
    height: 165px;
    border-radius: 20px;
    text-align: center;
    right: 0;
    font-size: 50px; }
    .case-view .view-content .swiper-button-next:hover {
      background-color: #32373b;
      color: #ffffff; }
  .case-view .view-content .right {
    padding: 60px 0 0 40px; }
    .case-view .view-content .right h2 {
      font-size: 35px;
      font-weight: bold;
      margin-bottom: 15px; }
    .case-view .view-content .right hr {
      background-color: #cbb796;
      height: 1px; }
    .case-view .view-content .right h5 {
      font-size: 17px;
      line-height: 50px;
      margin-bottom: 0; }
    .case-view .view-content .right p {
      font-size: 16px;
      line-height: 40px;
      margin-bottom: 0;
      text-indent: 2rem; }
    .case-view .view-content .right button {
      color: #32373b;
      padding: 10px 40px;
      font-size: 16px;
      letter-spacing: 1px;
      border-radius: 0;
      margin: 30px 0; }
    .case-view .view-content .right .arrows {
      display: block;
      position: absolute;
      bottom: 0;
      width: calc(100% - 30px); }
      .case-view .view-content .right .arrows .page-arrows {
        border: 1px solid #32373b; }
        .case-view .view-content .right .arrows .page-arrows .page-arrow {
          height: 50px; }

.case-view .relevant {
  padding: 5rem 0 8rem; }
  .case-view .relevant .title {
    padding-bottom: 30px; }
    .case-view .relevant .title h2 {
      font-size: 30px;
      margin-bottom: 0;
      color: #000000;
      font-weight: bold;
      letter-spacing: 3px; }
      .case-view .relevant .title h2 span {
        font-size: 20px;
        font-weight: normal;
        color: #000;
        text-transform: uppercase; }
  .case-view .relevant .row {
    margin-right: -20px;
    margin-left: -20px; }
    .case-view .relevant .row [class^="col-"] {
      padding-left: 20px;
      padding-right: 20px; }
    .case-view .relevant .row .detail {
      text-decoration: none;
      transition: all .36s ease; }
      .case-view .relevant .row .detail .img {
        overflow: hidden; }
        .case-view .relevant .row .detail .img img {
          width: 100%; }
      .case-view .relevant .row .detail .text {
        padding: 20px 0; }
        .case-view .relevant .row .detail .text h5 {
          color: #32373b;
          font-size: 17px;
          margin-bottom: 0; }
      .case-view .relevant .row .detail:hover {
        background-color: #ffffff;
        box-shadow: 0px 2px 15px #dadada;
        transition: all .36s ease; }

@media (max-width: 1719px) {
  .case-view .view-content .case-swiper-thumb {
    padding: 20px 75px 0; }
    .case-view .view-content .case-swiper-thumb .case-view-gallery-thumbs .swiper-slide .img {
      height: 125px; }
  .case-view .view-content .swiper-button-prev {
    width: 55px;
    height: 125px;
    font-size: 50px; }
  .case-view .view-content .swiper-button-next {
    width: 55px;
    height: 125px;
    font-size: 50px; }
  .case-view .view-content .right {
    padding: 30px 0 0 40px; }
    .case-view .view-content .right h2 {
      font-size: 35px;
      font-weight: bold;
      margin-bottom: 10px; }
    .case-view .view-content .right hr {
      background-color: #cbb796;
      height: 1px; }
    .case-view .view-content .right h5 {
      font-size: 17px;
      line-height: 40px;
      margin-bottom: 0; }
    .case-view .view-content .right p {
      font-size: 16px;
      line-height: 35px;
      margin-bottom: 0;
      text-indent: 2rem; }
    .case-view .view-content .right button {
      color: #32373b;
      padding: 10px 40px;
      font-size: 16px;
      letter-spacing: 1px;
      border-radius: 0;
      margin: 20px 0; } }

@media (max-width: 1399px) {
  .case-view .view-content .case-swiper-thumb {
    padding: 20px 60px 0; }
    .case-view .view-content .case-swiper-thumb .case-view-gallery-thumbs .swiper-slide .img {
      height: 105px; }
  .case-view .view-content .swiper-button-prev {
    width: 40px;
    height: 105px;
    font-size: 40px; }
  .case-view .view-content .swiper-button-next {
    width: 40px;
    height: 105px;
    font-size: 40px; }
  .case-view .view-content .right {
    padding: 0px 0 0 30px; }
    .case-view .view-content .right h2 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 0; }
    .case-view .view-content .right h5 {
      font-size: 17px;
      line-height: 35px;
      margin-bottom: 0; }
    .case-view .view-content .right p {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 0;
      text-indent: 2rem;
      height: 270px;
      overflow: hidden; }
    .case-view .view-content .right button {
      color: #32373b;
      padding: 10px 40px;
      font-size: 16px;
      letter-spacing: 1px;
      border-radius: 0;
      margin: 15px 0; } }

@media (max-width: 992px) {
  .case-view .view-content {
    box-shadow: 0px 2px 15px #dadada;
    padding: 5vw; }
    .case-view .view-content .left {
      position: relative; }
    .case-view .view-content .case-swiper-thumb {
      padding: 3vw 7vw 0; }
      .case-view .view-content .case-swiper-thumb .case-view-gallery-thumbs .swiper-slide {
        height: 100%;
        position: relative; }
        .case-view .view-content .case-swiper-thumb .case-view-gallery-thumbs .swiper-slide .img {
          height: 13vw;
          overflow: hidden;
          border-radius: 1vw; }
          .case-view .view-content .case-swiper-thumb .case-view-gallery-thumbs .swiper-slide .img img {
            width: 100%; }
        .case-view .view-content .case-swiper-thumb .case-view-gallery-thumbs .swiper-slide .hover {
          border-radius: 1vw; }
    .case-view .view-content .swiper-button-prev {
      width: 5vw;
      height: 13vw;
      border-radius: 1vw;
      font-size: 20px; }
    .case-view .view-content .swiper-button-next {
      width: 5vw;
      height: 13vw;
      border-radius: 1vw;
      font-size: 20px; }
    .case-view .view-content .right {
      padding: 8vw 0 15vw; }
      .case-view .view-content .right h2 {
        font-size: 21px;
        font-weight: bold;
        margin-bottom: 4vw; }
      .case-view .view-content .right hr {
        background-color: #cbb796;
        height: 1px;
        margin: 2vw 0; }
      .case-view .view-content .right h5 {
        font-size: 15px;
        line-height: 8vw;
        margin-bottom: 0; }
      .case-view .view-content .right p {
        font-size: 13px;
        line-height: 7vw;
        margin-bottom: 0;
        text-indent: 2rem;
        height: 100%; }
      .case-view .view-content .right button {
        color: #32373b;
        padding: 2vw 5vw;
        font-size: 13px;
        letter-spacing: 1px;
        border-radius: 0;
        margin: 3vw 0 0; }
      .case-view .view-content .right .arrows {
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%; }
        .case-view .view-content .right .arrows .page-arrows {
          border: 1px solid #32373b; }
          .case-view .view-content .right .arrows .page-arrows .page-arrow {
            height: 10vw;
            font-size: 14px; }
  .case-view .relevant {
    padding: 7vw 0; }
    .case-view .relevant .title {
      padding-bottom: 7vw; }
      .case-view .relevant .title h2 {
        font-size: 22px;
        margin-bottom: 0;
        color: #000000;
        font-weight: bold;
        letter-spacing: 1px; }
        .case-view .relevant .title h2 span {
          font-size: 15px;
          font-weight: normal;
          color: #000;
          text-transform: uppercase; }
    .case-view .relevant .row {
      margin-right: -2vw;
      margin-left: -2vw; }
      .case-view .relevant .row [class^="col-"] {
        padding-left: 2vw;
        padding-right: 2vw; }
      .case-view .relevant .row .detail {
        text-decoration: none;
        transition: all .36s ease; }
        .case-view .relevant .row .detail .img {
          overflow: hidden; }
          .case-view .relevant .row .detail .img img {
            width: 100%; }
        .case-view .relevant .row .detail .text {
          padding: 3vw 0; }
          .case-view .relevant .row .detail .text h5 {
            color: #32373b;
            font-size: 14px;
            margin-bottom: 0; }
        .case-view .relevant .row .detail:hover {
          background-color: #ffffff;
          box-shadow: 0px 2px 15px #dadada;
          transition: all .36s ease; } }

.article-list a {
  text-decoration: none !important; }

.article-list .article-list-carousel {
  padding: 1rem 0 30px; }
  .article-list .article-list-carousel .article-list-container .swiper-pagination {
    width: 50%;
    left: calc(50% + 35px);
    bottom: 50px;
    display: flex;
    justify-content: start;
    align-items: center; }
    .article-list .article-list-carousel .article-list-container .swiper-pagination .swiper-pagination-bullet {
      width: 45px;
      height: 3px;
      border-radius: 20px; }
  .article-list .article-list-carousel .detail {
    background-color: #f3f4f8;
    transition: all .36s ease; }
    .article-list .article-list-carousel .detail .row .left {
      padding-right: 30px;
      display: flex;
      align-items: center;
      height: 100%; }
      .article-list .article-list-carousel .detail .row .left .img {
        height: 475px;
        overflow: hidden; }
        .article-list .article-list-carousel .detail .row .left .img img {
          transition: all .36s ease;
          width: 100%; }
    .article-list .article-list-carousel .detail .row .right {
      padding: 50px 50px 50px 35px; }
      .article-list .article-list-carousel .detail .row .right h4 {
        font-size: 25px;
        margin-bottom: 15px;
        color: #32373b;
        line-height: 35px; }
      .article-list .article-list-carousel .detail .row .right h5 {
        color: #32373b;
        font-size: 18px;
        margin-bottom: 10px; }
      .article-list .article-list-carousel .detail .row .right hr {
        width: 50px;
        height: 2px;
        background-color: #32373b;
        margin: 15px 0 50px; }
      .article-list .article-list-carousel .detail .row .right p {
        color: #898989;
        font-size: 17px;
        margin-bottom: 0;
        line-height: 35px;
        transition: all .36s ease; }
    .article-list .article-list-carousel .detail:hover {
      transition: all .36s ease; }
      .article-list .article-list-carousel .detail:hover .img img {
        transform: scale(1.08);
        transition: all .36s ease; }
      .article-list .article-list-carousel .detail:hover .right p {
        color: #32373b;
        transition: all .36s ease; }

.article-list .article-list-content {
  padding-top: 50px;
  padding-bottom: 100px; }
  .article-list .article-list-content > .row {
    margin-right: -15px;
    margin-left: -15px; }
    .article-list .article-list-content > .row [class^="col-"] {
      padding-left: 15px;
      padding-right: 15px; }
    .article-list .article-list-content > .row .detail {
      border: 1px solid #cbb796;
      transition: all .36s ease;
      margin-bottom: 50px; }
      .article-list .article-list-content > .row .detail .row {
        margin: auto; }
        .article-list .article-list-content > .row .detail .row [class^="col-"] {
          padding: 0; }
      .article-list .article-list-content > .row .detail .left {
        padding: 30px 0 30px 30px; }
        .article-list .article-list-content > .row .detail .left h4 {
          font-size: 22px;
          color: #32373b;
          margin-bottom: 10px;
          line-height: 30px;
          height: 60px;
          overflow: hidden;
          transition: all .36s ease; }
        .article-list .article-list-content > .row .detail .left p {
          color: #787878;
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 0; }
        .article-list .article-list-content > .row .detail .left hr {
          background-color: #cbb796;
          margin: 20px 0; }
        .article-list .article-list-content > .row .detail .left h5 {
          font-size: 14px;
          margin-bottom: 0;
          color: #787878;
          max-width: 80px;
          text-align: right;
          transition: all 1s ease; }
      .article-list .article-list-content > .row .detail .right {
        padding-left: 25px; }
        .article-list .article-list-content > .row .detail .right .img {
          height: 265px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center; }
          .article-list .article-list-content > .row .detail .right .img img {
            height: 100%; }
      .article-list .article-list-content > .row .detail:hover {
        box-shadow: 0px 2px 15px #32373b;
        transition: all .36s ease; }
        .article-list .article-list-content > .row .detail:hover .left h4 {
          color: #da271e;
          transition: all .36s ease; }
        .article-list .article-list-content > .row .detail:hover .left hr {
          background-color: #cbb796; }
        .article-list .article-list-content > .row .detail:hover .left h5 {
          max-width: 100%;
          transition: all 1s ease; }

@media (max-width: 1719px) {
  .article-list .article-list-carousel .article-list-container .swiper-pagination {
    width: 50%;
    left: calc(50% + 25px);
    bottom: 30px;
    display: flex;
    justify-content: start;
    align-items: center; }
    .article-list .article-list-carousel .article-list-container .swiper-pagination .swiper-pagination-bullet {
      width: 45px;
      height: 3px;
      border-radius: 20px; }
  .article-list .article-list-carousel .detail .row .left {
    padding-right: 30px; }
    .article-list .article-list-carousel .detail .row .left .img {
      height: 380px; }
  .article-list .article-list-carousel .detail .row .right {
    padding: 30px 40px 30px 25px; }
    .article-list .article-list-carousel .detail .row .right h4 {
      font-size: 25px;
      margin-bottom: 10px;
      color: #32373b;
      line-height: 35px; }
    .article-list .article-list-carousel .detail .row .right h5 {
      color: #32373b;
      font-size: 17px;
      margin-bottom: 10px; }
    .article-list .article-list-carousel .detail .row .right hr {
      width: 50px;
      height: 2px;
      background-color: #32373b;
      margin: 15px 0; }
    .article-list .article-list-carousel .detail .row .right p {
      font-size: 16px;
      line-height: 30px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden; }
  .article-list .article-list-content {
    padding-top: 40px; }
    .article-list .article-list-content > .row .detail {
      margin-bottom: 40px; }
      .article-list .article-list-content > .row .detail .left {
        padding: 25px 0 25px 25px; }
        .article-list .article-list-content > .row .detail .left h4 {
          font-size: 20px;
          color: #32373b;
          margin-bottom: 10px;
          line-height: 28px;
          height: 56px; }
        .article-list .article-list-content > .row .detail .left p {
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 0;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden; }
        .article-list .article-list-content > .row .detail .left hr {
          background-color: #cbb796;
          margin: 10px 0; }
      .article-list .article-list-content > .row .detail .right {
        padding-left: 20px; }
        .article-list .article-list-content > .row .detail .right .img {
          height: 228px;
          overflow: hidden; } }

@media (max-width: 1399px) {
  .article-list .article-list-carousel .article-list-container .swiper-pagination {
    width: 50%;
    left: calc(50% + 20px);
    bottom: 20px;
    display: flex;
    justify-content: start;
    align-items: center; }
    .article-list .article-list-carousel .article-list-container .swiper-pagination .swiper-pagination-bullet {
      width: 45px;
      height: 3px;
      border-radius: 20px; }
  .article-list .article-list-carousel .detail .row .left {
    padding-right: 15px; }
    .article-list .article-list-carousel .detail .row .left .img {
      height: 330px; }
  .article-list .article-list-carousel .detail .row .right {
    padding: 20px 30px 20px 20px; }
    .article-list .article-list-carousel .detail .row .right h4 {
      font-size: 22px;
      margin-bottom: 10px;
      color: #32373b;
      line-height: 35px; }
    .article-list .article-list-carousel .detail .row .right h5 {
      font-size: 15px;
      margin-bottom: 10px; }
    .article-list .article-list-carousel .detail .row .right hr {
      width: 50px;
      height: 2px;
      background-color: #32373b;
      margin: 10px 0; }
    .article-list .article-list-carousel .detail .row .right p {
      font-size: 16px;
      line-height: 30px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden; } }

@media (max-width: 992px) {
  .article-list .article-list-carousel {
    padding: 0; }
    .article-list .article-list-carousel .article-list-container .swiper-pagination {
      width: 100%;
      left: 0;
      bottom: 5vw;
      display: flex;
      justify-content: center;
      align-items: center; }
      .article-list .article-list-carousel .article-list-container .swiper-pagination .swiper-pagination-bullet {
        width: 7vw;
        height: 2px;
        border-radius: 20px; }
    .article-list .article-list-carousel .detail {
      background-color: #f3f4f8; }
      .article-list .article-list-carousel .detail .row .left {
        padding-right: 0;
        display: flex;
        align-items: center;
        height: 100%; }
        .article-list .article-list-carousel .detail .row .left .img {
          height: auto;
          overflow: hidden; }
      .article-list .article-list-carousel .detail .row .right {
        padding: 5vw 3vw 10vw; }
        .article-list .article-list-carousel .detail .row .right h4 {
          font-size: 17px;
          margin-bottom: 3vw;
          color: #32373b;
          line-height: 8vw; }
        .article-list .article-list-carousel .detail .row .right h5 {
          color: #32373b;
          font-size: 14px;
          margin-bottom: 10px; }
        .article-list .article-list-carousel .detail .row .right hr {
          width: 10vw;
          height: 2px;
          background-color: #32373b;
          margin: 3vw 0; }
        .article-list .article-list-carousel .detail .row .right p {
          color: #898989;
          font-size: 13px;
          margin-bottom: 0;
          line-height: 7vw; }
  .article-list .article-list-content {
    padding: 5vw 0 10vw; }
    .article-list .article-list-content > .row .detail {
      margin-bottom: 6vw; }
      .article-list .article-list-content > .row .detail .left {
        padding: 3vw 0 3vw 3vw; }
        .article-list .article-list-content > .row .detail .left h4 {
          font-size: 15px;
          color: #32373b;
          margin-bottom: 1vw;
          line-height: 7vw;
          height: 7vw;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden; }
        .article-list .article-list-content > .row .detail .left p {
          font-size: 13px;
          line-height: 6.5vw;
          margin-bottom: 0;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden; }
        .article-list .article-list-content > .row .detail .left hr {
          background-color: #cbb796;
          margin: 1vw 0; }
        .article-list .article-list-content > .row .detail .left h5 {
          font-size: 10px;
          margin-bottom: 0;
          color: #787878;
          max-width: 100%;
          text-align: left; }
      .article-list .article-list-content > .row .detail .right {
        padding-left: 3vw; }
        .article-list .article-list-content > .row .detail .right .img {
          height: 26vw;
          overflow: hidden; }
          .article-list .article-list-content > .row .detail .right .img img {
            height: auto;
            width: 100%; } }

.page-strategy .strategy-title {
  padding: 1rem 0; }
  .page-strategy .strategy-title .row .text {
    padding-right: 150px; }
    .page-strategy .strategy-title .row .text p {
      font-size: 16px;
      margin-bottom: 0;
      line-height: 40px;
      text-indent: 2rem; }
      .page-strategy .strategy-title .row .text p span {
        font-size: 30px;
        font-weight: bold;
        padding-right: 5px; }

.page-strategy .strategy-content {
  padding: 50px 0; }
  .page-strategy .strategy-content .text {
    padding: 30px 140px;
    background: linear-gradient(0deg, #32373b, #cbb796); }
    .page-strategy .strategy-content .text h5 {
      color: #ffffff;
      font-size: 18px;
      line-height: 40px;
      margin-bottom: 0;
      letter-spacing: 2px; }

@media (max-width: 992px) {
  .page-strategy .strategy-title {
    padding: 0 0; }
    .page-strategy .strategy-title .row .text {
      padding-right: 0;
      padding-bottom: 5vw; }
      .page-strategy .strategy-title .row .text p {
        font-size: 14px;
        margin-bottom: 0;
        line-height: 7.5vw;
        text-indent: 2rem; }
        .page-strategy .strategy-title .row .text p span {
          font-size: 22px;
          font-weight: bold;
          padding-right: 5px; }
    .page-strategy .strategy-title .row img {
      width: 100%; }
  .page-strategy .strategy-content {
    padding: 5vw 0 10vw; }
    .page-strategy .strategy-content .text {
      padding: 5vw 5vw;
      background: linear-gradient(0deg, #32373b, #cbb796); }
      .page-strategy .strategy-content .text h5 {
        color: #ffffff;
        font-size: 13px;
        line-height: 6vw;
        margin-bottom: 0;
        letter-spacing: 1px; } }

.page-social .social-table {
  padding: 1rem 0 8rem; }
  .page-social .social-table .table {
    text-align: center; }
    .page-social .social-table .table .title {
      font-size: 18px; }
      .page-social .social-table .table .title th {
        padding: 1.5rem 0; }
    .page-social .social-table .table .table-content {
      font-size: 16px; }
      .page-social .social-table .table .table-content tr {
        background-color: #ffffff;
        transition: all .36s ease;
        color: #000000;
        position: relative; }
        .page-social .social-table .table .table-content tr:hover {
          background: linear-gradient(0deg, #32373b, #cbb796);
          color: #ffffff; }
      .page-social .social-table .table .table-content td {
        padding: 1.5rem 0; }

@media (max-width: 992px) {
  .page-social .social-table {
    padding: 0 0 10vw; }
    .page-social .social-table .table {
      text-align: center; }
      .page-social .social-table .table .title {
        font-size: 14px; }
        .page-social .social-table .table .title th {
          padding: 5vw 0; }
          .page-social .social-table .table .title th:nth-last-child(1) {
            display: none; }
      .page-social .social-table .table .table-content {
        font-size: 12px; }
        .page-social .social-table .table .table-content tr {
          background-color: #ffffff;
          transition: all .36s ease;
          color: #000000;
          position: relative; }
          .page-social .social-table .table .table-content tr:hover {
            background: linear-gradient(0deg, #32373b, #cbb796);
            color: #ffffff; }
        .page-social .social-table .table .table-content td {
          padding: 1.5rem 0; }
          .page-social .social-table .table .table-content td:nth-last-child(1) {
            display: none; } }

.page-resume .resume-title {
  padding: 3rem 0 20px; }
  .page-resume .resume-title h1 {
    font-size: 25px;
    font-weight: bold;
    color: #cbb796;
    margin-bottom: 30px; }
  .page-resume .resume-title h4 {
    font-size: 16px;
    margin-bottom: 0;
    padding-right: 50px;
    color: #000000;
    padding-bottom: 20px; }
  .page-resume .resume-title hr {
    background-color: #dcdcdc;
    height: 2px; }

.page-resume .resume-description .responsibility {
  padding: 15px 0; }
  .page-resume .resume-description .responsibility img {
    width: 25px;
    padding-top: 0; }
  .page-resume .resume-description .responsibility .text {
    padding-left: 15px; }
    .page-resume .resume-description .responsibility .text h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #000000; }
    .page-resume .resume-description .responsibility .text h5 {
      font-size: 15px;
      margin-bottom: 0;
      line-height: 35px;
      color: #4f4f4f; }

.page-resume .resume-form .title {
  background: linear-gradient(0deg, #32373b, #cbb796);
  padding: 25px 40px;
  margin: 20px 0; }
  .page-resume .resume-form .title h1 {
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
    letter-spacing: 2px; }

.page-resume .resume-form .subtitle h5 {
  color: #000;
  font-size: 18px;
  margin-bottom: 0; }

.page-resume .resume-form .subtitle hr {
  background-color: #a0a0a0;
  margin: 20px auto; }

.page-resume .resume-form .form-group {
  margin-bottom: 30px; }
  .page-resume .resume-form .form-group .form-control {
    font-size: 16px;
    background-color: #eeeeee; }
  .page-resume .resume-form .form-group .form-control1 {
    height: 55px; }
  .page-resume .resume-form .form-group .form-control2 {
    line-height: 30px; }

.page-resume .resume-form .resume-submit {
  padding: 20px 0 100px; }
  .page-resume .resume-form .resume-submit button {
    background: linear-gradient(0deg, #32373b, #cbb796);
    transition: all .36s ease;
    border: none;
    padding: 10px 70px;
    font-size: 17px;
    letter-spacing: 2px;
    border-radius: 0;
    margin: 20px 20px 30px;
    color: #ffffff; }
    .page-resume .resume-form .resume-submit button:hover {
      background: linear-gradient(180deg, #32373b, #cbb796);
      transition: all .36s ease;
      color: #ffffff; }
    .page-resume .resume-form .resume-submit button:active {
      background: linear-gradient(180deg, #32373b, #cbb796);
      transition: all .36s ease;
      color: #ffffff; }
    .page-resume .resume-form .resume-submit button:focus {
      background: linear-gradient(180deg, #32373b, #cbb796);
      transition: all .36s ease;
      color: #ffffff; }

@media (max-width: 992px) {
  .page-resume .resume-title {
    padding: 5vw 0; }
    .page-resume .resume-title h1 {
      font-size: 20px;
      margin-bottom: 5vw; }
    .page-resume .resume-title h4 {
      font-size: 13px;
      margin-bottom: 0;
      padding-right: 2vw;
      padding-bottom: 5vw; }
    .page-resume .resume-title hr {
      background-color: #dcdcdc;
      height: 2px;
      margin: 0 auto; }
  .page-resume .resume-description .responsibility {
    padding: 3vw 0; }
    .page-resume .resume-description .responsibility img {
      width: 20px; }
    .page-resume .resume-description .responsibility .text {
      padding-left: 2vw; }
      .page-resume .resume-description .responsibility .text h3 {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #000000; }
      .page-resume .resume-description .responsibility .text h5 {
        font-size: 13px;
        margin-bottom: 0;
        line-height: 7vw;
        color: #4f4f4f; }
  .page-resume .resume-form .title {
    background: linear-gradient(0deg, #32373b, #cbb796);
    padding: 5vw 3vw;
    margin: 20px 0; }
    .page-resume .resume-form .title h1 {
      color: #ffffff;
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 0;
      letter-spacing: 2px; }
  .page-resume .resume-form .subtitle h5 {
    color: #000;
    font-size: 16px;
    margin-bottom: 0; }
  .page-resume .resume-form .subtitle hr {
    background-color: #a0a0a0;
    margin: 4vw auto; }
  .page-resume .resume-form .form-group {
    margin-bottom: 5vw; }
    .page-resume .resume-form .form-group .form-control {
      font-size: 14px;
      background-color: #eeeeee; }
    .page-resume .resume-form .form-group .form-control1 {
      height: 10vw; }
    .page-resume .resume-form .form-group .form-control2 {
      line-height: 8vw; }
  .page-resume .resume-form .resume-submit {
    padding: 5vw 0 12vw; }
    .page-resume .resume-form .resume-submit button {
      background: linear-gradient(0deg, #32373b, #cbb796);
      transition: all .36s ease;
      border: none;
      padding: 2vw 7vw;
      font-size: 14px;
      letter-spacing: 2px;
      border-radius: 0;
      margin: 5vw 7vw 5vw;
      color: #ffffff; }
      .page-resume .resume-form .resume-submit button:hover {
        background: linear-gradient(180deg, #32373b, #cbb796);
        transition: all .36s ease;
        color: #ffffff; }
      .page-resume .resume-form .resume-submit button:active {
        background: linear-gradient(180deg, #32373b, #cbb796);
        transition: all .36s ease;
        color: #ffffff; }
      .page-resume .resume-form .resume-submit button:focus {
        background: linear-gradient(180deg, #32373b, #cbb796);
        transition: all .36s ease;
        color: #ffffff; } }
