.index {

  .model-more {
    button {
      background: linear-gradient(90deg, rgba(126, 120, 116, 1), rgba(177, 164, 154, 1));
      padding: 1rem 1rem;
      width: 170px;
      border-radius: 0;
      transition: all .36s ease;
      outline: none;

      h5 {
        margin-bottom: 0;
        color: #ffffff;
        font-size: 17px;
        letter-spacing: 3px;
      }

      &:hover {
        background: linear-gradient(90deg, rgb(0, 0, 0), rgb(116, 107, 101));
        transition: all .36s ease;
      }
    }
  }

  // 关于我们
  .about {
    width: 1400px;
    margin: 0 auto;
    background: url(../images/about-back.jpg) no-repeat top center;
    padding: 50px 0;

    .title {
      h1 {
        font-weight: bold;
        font-size: 25px;

        span {
          font-size: 35px;
          // font-family: 宋体;
          font-weight: bold;
          letter-spacing: 5px;
          background: linear-gradient(to right, #000000, #686868);
          -webkit-background-clip: text;
          color: transparent;
        }
      }

      h5 {
        font-size: 18px;
        letter-spacing: 20px;
        margin-top: 15px;
        background: linear-gradient(to right, #595959, #c9c1ba);
        -webkit-background-clip: text;
        color: transparent;
      }
    }

    .content {
      margin-top: 40px;

      p {
        text-indent: 2rem;
        font-size: 17px;
        line-height: 40px;
        margin-bottom: 0;
      }




    }

    .about-right {
      padding-left: 100px;
      position: relative;

      .english-title {
        position: absolute;
        top: -101px;
        z-index: 9;
      }
    }
  }

  // 专业服务
  .professional {
    padding: 3rem 0 6rem;

    .content {
      position: relative;

      .swiper-button-prev {
        outline: none;
        top: 98%;
        background-image: none;
        background-color: #b1a49a;
        color: #ffffff;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        left: 18%;

        &:hover {
          background-color: #373737;
        }

      }

      .swiper-button-next {
        outline: none;
        top: 98%;
        background-image: none;
        background-color: #b1a49a;
        color: #ffffff;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        right: 18%;

        &:hover {
          background-color: #373737;
        }
      }

      .gallery-top {
        .img {
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          height: 608px;

          img {
            height: 100%;
          }
        }
      }

      .professional-thumb {
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        bottom: -50px;
        overflow: hidden;

      }

      .gallery-thumbs {
        width: 50%;
        margin: 0 auto;


        .swiper-slide {
          height: 100%;
          opacity: 0.8;
        }

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .img {
          width: 120px;
          height: 120px;
          overflow: hidden;
          text-align: center;
          border-radius: 100%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          transition: all .36s ease;

          img {
            height: 100%;
            transition: all .36s ease;

          }
        }
      }
    }


  }

  //新闻
  .news {

    /* ********************************* 定宽布局 ********************************* */
    .w {
      width: 100%;
      min-width: 1720px;
      overflow: hidden;
    }

    .sw {
      width: 1720px;
      margin: 0 auto;
    }

    /* ********************************* 通用flex布局 ********************************* */
    /* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素space-between */
    .flex_row_sb {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    /* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素space-around */
    .flex_row_sa {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    /* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚中靠拢 */
    .flex_row_center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚左靠拢 */
    .flex_row_left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    /* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚右靠拢 */
    .flex_row_right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    /* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素space-between */
    .flex_column_sb {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    /* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素space-around */
    .flex_column_sa {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    /* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚中靠拢 */
    .flex_column_center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    /* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚上靠拢 */
    .flex_column_top {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    /* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚下靠拢 */
    .flex_column_bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }



    /* ********************************* 通用行数限制 ********************************* */
    .text_of_1 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .text_of_2 {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .text_of_3 {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .text_of_4 {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .text_of_5 {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    a {
      text-decoration: none !important;
    }

    .pc-news {
      .indexNews {
        min-height: 898px;

        .indexNews_c {
          height: auto;
          align-items: flex-start;

          .news_L {
            height: 810px;
            width: 552px;
            box-sizing: border-box;
            padding-top: 50px;

            .news-title {
              padding-bottom: 70px;

              h1 {
                font-size: 35px;
                font-weight: bold;
                margin-bottom: 10px;
                letter-spacing: 3px;
              }

              h5 {
                color: #717171;
                font-size: 15px;
                text-transform: uppercase;
              }
            }

            .newsL_content {
              width: 100%;
              padding-bottom: 15px;
              transition: all 1s ease;
              bottom: 0px;
              cursor: pointer;
              position: relative;

              &:hover {
                box-shadow: -5px 5px 10px #ccc;
                bottom: 20px;
              }

              .lMask {
                width: 100%;
                height: 342px;
                overflow: hidden;

                >img {
                  height: 100%;
                  width: auto;
                }
              }

              .newslText {
                height: auto;
                width: 100%;

                .news_time {
                  font-size: 16px;
                  color: #000;
                  line-height: 55px;
                  // letter-spacing: 2px;
                }

                .news_title {
                  font-size: 20px;
                  color: #000;
                  font-weight: bold;
                  width: 90%;
                  margin-bottom: 15px;
                  transition: all 0.5s ease;
                }

                .news_content {
                  font-size: 16px;
                  color: #676767;
                  letter-spacing: 2px;
                  width: 98%;
                  line-height: 25px;
                  margin-bottom: 40px;
                }
              }

              .newslText {
                box-sizing: border-box;
                padding: 0 10px;
                position: relative;
              }

              .public_more {
                width: 100%;
                padding-left: 10px;
                box-sizing: border-box;
              }
            }
          }

          .news_R {
            align-items: flex-start;

            .newsBtn {
              height: 750px;
              width: 80px;
              position: relative;
              box-sizing: border-box;
              padding: 50px 0px;

              .Btn_r {
                height: 100%;
                position: absolute;
                border-right: 1px solid #eee;
                top: 0;
                right: 1px;
                z-index: 9;
              }

              .Btn_box {
                height: 120px;
                width: 100%;
                box-sizing: border-box;
                border-right: 3px solid #fff;
                cursor: pointer;
                transition: all 0.5s ease;
                z-index: 8;

                .btnContent {
                  width: 20px;
                  font-size: 18px;
                  line-height: 18px;
                }
              }

              .activated_btn {
                border-color: #3f4f74;
                z-index: 10;

                .btnContent {
                  font-weight: bold;
                }
              }
            }

            .newsList {
              width: 1027px;
              height: 810px;
              position: relative;

              .listCntent {
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 0;
                opacity: 0;
                transition: all 0.5s ease;

                .coansf {
                  width: 100%;
                  height: 750px;
                  box-sizing: border-box;
                  padding: 70px 0px 30px;
                  padding-left: 145px;

                  .newsBox {
                    width: 100%;

                    &:nth-child(2) {
                      margin-left: -180px;
                    }

                    &:hover {
                      .nlseMask {
                        .lsabfs_te {
                          margin-bottom: -10px;
                          margin-right: -25px;
                        }
                      }

                      .newslText {
                        border-color: #caba80;
                        box-shadow: 3px 3px 10px #ccc;

                        .news_title {
                          color: #bdae89;

                        }
                      }
                    }

                    .nlseMask {
                      width: 167px;
                      height: 167px;
                      position: relative;
                      border: 1px dashed rgb(36, 27, 27);
                      border-radius: 100%;

                      .lsabfs_te {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        position: absolute;
                        border-radius: 100%;
                        bottom: 10px;
                        right: 25px;
                        transition: all 0.5s ease;

                        >img {
                          height: 100%;
                          width: auto;
                        }
                      }
                    }

                    .newslText {
                      width: 640px;
                      box-sizing: border-box;
                      padding: 10px;
                      border: 1px solid #fff;
                      transition: all 0.5s ease;

                      .news_time {
                        line-height: 16px;
                        margin-bottom: 10px;
                      }

                      .news_content {
                        margin-bottom: 0px;
                      }
                    }
                  }
                }
              }

              .activateds {
                z-index: 1;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .wap-news {
      display: none;
    }

  }


  //1720以下
  @media (max-width: 1719px) {

    // 专业服务
    .professional {
      .content {
        position: relative;

        .swiper-button-prev {
          top: 100%;
        }

        .swiper-button-next {
          top: 100%;
        }

        .gallery-top {
          .img {
            height: 493px;
          }
        }

        .gallery-thumbs {
          width: 55%;
          margin: 0 auto;

          .img {
            width: 100px;
            height: 100px;
          }
        }
      }


    }

    //新闻
    .news {
      .w {
        width: 100%;
        min-width: 1400px;
        overflow: hidden;
      }

      .sw {
        width: 1400px;
        margin: 0 auto;
      }

      .indexNews {
        min-height: 898px;

        .indexNews_c {
          height: auto;
          align-items: flex-start;

          .news_L {
            height: 810px;
            width: 522px;
            box-sizing: border-box;
            padding-top: 50px;

            .news-title {
              padding-bottom: 70px;

              h1 {
                font-size: 35px;
                font-weight: bold;
                margin-bottom: 10px;
                letter-spacing: 3px;
              }

              h5 {
                color: #717171;
                font-size: 15px;
                text-transform: uppercase;
              }
            }

            .newsL_content {
              width: 100%;
              padding-bottom: 15px;
              transition: all 1s ease;
              bottom: 0px;
              cursor: pointer;
              position: relative;

              &:hover {
                box-shadow: -5px 5px 10px #ccc;
                bottom: 20px;
              }

              .lMask {
                width: 100%;
                height: 342px;
                overflow: hidden;

                >img {
                  height: 100%;
                  width: auto;
                }
              }

              .newslText {
                height: auto;
                width: 100%;

                .news_time {
                  font-size: 16px;
                  color: #000;
                  line-height: 55px;
                  // letter-spacing: 2px;
                }

                .news_title {
                  font-size: 20px;
                  color: #000;
                  font-weight: bold;
                  width: 90%;
                  margin-bottom: 15px;
                  transition: all 0.5s ease;
                }

                .news_content {
                  font-size: 16px;
                  color: #676767;
                  letter-spacing: 2px;
                  width: 98%;
                  line-height: 25px;
                  margin-bottom: 40px;
                }
              }

              .newslText {
                box-sizing: border-box;
                padding: 0 10px;
                position: relative;
              }

              .public_more {
                width: 100%;
                padding-left: 10px;
                box-sizing: border-box;
              }
            }
          }

          .news_R {
            align-items: flex-start;

            .newsBtn {
              height: 705px;
              width: 80px;
              position: relative;
              box-sizing: border-box;
              padding: 50px 0px;

              .Btn_r {
                height: 100%;
                position: absolute;
                border-right: 1px solid #eee;
                top: 0;
                right: 1px;
                z-index: 9;
              }

              .Btn_box {
                height: 120px;
                width: 100%;
                box-sizing: border-box;
                border-right: 3px solid #fff;
                cursor: pointer;
                transition: all 0.5s ease;
                z-index: 8;

                .btnContent {
                  width: 20px;
                  font-size: 18px;
                  line-height: 18px;
                }
              }

              .activated_btn {
                border-color: #3f4f74;
                z-index: 10;

                .btnContent {
                  font-weight: bold;
                }
              }
            }

            .newsList {
              width: 740px;
              height: 810px;
              position: relative;

              .listCntent {
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 0;
                opacity: 0;
                transition: all 0.5s ease;

                .coansf {
                  width: 100%;
                  height: 750px;
                  box-sizing: border-box;
                  padding: 70px 0px 30px;
                  padding-left: 145px;

                  .newsBox {
                    width: 100%;

                    &:nth-child(2) {
                      margin-left: -180px;
                    }

                    &:hover {
                      .nlseMask {
                        .lsabfs_te {
                          margin-bottom: -10px;
                          margin-right: -25px;
                        }
                      }

                      .newslText {
                        border-color: #caba80;
                        box-shadow: 3px 3px 10px #ccc;

                        .news_title {
                          color: #bdae89;

                        }
                      }
                    }

                    .nlseMask {
                      width: 167px;
                      height: 167px;
                      position: relative;
                      border: 1px dashed rgb(36, 27, 27);
                      border-radius: 100%;

                      .lsabfs_te {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        position: absolute;
                        border-radius: 100%;
                        bottom: 10px;
                        right: 25px;
                        transition: all 0.5s ease;

                        >img {
                          height: 100%;
                          width: auto;
                        }
                      }
                    }



                    .newslText {
                      width: 405px;
                      box-sizing: border-box;
                      padding: 10px;
                      border: 1px solid #fff;
                      transition: all 0.5s ease;

                      .news_time {
                        line-height: 16px;
                        margin-bottom: 10px;
                      }

                      .news_content {
                        margin-bottom: 0px;
                      }
                    }
                  }
                }
              }

              .activateds {
                z-index: 1;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  //1400以下
  @media (max-width: 1399px) {

    // 专业服务
    .professional {
      .content {
        position: relative;

        .swiper-button-prev {
          top: 100%;
        }

        .swiper-button-next {
          top: 100%;
        }

        .gallery-top {
          .img {
            height: 421px;
          }
        }

        .gallery-thumbs {
          width: 52%;
          margin: 0 auto;

          .img {
            width: 90px;
            height: 90px;
          }
        }
      }


    }
  }

  //992以下
  @media (max-width: 992px) {
    padding-bottom: 15vw;

    .model-more {
      button {
        background: linear-gradient(90deg, rgba(126, 120, 116, 1), rgba(177, 164, 154, 1));
        padding: 3vw 7vw;
        width: 45vw;

        h5 {
          margin-bottom: 0;
          color: #ffffff;
          font-size: 14px;
          letter-spacing: 1px;
        }

        img {
          width: 5vw;
        }
      }
    }

    // 关于我们
    .about {
      width: 100%;
      margin: 0 auto;
      background: none;
      padding: 5vw 0;

      .title {
        h1 {
          font-weight: bold;
          font-size: 15px;

          span {
            font-size: 18px;
            // font-family: 宋体;
            font-weight: bold;
            letter-spacing: 1px;
            background: linear-gradient(to right, #000000, #686868);
            -webkit-background-clip: text;
            color: transparent;
          }
        }

        h5 {
          font-size: 15px;
          letter-spacing: 6px;
          margin-top: 2vw;
          background: linear-gradient(to right, #595959, #c9c1ba);
          -webkit-background-clip: text;
          color: transparent;
        }
      }

      .content {
        margin-top: 4vw;

        p {
          text-indent: 2rem;
          font-size: 15px;
          line-height: 7vw;
          margin-bottom: 0;
        }

        button {
          background: linear-gradient(90deg, rgba(126, 120, 116, 1), rgba(177, 164, 154, 1));
          padding: .75rem 1rem;
          width: 35vw;
          border-radius: 0;
          transition: all .36s ease;
          outline: none;

          h5 {
            margin-bottom: 0;
            color: #ffffff;
            font-size: 15px;
            letter-spacing: 1px;
          }

          &:hover {
            background: linear-gradient(90deg, rgb(0, 0, 0), rgb(116, 107, 101));
            transition: all .36s ease;
            transform: scale(1.05);
          }
        }
      }

      .about-right {
        padding-left: 100px;
        position: relative;

        .english-title {
          position: absolute;
          top: -101px;
        }
      }
    }

    // 专业服务
    .professional {
      padding: 5vw 0;

      .container {
        padding: 0;
      }

      .content {
        position: relative;

        .swiper-button-prev {
          outline: none;
          top: 60%;
          background-image: none;
          background-color: #b1a49a;
          color: #ffffff;
          width: 7vw;
          height: 7vw;
          border-radius: 100%;
          left: 1vw;

          &:hover {
            background-color: #373737;
          }

        }

        .swiper-button-next {
          outline: none;
          top: 60%;
          background-image: none;
          background-color: #b1a49a;
          color: #ffffff;
          width: 7vw;
          height: 7vw;
          border-radius: 100%;
          right: 1vw;

          &:hover {
            background-color: #373737;
          }
        }

        .gallery-top {
          .img {
            height: 36vw;

            img {
              height: 100%;
            }
          }
        }

        .professional-thumb {
          opacity: 0;
        }
      }
    }

    //新闻
    .news {
      padding: 5vw 0;

      .pc-news {
        display: none;
      }

      .wap-news {
        display: block;

        .news-title {
          padding-bottom: 3vw;

          h1 {
            font-size: 17px;
            font-weight: bold;
          }

          h5 {
            font-size: 11px;
            text-transform: uppercase;
            margin-bottom: 0;
            color: #717171;
          }
        }

        .detail1 {
          margin-bottom: 5vw;

          .img {
            height: 60vw;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .text {
            background-color: #eeeeee;
            padding: 5vw 3vw;

            h5 {
              font-size: 15px;
              color: #000000;
              font-weight: bold;
              width: 65vw;
              margin-bottom: 3vw;
            }

            h4 {
              font-size: 12px;
              color: #000000;
              margin-bottom: 0;
              width: 20vw;
            }

            p {
              font-size: 12px;
              margin-bottom: 0;
              color: #000000;
            }
          }
        }

        .detail2 {
          margin-bottom: 3vw;

          .news-img {
            width: 22vw;
            height: 22vw;
            box-sizing: border-box;
            border: 1px dashed #ccc;
            border-radius: 100%;
            position: relative;

            .img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              overflow: hidden;
              position: absolute;
              bottom: 1.5vw;
              right: 3.2vw;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
            }
          }

          .news-right {

            h5 {
              font-size: 15px;
              color: #000000;
              font-weight: bold;
              margin-bottom: 1vw;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            h4 {
              font-size: 12px;
              color: #000000;
              margin-bottom: 0;
              width: 20vw;
            }

            p {
              font-size: 12px;
              margin-bottom: 0;
              color: #000000;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }

    }
  }


}

//公共样式
.inside-page {
  background: url(../images/page-footer-back.jpg) no-repeat bottom center;

  .list-nav {
    background: url(../images/list-nav-back.png) no-repeat center right;
    // background-size: 60%;
    margin: 30px 0;

    .nav {
      .nav-item {
        background-color: #ffffff;

        &:nth-child(1) {
          .nav-link {
            &::before {
              content: "";
              position: absolute;
              top: 10px;
              left: 0;
              width: 1px;
              height: 35px;
              background: #535353;
            }
          }
        }

        &:nth-last-child(1) {
          padding-right: 50px;
        }

        .nav-link {
          text-align: center;
          position: relative;
          padding: 10px 25px;

          h5 {
            font-size: 20px;
            margin-bottom: 0;
            color: #535353;
          }

          p {
            text-transform: uppercase;
            font-size: 12px;
            color: #535353;
            margin-bottom: 0;
          }

          &::after {
            content: "";
            position: absolute;
            top: 10px;
            right: 0;
            width: 1px;
            height: 35px;
            background: #535353;
          }
        }

        .active {
          h5 {
            color: #da261e;
          }

          p {
            color: #da261e;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    margin-bottom: 3rem;

    .list-nav {
      margin: 3vw 0;
      background: none;

      .nav {
        .nav-item {
          background-color: #ffffff;

          &:nth-child(1) {
            .nav-link {
              &::before {
                content: "";
                position: absolute;
                top: 4vw;
                left: 0;
                width: 1px;
                height: 6vw;
                background: #535353;
              }
            }
          }

          &:nth-last-child(1) {
            padding-right: 0;
          }

          .nav-link {
            text-align: center;
            position: relative;
            padding: 3vw;

            h5 {
              font-size: 14px;
              margin-bottom: 0;
              color: #535353;
            }

            p {
              text-transform: uppercase;
              font-size: 7px;
              color: #535353;
              margin-bottom: 0;
            }

            &::after {
              content: "";
              position: absolute;
              top: 4vw;
              right: 0;
              width: 1px;
              height: 6vw;
              background: #535353;
            }
          }

          .active {
            h5 {
              color: #da261e;
            }

            p {
              color: #da261e;
            }
          }
        }
      }
    }
  }
}

//企业简介
.page-introduction {
  padding-bottom: 235px;

  .model-title {
    h1 {
      font-weight: bold;
      font-size: 27px;
      text-transform: uppercase;
      margin-bottom: 0;
      line-height: 35px;
    }

    h2 {
      font-size: 30px;
      padding: 10px 0 20px;
      margin-bottom: 0;
      letter-spacing: 2px;
    }

    hr {
      background-color: #000000;
      width: 150px;
      height: 1px;
      margin: 0;
    }
  }

  //集团简介
  .company {
    background-color: #f3f3f3;
    padding: 3rem 0;
    position: relative;

    .row {
      .left {
        .content {
          padding-top: 1rem;

          p {
            text-indent: 2rem;
            line-height: 37px;
            font-size: 16px;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 11;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      .right-img {
        position: absolute;
        right: 0;
        top: 3rem;
        width: 850px;

        img {
          width: 100%;
        }
      }
    }

  }

  //办公环境介绍
  .office {
    padding: 8rem 0 3rem;

    .row {
      position: relative;

      .left {
        padding-right: 70px;

        .office-top {
          .img {
            overflow: hidden;
            height: 655px;

            img {
              width: 100%;
            }
          }
        }

      }

      .right {

        .model-title {
          padding-bottom: 1rem;
        }

        p {
          text-indent: 2rem;
          line-height: 37px;
          font-size: 16px;
        }
      }

      .office-thumbs {
        position: absolute;
        right: 0;
        bottom: 30px;
        width: 62%;

        .img {
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }

      .office-thumbs .swiper-slide {
        height: 100%;
        position: relative;

        .hover {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background-color: rgba($color: #000000, $alpha: .5);
        }
      }

      .office-thumbs .swiper-slide-thumb-active {
        .hover {
          background-color: rgba($color: #000000, $alpha: 0);
        }
      }
    }

    .swiper-button-prev {
      outline: none;
      top: 47%;
      background-image: none;
      background-color: #c6bba7;
      color: #000000;
      width: 100px;
      height: 45px;
      border-radius: 0;
      text-align: center;
      left: 89%;
      font-size: 30px;

      &:hover {
        background-color: #32373b;
        color: #ffffff;
      }

    }

    .swiper-button-next {
      outline: none;
      top: 47%;
      color: #000000;
      background-image: none;
      background-color: #c6bba7;
      width: 100px;
      height: 45px;
      border-radius: 0;
      text-align: center;
      right: 0;
      font-size: 30px;

      &:hover {
        background-color: #32373b;
        color: #ffffff;
      }
    }

  }

  //培训系统
  .conference {
    padding: 5rem 0 5rem;

    .row {
      position: relative;

      .left {
        .model-title {
          padding-bottom: 1rem;
        }

        p {
          text-indent: 2rem;
          line-height: 37px;
          font-size: 16px;
        }

      }

      .right {
        padding-left: 70px;

        .office-top {
          .img {
            overflow: hidden;
            height: 655px;

            img {
              width: 100%;
            }
          }
        }


      }

      .conference-thumbs {
        position: absolute;
        left: 0;
        bottom: 30px;
        width: 62%;

        .img {
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }

      .conference-thumbs .swiper-slide {
        height: 100%;
        position: relative;

        .hover {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background-color: rgba($color: #000000, $alpha: .5);
        }
      }

      .conference-thumbs .swiper-slide-thumb-active {
        .hover {
          background-color: rgba($color: #000000, $alpha: 0);
        }
      }
    }

    .swiper-button-prev {
      outline: none;
      top: 47%;
      background-image: none;
      background-color: #c6bba7;
      color: #000000;
      width: 100px;
      height: 45px;
      border-radius: 0;
      text-align: center;
      left: 0;
      font-size: 30px;

      &:hover {
        background-color: #32373b;
        color: #ffffff;
      }

    }

    .swiper-button-next {
      outline: none;
      top: 47%;
      color: #000000;
      background-image: none;
      background-color: #c6bba7;
      width: 100px;
      height: 45px;
      border-radius: 0;
      text-align: center;
      right: 89%;
      font-size: 30px;

      &:hover {
        background-color: #32373b;
        color: #ffffff;
      }
    }

  }

  //集团资质
  .qualification {
    background: url(../images/introduction/qualification-back.jpg) no-repeat center center;
    padding: 3rem 0;

    .title {
      h1 {
        font-weight: bold;
        font-size: 27px;
        text-transform: uppercase;
        margin-bottom: 0;
        line-height: 35px;
      }

      h3 {
        font-size: 25px;
        padding: 10px 0 20px;
        margin-bottom: 0;
        letter-spacing: 2px;
      }

      hr {
        background-color: #000000;
        width: 120px;
        height: 1px;
        margin: 0 auto;
      }
    }

    .pc-content {
      padding-top: 3rem;

      .qualification-pc-container {
        padding: 0 30px;

        .detail {
          display: block;
          text-decoration: none;

          .img {
            width: 325px;
            height: 467px;
            overflow: hidden;
            transition: all .36s ease;
            margin: 0 auto;

            img {
              width: 100%;
            }
          }

          .text {
            h5 {
              margin-bottom: 0;
              padding: 20px 0 0;
              font-size: 17px;
              color: #000000;
            }
          }

          &:hover {
            .img {
              box-shadow: 0px 0px 15px #9e9e9e;
              transition: all .36s ease;
            }
          }
        }

        .swiper-button-prev {
          outline: none;
          text-align: center;
          font-size: 45px;
          top: 45%;
          background-image: none;
          background-color: #bebebe;
          color: #ffffff;
          width: 40px;
          height: 70px;
          border-radius: 10px;
          left: 0px;
        }

        .swiper-button-next {
          outline: none;
          text-align: center;
          font-size: 45px;
          top: 45%;
          background-image: none;
          background-color: #bebebe;
          color: #ffffff;
          width: 40px;
          height: 70px;
          border-radius: 10px;
          right: 0px;
        }
      }


    }

    .wap-content {
      display: none;
    }
  }

  //企业文化
  .culture {
    background: url(../images/introduction/culture-pc-back.jpg) no-repeat center center;
    padding: 3rem 0;

    .left {
      padding-right: 80px;

      .write {
        padding-top: 2rem;

        p {
          text-indent: 2rem;
          line-height: 40px;
          font-size: 16px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 11;
          -webkit-box-orient: vertical;
          overflow: hidden;
          letter-spacing: 2px;
        }
      }
    }

    .right {
      padding-left: 40px;

      .detail {
        margin: 10px 0 20px;
        height: 190px;
        overflow: hidden;

        .one {
          transition: all 1s ease;
          margin: 0;
        }

        .icon {
          border: 2px solid #ffffff;
          border-radius: 100%;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
          width: 105px;
          height: 105px;
          margin: 0 auto 15px;
        }

        h4 {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 0;
          line-height: 35px;
          letter-spacing: 3px;
        }

        h5 {
          font-size: 14px;
          text-transform: uppercase;
          margin-bottom: 0;
          color: #ffffff;
          line-height: 35px;
          letter-spacing: 2px;
        }

        .hover {
          .icon {
            border-color: #cbb796;
          }
        }

        h3 {
          font-size: 20px;
          font-weight: bold;
          color: #cbb796;
          margin-bottom: 0;
          line-height: 35px;
          letter-spacing: 0;
        }

        p {
          font-size: 16px;
          color: #cbb796;
          margin-bottom: 0;
          line-height: 35px;
          padding: 0 30px;
          height: 70px;
          overflow: hidden;
        }

        &:hover {
          .one {
            margin-top: -190px;
            transition: all 1s ease;
          }
        }
      }
    }
  }

  //1720以下
  @media (max-width: 1719px) {
    padding-bottom: 210px;


    //集团简介
    .company {
      .row {
        .right-img {
          width: 700px;

          img {
            width: 100%;
          }
        }
      }

    }

    //办公环境介绍
    .office {
      padding: 5rem 0 3rem;

      .row {
        position: relative;

        .left {
          padding-right: 40px;

          .office-top {
            .img {
              overflow: hidden;
              height: 540px;

              img {
                width: 100%;
              }
            }
          }

        }

        .right {

          .model-title {
            padding-bottom: 1rem;
          }

          p {
            text-indent: 2rem;
            line-height: 37px;
            font-size: 16px;
          }
        }
      }

      .swiper-button-prev {
        outline: none;
        top: 57%;
        background-image: none;
        background-color: #c6bba7;
        color: #000000;
        width: 100px;
        height: 45px;
        border-radius: 0;
        text-align: center;
        left: 86%;
        font-size: 30px;

        &:hover {
          background-color: #32373b;
          color: #ffffff;
        }

      }

      .swiper-button-next {
        outline: none;
        top: 57%;
        color: #000000;
        background-image: none;
        background-color: #c6bba7;
        width: 100px;
        height: 45px;
        border-radius: 0;
        text-align: center;
        right: 0;
        font-size: 30px;

        &:hover {
          background-color: #32373b;
          color: #ffffff;
        }
      }

    }

    //培训系统
    .conference {
      padding: 5rem 0 5rem;

      .row {

        .right {
          padding-left: 40px;

          .conference-top {
            .img {
              overflow: hidden;
              height: 540px;

              img {
                width: 100%;
              }
            }
          }

        }

        .left {

          .model-title {
            padding-bottom: 1rem;
          }

          p {
            text-indent: 2rem;
            line-height: 37px;
            font-size: 16px;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      .swiper-button-prev {
        top: 57%;
        width: 100px;
        height: 45px;
        border-radius: 0;
        left: 0;
        font-size: 30px;
      }

      .swiper-button-next {
        top: 57%;
        width: 100px;
        height: 45px;
        border-radius: 0;
        right: 86%;
      }
    }
  }

  //1400以下
  @media (max-width: 1399px) {

    //集团简介
    .company {
      .row {
        .right-img {
          width: 600px;
        }
      }
    }

  }

  //992以下
  @media (max-width: 992px) {
    padding-bottom: 0;

    .model-title {
      h1 {
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 0;
        line-height: 6.5vw;
      }

      h2 {
        font-size: 19px;
        padding: 2vw 0 3vw;
        margin-bottom: 0;
        letter-spacing: 2px;
      }

      hr {
        background-color: #000000;
        width: 25vw;
        height: 1px;
        margin: 0;
      }
    }

    //集团简介
    .company {
      background-color: #f3f3f3;
      padding: 5vw 0;
      position: relative;

      .row {
        .left {
          .content {
            padding-top: 1rem;

            p {
              text-indent: 2rem;
              line-height: 7vw;
              font-size: 14px;
            }
          }
        }
      }
    }

    //办公环境介绍
    .office {
      padding: 7vw 0 5vw;

      .container {
        overflow: hidden;
      }

      .row {
        position: relative;
        overflow: hidden;

        .left {
          padding-right: 0;

          .office-top {
            .img {
              overflow: hidden;
              height: 66vw;

              img {
                width: 100%;
              }
            }
          }

        }

        .right {
          .model-title {
            padding-bottom: 5vw;
          }

          p {
            text-indent: 2rem;
            line-height: 7vw;
            font-size: 14px;
          }

        }

        .office-thumbs {
          opacity: 0;
        }
      }

      .swiper-button-prev {
        top: 75%;
        width: 15vw;
        height: 7vw;
        left: 15px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

      }

      .swiper-button-next {
        top: 75%;
        width: 15vw;
        height: 7vw;
        right: 15px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }

    }

    //培训系统
    .conference {
      padding: 7vw 0 5vw;

      .container {
        overflow: hidden;
      }

      .row {
        position: relative;
        overflow: hidden;

        .right {
          padding-left: 0;

          .conference-top {
            .img {
              overflow: hidden;
              height: 66vw;

              img {
                width: 100%;
              }
            }
          }

        }

        .left {
          .model-title {
            padding-bottom: 5vw;
          }

          p {
            text-indent: 2rem;
            line-height: 7vw;
            font-size: 14px;
          }

        }

        .conference-thumbs {
          opacity: 0;
        }
      }

      .swiper-button-prev {
        top: 75%;
        width: 15vw;
        height: 7vw;
        left: 15px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

      }

      .swiper-button-next {
        top: 75%;
        width: 15vw;
        height: 7vw;
        right: 15px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }

    }

    //集团资质
    .qualification {
      background: url(../images/introduction/qualification-back.jpg) no-repeat center center;
      padding: 7vw 0;

      .title {
        h1 {
          font-weight: bold;
          font-size: 18px;
          text-transform: uppercase;
          margin-bottom: 0;
          line-height: 6.5vw;
        }

        h3 {
          font-size: 20px;
          padding: 2vw 0 3vw;
          margin-bottom: 0;
          letter-spacing: 2px;
        }

        hr {
          background-color: #000000;
          width: 25vw;
          height: 1px;
          margin: 0 auto;
        }
      }

      .pc-content {
        display: none;
      }

      .wap-content {
        display: block;
        padding: 5vw 15vw 0;
        position: relative;

        .qualification-wap-container {
          padding: 0;
          overflow: hidden;

          .detail {
            display: block;
            text-decoration: none;

            .img {
              width: auto;
              height: 89vw;
              overflow: hidden;
              margin: 0 auto;

              img {
                width: 100%;
              }
            }

            .text {
              h5 {
                margin-bottom: 0;
                padding: 3vw 0 0;
                font-size: 14px;
                color: #000000;
              }
            }
          }
        }

        .swiper-button-prev {
          outline: none;
          text-align: center;
          font-size: 25px;
          top: 45%;
          background-image: none;
          background-color: #bebebe;
          color: #ffffff;
          width: 7vw;
          height: 15vw;
          border-radius: 10px;
          left: 0px;
        }

        .swiper-button-next {
          outline: none;
          text-align: center;
          font-size: 25px;
          top: 45%;
          background-image: none;
          background-color: #bebebe;
          color: #ffffff;
          width: 7vw;
          height: 15vw;
          border-radius: 10px;
          right: 0px;
        }
      }
    }

    //企业文化
    .culture {
      background: none;
      padding: 0;

      .container {
        padding: 0;
      }

      .left {
        padding: 5vw 5vw;
        background: #c6bba7;

        .write {
          padding-top: 5vw;

          p {
            text-indent: 2rem;
            line-height: 7vw;
            font-size: 14px;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 11;
            -webkit-box-orient: vertical;
            overflow: hidden;
            letter-spacing: 0px;
          }
        }
      }

      .right {
        background: url(../images/introduction/culture-wap-back.jpg) no-repeat center center;
        padding: 5vw 5vw;

        .detail {
          margin: 3vw 0 5vw;
          height: 36vw;
          overflow: hidden;

          .one {
            transition: all 1s ease;
            margin: 0;
          }

          .icon {
            border: 2px solid #ffffff;
            border-radius: 100%;
            width: 20vw;
            height: 20vw;
            margin: 0 auto 3vw;

            img {
              width: 7vw;
            }
          }

          h4 {
            font-size: 17px;
            font-weight: bold;
            color: #ffffff;
            margin-bottom: 0;
            line-height: 7vw;
            letter-spacing: 1px;
          }

          h5 {
            font-size: 10px;
            text-transform: uppercase;
            margin-bottom: 0;
            color: #ffffff;
            line-height: 7vw;
            letter-spacing: 0px;
          }

          .hover {
            .icon {
              border-color: #cbb796;
            }
          }

          h3 {
            font-size: 17px;
            font-weight: bold;
            color: #cbb796;
            margin-bottom: 0;
            line-height: 7vw;
            letter-spacing: 0;
          }

          p {
            font-size: 12px;
            color: #cbb796;
            margin-bottom: 0;
            line-height: 7vw;
            padding: 0 2vw;
            height: 14vw;
            overflow: hidden;
          }

          &:hover {
            .one {
              margin-top: -36vw;
              transition: all 1s ease;
            }
          }
        }
      }
    }
  }
}

//案例列表
.case-list {
  .case-list-carousel {
    margin-bottom: 50px;

    .case-list-pc-container {
      .detail {
        position: relative;

        .img {
          height: 440px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;

          img {
            height: 100%;
          }
        }

        .hover {
          position: absolute;
          background-color: rgba($color: #000000, $alpha: .2);
          transition: all .36s ease;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: #ffffff;
          padding: 330px 0 0 50px;

          h3 {
            font-size: 24px;
            letter-spacing: 2px;
            font-weight: bold;
            margin-bottom: 0;
          }

          h4 {
            font-size: 16px;
            padding: 10px 0;
            margin-bottom: 0;
          }

          h5 {
            font-size: 16px;
            margin-bottom: 0;
            text-align: right;
            max-width: 85px;
            transition: all 1s ease;
          }
        }

        &:hover {
          .hover {
            background-color: rgba($color: #000000, $alpha: .5);
            transition: all .36s ease;

            h5 {
              max-width: 700px;
              transition: all 1s ease;
            }
          }
        }
      }
    }

    .case-list-wap-container {
      display: none;
    }
  }

  .case-list-content {
    .row {
      margin-right: -20px;
      margin-left: -20px;

      [class^="col-"] {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;
      }
    }

    .detail {
      position: relative;

      .img {
        height: 440px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        img {
          height: 100%;
        }
      }

      .hover {
        position: absolute;
        background-color: rgba($color: #000000, $alpha: .5);
        opacity: 0;
        transition: all .36s ease;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: #ffffff;
        padding: 330px 0 0 30px;

        h3 {
          font-size: 24px;
          letter-spacing: 2px;
          font-weight: bold;
          margin-bottom: 0;
        }

        h4 {
          font-size: 16px;
          padding: 10px 0;
          margin-bottom: 0;
        }

        h5 {
          font-size: 16px;
          margin-bottom: 0;
          text-align: right;
          max-width: 85px;
          transition: all 1s ease;
        }
      }

      &:hover {
        .hover {
          opacity: 1;
          transition: all .36s ease;

          h5 {
            max-width: 470px;
            transition: all 1s ease;
          }
        }
      }
    }
  }

  //1720以下
  @media (max-width: 1719px) {
    .case-list-carousel {
      .case-list-pc-container {
        .detail {
          &:hover {
            .hover {

              h5 {
                max-width: 550px;
                transition: all 1s ease;
              }
            }
          }
        }
      }

      .case-list-wap-container {
        display: none;
      }
    }

    .case-list-content {
      .detail {
        &:hover {
          .hover {
            h5 {
              max-width: 370px;
              transition: all 1s ease;
            }
          }
        }
      }
    }
  }

  //1400以下
  @media (max-width: 1399px) {
    .case-list-carousel {
      .case-list-pc-container {
        .detail {
          &:hover {
            .hover {
              h5 {
                max-width: 450px;
                transition: all 1s ease;
              }
            }
          }
        }
      }
    }

    .case-list-content {
      .detail {
        &:hover {
          .hover {
            h5 {
              max-width: 300px;
              transition: all 1s ease;
            }
          }
        }
      }
    }
  }

  //992以下
  @media (max-width: 992px) {
    .case-list-carousel {
      margin-bottom: 5vw;

      .case-list-pc-container {
        display: none;
      }

      .case-list-wap-container {
        display: block;

        .detail {
          position: relative;

          .img {
            height: 49vw;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .hover {
            position: absolute;
            background-color: rgba($color: #000000, $alpha: .2);
            transition: all .36s ease;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            color: #ffffff;
            padding: 29vw 0 0 5vw;

            h3 {
              font-size: 16px;
              letter-spacing: 2px;
              font-weight: bold;
              margin-bottom: 0;
            }

            h4 {
              font-size: 12px;
              padding: 1.5vw 0;
              margin-bottom: 0;
            }

            h5 {
              font-size: 12px;
              margin-bottom: 0;
              text-align: left;
            }
          }
        }
      }
    }

    .case-list-content {
      .row {
        margin-right: -2vw;
        margin-left: -2vw;

        [class^="col-"] {
          padding-left: 2vw;
          padding-right: 2vw;
          margin-bottom: 4vw;
        }
      }

      .detail {
        position: relative;

        .img {
          height: 40vw;
        }

        .hover {
          position: absolute;
          background-color: rgba($color: #000000, $alpha: .2);
          opacity: 1;
          padding: 27vw 0 0 3vw;

          h3 {
            font-size: 15px;
            letter-spacing: 2px;
            font-weight: bold;
            margin-bottom: 0;
          }

          h4 {
            font-size: 12px;
            padding: 1.5vw 0;
            margin-bottom: 0;
            display: none;
          }

          h5 {
            font-size: 12px;
            margin-bottom: 0;
            text-align: left;
            padding-top: 2vw;
          }
        }
      }
    }
  }
}

//联系我们
.page-contact {
  .contact-container {
    background-color: #f6f6f6;
    padding: 30px 80px 50px;
    margin-top: 70px;

    .title {
      h1 {
        font-size: 30px;
        color: #000;
        font-weight: bold;

        span {
          padding-right: 10px;
        }
      }

      hr {
        margin: 20px auto;
      }
    }

    .row {
      margin-right: -70px;
      margin-left: -70px;

      [class^="col-"] {
        padding-left: 70px;
        padding-right: 70px;
      }

      .text {
        padding: 10px 0;

        h5 {
          font-size: 17px;
          color: #757575;
          margin-bottom: 10px;
        }

        h1 {
          font-size: 33px;
          font-weight: bold;
          font-family: fantasy;
        }
      }

      .erweima {
        text-align: center;

        .img {
          width: 120px;
          height: 120px;
          margin: 0 auto;

          img {
            width: 100%;
          }
        }

        h5 {
          font-size: 17px;
          color: #757575;
          margin-top: 10px;
        }
      }
    }
  }

  .contact-message {
    padding: 3rem 0;

    .title {
      h1 {
        font-size: 60px;
        font-family: 'Times New Roman', Times, serif;
        margin-bottom: 10px;
        color: #000;
        font-style: italic;
      }

      h5 {
        font-size: 15px;
        color: #000;
      }
    }

    form {
      padding-top: 40px;

      .form-group {
        margin-bottom: 30px;

        .form-control1 {
          height: 50px;
        }

        .form-control2 {
          line-height: 30px;
        }
      }

      button {
        background: linear-gradient(180deg, rgba(182, 166, 139, 1), rgba(58, 67, 78, 1));
        border: none;
        padding: 10px 50px;
        font-size: 17px;
        letter-spacing: 2px;
        border-radius: 0;
        margin: 20px 0 30px;
      }
    }
  }

  //1720以下
  @media (max-width: 1719px) {
    .contact-container {
      .row {
        margin-right: -20px;
        margin-left: -20px;

        [class^="col-"] {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }

  //1400以下
  @media (max-width: 1399px) {
    .contact-container {
      .row {
        margin-right: -20px;
        margin-left: -20px;

        [class^="col-"] {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }

  //992以下
  @media (max-width: 992px) {
    .contact-container {
      background-color: #f6f6f6;
      padding: 5vw 5vw;
      margin-top: 5vw;

      .title {
        h1 {
          font-size: 21px;
          color: #000;
          font-weight: bold;

          span {
            padding-right: 2vw;
          }
        }

        hr {
          margin: 5vw auto;
        }
      }

      .row {
        margin-right: -3vw;
        margin-left: -3vw;

        [class^="col-"] {
          padding-left: 3vw;
          padding-right: 3vw;
        }

        .text {
          padding: 3vw 0;

          h5 {
            font-size: 14px;
            color: #757575;
            margin-bottom: 10px;
          }

          h1 {
            font-size: 17px;
            font-weight: bold;
            font-family: fantasy;
          }
        }

        .erweima {
          text-align: center;

          .img {
            width: 30vw;
            height: 30vw;
            margin: 0 auto;

            img {
              width: 100%;
            }
          }

          h5 {
            font-size: 13px;
            color: #757575;
            margin-top: 3vw;
          }
        }
      }
    }

    .contact-message {
      padding: 5vw 0;

      .title {
        h1 {
          font-size: 35px;
          font-family: 'Times New Roman', Times, serif;
          margin-bottom: 10px;
          color: #000;
          font-style: italic;
        }

        h5 {
          font-size: 12px;
          color: #000;
        }
      }

      form {
        padding-top: 3vw;

        .form-group {
          margin-bottom: 3vw;

          .form-control1 {
            height: 10vw;
          }

          .form-control2 {
            line-height: 30px;
          }
        }

        button {
          background: linear-gradient(180deg, rgba(182, 166, 139, 1), rgba(58, 67, 78, 1));
          border: none;
          padding: 2vw 5vw;
          font-size: 14px;
          letter-spacing: 2px;
          border-radius: 0;
          margin: 3vw 0 5vw;
        }
      }
    }
  }
}

//案例详情
.case-view {
  .view-content {
    box-shadow: 0px 2px 15px #dadada;
    padding: 30px;

    .left {
      position: relative;
    }

    .case-view-gallery-top {
      .swiper-slide {
        .img {
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }
    }

    .case-swiper-thumb {
      padding: 20px 75px 0;

      .case-view-gallery-thumbs {

        .swiper-slide {
          height: 100%;
          position: relative;

          .img {
            height: 165px;
            overflow: hidden;
            border-radius: 20px;

            img {
              width: 100%;
            }
          }

          .hover {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            border-radius: 20px;
            background-color: rgba($color: #000000, $alpha: .5);
          }
        }

        .swiper-slide-thumb-active {
          .hover {
            background-color: rgba($color: #000000, $alpha: 0);
          }
        }
      }
    }

    .swiper-button-prev {
      outline: none;
      top: auto;
      bottom: 0;
      background-image: none;
      background-color: #c6bba7;
      color: #32373b;
      width: 60px;
      height: 165px;
      border-radius: 20px;
      text-align: center;
      left: 0;
      font-size: 50px;

      &:hover {
        background-color: #32373b;
        color: #ffffff;
      }

    }

    .swiper-button-next {
      outline: none;
      top: auto;
      bottom: 0;
      color: #32373b;
      background-image: none;
      background-color: #c6bba7;
      width: 60px;
      height: 165px;
      border-radius: 20px;
      text-align: center;
      right: 0;
      font-size: 50px;

      &:hover {
        background-color: #32373b;
        color: #ffffff;
      }
    }

    .right {
      padding: 60px 0 0 40px;

      h2 {
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      hr {
        background-color: $primary;
        height: 1px;
      }

      h5 {
        font-size: 17px;
        line-height: 50px;
        margin-bottom: 0;
      }

      p {
        font-size: 16px;
        line-height: 40px;
        margin-bottom: 0;
        text-indent: 2rem;
      }

      button {
        color: $secondary;
        padding: 10px 40px;
        font-size: 16px;
        letter-spacing: 1px;
        border-radius: 0;
        margin: 30px 0;
      }

      .arrows {
        display: block;
        position: absolute;
        bottom: 0;
        width: calc(100% - 30px);

        .page-arrows {
          border: 1px solid $secondary;

          .page-arrow {
            height: 50px;
          }
        }
      }

    }
  }

  //相关推荐
  .relevant {
    padding: 5rem 0 8rem;

    .title {
      padding-bottom: 30px;

      h2 {
        font-size: 30px;
        margin-bottom: 0;
        color: #000000;
        font-weight: bold;
        letter-spacing: 3px;

        span {
          font-size: 20px;
          font-weight: normal;
          color: #000;
          text-transform: uppercase;
        }
      }
    }

    .row {
      margin-right: -20px;
      margin-left: -20px;

      [class^="col-"] {
        padding-left: 20px;
        padding-right: 20px;
      }

      .detail {
        text-decoration: none;
        transition: all .36s ease;

        .img {
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .text {
          padding: 20px 0;

          h5 {
            color: $secondary;
            font-size: 17px;
            margin-bottom: 0;
          }
        }

        &:hover {
          background-color: #ffffff;
          box-shadow: 0px 2px 15px #dadada;
          transition: all .36s ease;
        }
      }
    }
  }

  //1720以下
  @media (max-width: 1719px) {
    .view-content {
      .case-swiper-thumb {
        padding: 20px 75px 0;

        .case-view-gallery-thumbs {

          .swiper-slide {
            .img {
              height: 125px;
            }
          }
        }
      }

      .swiper-button-prev {
        width: 55px;
        height: 125px;
        font-size: 50px;
      }

      .swiper-button-next {
        width: 55px;
        height: 125px;
        font-size: 50px;
      }

      .right {
        padding: 30px 0 0 40px;

        h2 {
          font-size: 35px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        hr {
          background-color: $primary;
          height: 1px;
        }

        h5 {
          font-size: 17px;
          line-height: 40px;
          margin-bottom: 0;
        }

        p {
          font-size: 16px;
          line-height: 35px;
          margin-bottom: 0;
          text-indent: 2rem;
        }

        button {
          color: $secondary;
          padding: 10px 40px;
          font-size: 16px;
          letter-spacing: 1px;
          border-radius: 0;
          margin: 20px 0;
        }

      }
    }
  }

  //1400以下
  @media (max-width: 1399px) {
    .view-content {
      .case-swiper-thumb {
        padding: 20px 60px 0;

        .case-view-gallery-thumbs {

          .swiper-slide {
            .img {
              height: 105px;
            }
          }
        }
      }

      .swiper-button-prev {
        width: 40px;
        height: 105px;
        font-size: 40px;
      }

      .swiper-button-next {
        width: 40px;
        height: 105px;
        font-size: 40px;
      }

      .right {
        padding: 0px 0 0 30px;

        h2 {
          font-size: 30px;
          font-weight: bold;
          margin-bottom: 0;
        }

        h5 {
          font-size: 17px;
          line-height: 35px;
          margin-bottom: 0;
        }

        p {
          font-size: 16px;
          line-height: 30px;
          margin-bottom: 0;
          text-indent: 2rem;
          height: 270px;
          overflow: hidden;
        }

        button {
          color: $secondary;
          padding: 10px 40px;
          font-size: 16px;
          letter-spacing: 1px;
          border-radius: 0;
          margin: 15px 0;
        }

      }
    }
  }

  //992以下
  @media (max-width: 992px) {
    .view-content {
      box-shadow: 0px 2px 15px #dadada;
      padding: 5vw;

      .left {
        position: relative;
      }

      .case-swiper-thumb {
        padding: 3vw 7vw 0;

        .case-view-gallery-thumbs {

          .swiper-slide {
            height: 100%;
            position: relative;

            .img {
              height: 13vw;
              overflow: hidden;
              border-radius: 1vw;

              img {
                width: 100%;
              }
            }

            .hover {
              border-radius: 1vw;
            }
          }
        }
      }

      .swiper-button-prev {
        width: 5vw;
        height: 13vw;
        border-radius: 1vw;
        font-size: 20px;
      }

      .swiper-button-next {
        width: 5vw;
        height: 13vw;
        border-radius: 1vw;
        font-size: 20px;
      }

      .right {
        padding: 8vw 0 15vw;

        h2 {
          font-size: 21px;
          font-weight: bold;
          margin-bottom: 4vw;
        }

        hr {
          background-color: $primary;
          height: 1px;
          margin: 2vw 0;
        }

        h5 {
          font-size: 15px;
          line-height: 8vw;
          margin-bottom: 0;
        }

        p {
          font-size: 13px;
          line-height: 7vw;
          margin-bottom: 0;
          text-indent: 2rem;
          height: 100%;
        }

        button {
          color: $secondary;
          padding: 2vw 5vw;
          font-size: 13px;
          letter-spacing: 1px;
          border-radius: 0;
          margin: 3vw 0 0;
        }

        .arrows {
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;

          .page-arrows {
            border: 1px solid $secondary;

            .page-arrow {
              height: 10vw;
              font-size: 14px;
            }
          }
        }

      }
    }

    //相关推荐
    .relevant {
      padding: 7vw 0;

      .title {
        padding-bottom: 7vw;

        h2 {
          font-size: 22px;
          margin-bottom: 0;
          color: #000000;
          font-weight: bold;
          letter-spacing: 1px;

          span {
            font-size: 15px;
            font-weight: normal;
            color: #000;
            text-transform: uppercase;
          }
        }
      }

      .row {
        margin-right: -2vw;
        margin-left: -2vw;

        [class^="col-"] {
          padding-left: 2vw;
          padding-right: 2vw;
        }

        .detail {
          text-decoration: none;
          transition: all .36s ease;

          .img {
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .text {
            padding: 3vw 0;

            h5 {
              color: $secondary;
              font-size: 14px;
              margin-bottom: 0;
            }
          }

          &:hover {
            background-color: #ffffff;
            box-shadow: 0px 2px 15px #dadada;
            transition: all .36s ease;
          }
        }
      }
    }

  }
}

//新闻列表
.article-list {
  a {
    text-decoration: none !important;
  }

  //新闻轮播
  .article-list-carousel {
    padding: 1rem 0 30px;

    .article-list-container {
      .swiper-pagination {
        width: 50%;
        left: calc(50% + 35px);
        bottom: 50px;
        display: flex;
        justify-content: start;
        align-items: center;

        .swiper-pagination-bullet {
          width: 45px;
          height: 3px;
          border-radius: 20px;
        }
      }
    }

    .detail {
      background-color: #f3f4f8;
      transition: all .36s ease;

      .row {
        .left {
          padding-right: 30px;
          display: flex;
          align-items: center;
          height: 100%;

          .img {
            height: 475px;
            overflow: hidden;

            img {
              transition: all .36s ease;
              width: 100%;
            }
          }
        }

        .right {
          padding: 50px 50px 50px 35px;

          h4 {
            font-size: 25px;
            margin-bottom: 15px;
            color: $secondary;
            line-height: 35px;
          }

          h5 {
            color: $secondary;
            font-size: 18px;
            margin-bottom: 10px;
          }

          hr {
            width: 50px;
            height: 2px;
            background-color: $secondary;
            margin: 15px 0 50px;
          }

          p {
            color: #898989;
            font-size: 17px;
            margin-bottom: 0;
            line-height: 35px;
            transition: all .36s ease;
          }
        }
      }

      &:hover {
        transition: all .36s ease;

        .img {
          img {
            transform: scale(1.08);
            transition: all .36s ease;
          }
        }

        .right {
          p {
            color: $secondary;
            transition: all .36s ease;
          }
        }
      }
    }
  }

  //列表内容
  .article-list-content {
    padding-top: 50px;
    padding-bottom: 100px;

    &>.row {
      margin-right: -15px;
      margin-left: -15px;

      [class^="col-"] {
        padding-left: 15px;
        padding-right: 15px;
      }

      .detail {
        border: 1px solid $primary;
        transition: all .36s ease;
        margin-bottom: 50px;

        .row {
          margin: auto;

          [class^="col-"] {
            padding: 0;
          }
        }

        .left {
          padding: 30px 0 30px 30px;

          h4 {
            font-size: 22px;
            color: $secondary;
            margin-bottom: 10px;
            line-height: 30px;
            height: 60px;
            overflow: hidden;
            transition: all .36s ease;

          }

          p {
            color: #787878;
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 0;
          }

          hr {
            background-color: $primary;
            margin: 20px 0;
          }

          h5 {
            font-size: 14px;
            margin-bottom: 0;
            color: #787878;
            max-width: 80px;
            text-align: right;
            transition: all 1s ease;
          }
        }

        .right {
          padding-left: 25px;

          .img {
            height: 265px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            img {
              height: 100%;
            }
          }
        }

        &:hover {
          box-shadow: 0px 2px 15px $secondary;
          transition: all .36s ease;

          .left {

            h4 {
              color: #da271e;
              transition: all .36s ease;

            }

            hr {
              background-color: $primary;
            }

            h5 {
              max-width: 100%;
              transition: all 1s ease;
            }
          }
        }
      }
    }
  }

  //1720以下
  @media (max-width: 1719px) {

    //新闻轮播
    .article-list-carousel {
      .article-list-container {
        .swiper-pagination {
          width: 50%;
          left: calc(50% + 25px);
          bottom: 30px;
          display: flex;
          justify-content: start;
          align-items: center;

          .swiper-pagination-bullet {
            width: 45px;
            height: 3px;
            border-radius: 20px;
          }
        }
      }

      .detail {
        .row {
          .left {
            padding-right: 30px;

            .img {
              height: 380px;
            }
          }

          .right {
            padding: 30px 40px 30px 25px;

            h4 {
              font-size: 25px;
              margin-bottom: 10px;
              color: $secondary;
              line-height: 35px;
            }

            h5 {
              color: $secondary;
              font-size: 17px;
              margin-bottom: 10px;
            }

            hr {
              width: 50px;
              height: 2px;
              background-color: $secondary;
              margin: 15px 0;
            }

            p {
              font-size: 16px;
              line-height: 30px;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }
    }

    //列表内容
    .article-list-content {
      padding-top: 40px;

      &>.row {
        .detail {
          margin-bottom: 40px;

          .left {
            padding: 25px 0 25px 25px;

            h4 {
              font-size: 20px;
              color: $secondary;
              margin-bottom: 10px;
              line-height: 28px;
              height: 56px;
            }

            p {
              font-size: 16px;
              line-height: 25px;
              margin-bottom: 0;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            hr {
              background-color: $primary;
              margin: 10px 0;
            }
          }

          .right {
            padding-left: 20px;

            .img {
              height: 228px;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  //1400以下
  @media (max-width: 1399px) {

    //新闻轮播
    .article-list-carousel {
      .article-list-container {
        .swiper-pagination {
          width: 50%;
          left: calc(50% + 20px);
          bottom: 20px;
          display: flex;
          justify-content: start;
          align-items: center;

          .swiper-pagination-bullet {
            width: 45px;
            height: 3px;
            border-radius: 20px;
          }
        }
      }

      .detail {
        .row {
          .left {
            padding-right: 15px;

            .img {
              height: 330px;
            }
          }

          .right {
            padding: 20px 30px 20px 20px;

            h4 {
              font-size: 22px;
              margin-bottom: 10px;
              color: $secondary;
              line-height: 35px;
            }

            h5 {
              font-size: 15px;
              margin-bottom: 10px;
            }

            hr {
              width: 50px;
              height: 2px;
              background-color: $secondary;
              margin: 10px 0;
            }

            p {
              font-size: 16px;
              line-height: 30px;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }
    }

  }

  //992以下
  @media (max-width: 992px) {

    //新闻轮播
    .article-list-carousel {
      padding: 0;

      .article-list-container {
        .swiper-pagination {
          width: 100%;
          left: 0;
          bottom: 5vw;
          display: flex;
          justify-content: center;
          align-items: center;

          .swiper-pagination-bullet {
            width: 7vw;
            height: 2px;
            border-radius: 20px;
          }
        }
      }

      .detail {
        background-color: #f3f4f8;

        .row {
          .left {
            padding-right: 0;
            display: flex;
            align-items: center;
            height: 100%;

            .img {
              height: auto;
              overflow: hidden;
            }
          }

          .right {
            padding: 5vw 3vw 10vw;

            h4 {
              font-size: 17px;
              margin-bottom: 3vw;
              color: $secondary;
              line-height: 8vw;
            }

            h5 {
              color: $secondary;
              font-size: 14px;
              margin-bottom: 10px;
            }

            hr {
              width: 10vw;
              height: 2px;
              background-color: $secondary;
              margin: 3vw 0;
            }

            p {
              color: #898989;
              font-size: 13px;
              margin-bottom: 0;
              line-height: 7vw;
            }
          }
        }
      }
    }

    //列表内容
    .article-list-content {
      padding: 5vw 0 10vw;

      &>.row {

        .detail {
          margin-bottom: 6vw;

          .left {
            padding: 3vw 0 3vw 3vw;


            h4 {
              font-size: 15px;
              color: $secondary;
              margin-bottom: 1vw;
              line-height: 7vw;
              height: 7vw;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            p {
              font-size: 13px;
              line-height: 6.5vw;
              margin-bottom: 0;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            hr {
              background-color: $primary;
              margin: 1vw 0;
            }

            h5 {
              font-size: 10px;
              margin-bottom: 0;
              color: #787878;
              max-width: 100%;
              text-align: left;
            }
          }

          .right {
            padding-left: 3vw;

            .img {
              height: 26vw;
              overflow: hidden;

              img {
                height: auto;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

//人才战略
.page-strategy {
  .strategy-title {
    padding: 1rem 0;

    .row {
      .text {
        padding-right: 150px;

        p {
          font-size: 16px;
          margin-bottom: 0;
          line-height: 40px;
          text-indent: 2rem;

          span {
            font-size: 30px;
            font-weight: bold;
            padding-right: 5px;
          }
        }
      }
    }
  }

  .strategy-content {
    padding: 50px 0;

    .text {
      padding: 30px 140px;
      background: linear-gradient(0deg, $secondary, $primary);

      h5 {
        color: #ffffff;
        font-size: 18px;
        line-height: 40px;
        margin-bottom: 0;
        letter-spacing: 2px;
      }
    }
  }

  //992以下
  @media (max-width: 992px) {
    .strategy-title {
      padding: 0 0;

      .row {
        .text {
          padding-right: 0;
          padding-bottom: 5vw;

          p {
            font-size: 14px;
            margin-bottom: 0;
            line-height: 7.5vw;
            text-indent: 2rem;

            span {
              font-size: 22px;
              font-weight: bold;
              padding-right: 5px;
            }
          }
        }

        img {
          width: 100%;
        }
      }
    }

    .strategy-content {
      padding: 5vw 0 10vw;

      .text {
        padding: 5vw 5vw;
        background: linear-gradient(0deg, $secondary, $primary);

        h5 {
          color: #ffffff;
          font-size: 13px;
          line-height: 6vw;
          margin-bottom: 0;
          letter-spacing: 1px;
        }
      }
    }
  }
}

//社会招聘
.page-social {
  .social-table {
    padding: 1rem 0 8rem;

    .table {
      text-align: center;

      .title {
        font-size: 18px;

        th {
          padding: 1.5rem 0;
        }
      }

      .table-content {
        font-size: 16px;

        tr {
          background-color: #ffffff;
          transition: all .36s ease;
          color: #000000;
          position: relative;

          &:hover {
            background: linear-gradient(0deg, $secondary, $primary);
            color: #ffffff;
          }
        }

        td {
          padding: 1.5rem 0;
        }
      }
    }
  }

  //992以下
  @media (max-width: 992px) {
    .social-table {
      padding: 0 0 10vw;

      .table {
        text-align: center;

        .title {
          font-size: 14px;

          th {
            padding: 5vw 0;

            &:nth-last-child(1) {
              display: none;
            }
          }

        }

        .table-content {
          font-size: 12px;

          tr {
            background-color: #ffffff;
            transition: all .36s ease;
            color: #000000;
            position: relative;

            &:hover {
              background: linear-gradient(0deg, $secondary, $primary);
              color: #ffffff;
            }
          }

          td {
            padding: 1.5rem 0;

            &:nth-last-child(1) {
              display: none;
            }
          }
        }
      }
    }
  }
}

//简历
.page-resume {
  .resume-title {
    padding: 3rem 0 20px;

    h1 {
      font-size: 25px;
      font-weight: bold;
      color: $primary;
      margin-bottom: 30px;
    }

    h4 {
      font-size: 16px;
      margin-bottom: 0;
      padding-right: 50px;
      color: #000000;
      padding-bottom: 20px;
    }

    hr {
      background-color: #dcdcdc;
      height: 2px;

    }
  }

  .resume-description {
    .responsibility {
      padding: 15px 0;

      img {
        width: 25px;
        padding-top: 0;
      }

      .text {
        padding-left: 15px;

        h3 {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #000000;
        }

        h5 {
          font-size: 15px;
          margin-bottom: 0;
          line-height: 35px;
          color: #4f4f4f;
        }
      }
    }
  }

  .resume-form {
    .title {
      background: linear-gradient(0deg, $secondary, $primary);
      padding: 25px 40px;
      margin: 20px 0;

      h1 {
        color: #ffffff;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 0;
        letter-spacing: 2px;
      }
    }

    .subtitle {
      h5 {
        color: #000;
        font-size: 18px;
        margin-bottom: 0;
      }

      hr {
        background-color: #a0a0a0;
        margin: 20px auto;
      }
    }

    .form-group {
      margin-bottom: 30px;

      .form-control {
        font-size: 16px;
        background-color: #eeeeee;
      }

      .form-control1 {
        height: 55px;
      }

      .form-control2 {
        line-height: 30px;
      }
    }

    .resume-submit {
      padding: 20px 0 100px;

      button {
        background: linear-gradient(0deg, $secondary, $primary);
        transition: all .36s ease;
        border: none;
        padding: 10px 70px;
        font-size: 17px;
        letter-spacing: 2px;
        border-radius: 0;
        margin: 20px 20px 30px;
        color: #ffffff;

        &:hover {
          background: linear-gradient(180deg, $secondary, $primary);
          transition: all .36s ease;
          color: #ffffff;
        }

        &:active {
          background: linear-gradient(180deg, $secondary, $primary);
          transition: all .36s ease;
          color: #ffffff;
        }

        &:focus {
          background: linear-gradient(180deg, $secondary, $primary);
          transition: all .36s ease;
          color: #ffffff;
        }
      }
    }
  }

  //992以下
  @media (max-width: 992px) {
    .resume-title {
      padding: 5vw 0;

      h1 {
        font-size: 20px;
        margin-bottom: 5vw;
      }

      h4 {
        font-size: 13px;
        margin-bottom: 0;
        padding-right: 2vw;
        padding-bottom: 5vw;
      }

      hr {
        background-color: #dcdcdc;
        height: 2px;
        margin: 0 auto;

      }
    }

    .resume-description {
      .responsibility {
        padding: 3vw 0;

        img {
          width: 20px;
        }

        .text {
          padding-left: 2vw;

          h3 {
            font-size: 17px;
            font-weight: bold;
            margin-bottom: 10px;
            color: #000000;
          }

          h5 {
            font-size: 13px;
            margin-bottom: 0;
            line-height: 7vw;
            color: #4f4f4f;
          }
        }
      }
    }

    .resume-form {
      .title {
        background: linear-gradient(0deg, $secondary, $primary);
        padding: 5vw 3vw;
        margin: 20px 0;

        h1 {
          color: #ffffff;
          font-size: 19px;
          font-weight: bold;
          margin-bottom: 0;
          letter-spacing: 2px;
        }
      }

      .subtitle {
        h5 {
          color: #000;
          font-size: 16px;
          margin-bottom: 0;
        }

        hr {
          background-color: #a0a0a0;
          margin: 4vw auto;
        }
      }

      .form-group {
        margin-bottom: 5vw;

        .form-control {
          font-size: 14px;
          background-color: #eeeeee;
        }

        .form-control1 {
          height: 10vw;
        }

        .form-control2 {
          line-height: 8vw;
        }
      }

      .resume-submit {
        padding: 5vw 0 12vw;

        button {
          background: linear-gradient(0deg, $secondary, $primary);
          transition: all .36s ease;
          border: none;
          padding: 2vw 7vw;
          font-size: 14px;
          letter-spacing: 2px;
          border-radius: 0;
          margin: 5vw 7vw 5vw;
          color: #ffffff;

          &:hover {
            background: linear-gradient(180deg, $secondary, $primary);
            transition: all .36s ease;
            color: #ffffff;
          }

          &:active {
            background: linear-gradient(180deg, $secondary, $primary);
            transition: all .36s ease;
            color: #ffffff;
          }

          &:focus {
            background: linear-gradient(180deg, $secondary, $primary);
            transition: all .36s ease;
            color: #ffffff;
          }
        }
      }
    }
  }
}