.mu-sidebar {
  display: none;
  background-color: $gray-900;

  .nav {
    flex-direction: column;
    padding: 40px 0;

    .nav-item {
      .nav-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $gray-500;
        padding: 0.75rem 0.75rem 0.75rem 1.25rem;
        border-bottom: 1px solid darken($gray-800, 5%);

        &.active {
          color: $primary;
        }

        i {
          font-size: 17px;
          margin-right: 0.25rem;
        }
      }

      .nav-submenu {
        display: flex;
        flex-direction: column;
        background-color: darken($gray-800, 5%);
        max-height: 0;
        overflow: hidden;
        transition: $transition;

        .nav-submenu-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: $gray-500;
          padding: 0.75rem 0.75rem 0.75rem 1.25rem;

          &.active {
            color: $primary;
          }

          &:hover {
            text-decoration: none;
          }
        }

        &.show {
          max-height: 500px;
        }
      }
    }
  }
}
