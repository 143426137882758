//文字只展示一行（无省略号）
.mu-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

//文字只展示两行（加省略号）
.mu-multi-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

//文字只展示三行（加省略号）
.mu-multi-ellipsis--l3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

//文字只展示四行（加省略号）
.mu-multi-ellipsis--l4 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  color: $gray-600;
  transition: $transition;
  &:hover {
    color: $primary;
    text-decoration: none;
  }
}

.sk-highlight {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
}
