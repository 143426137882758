.dropdown:hover {
  & > .dropdown-item {
    background-color: $gray-100;
  }
}

// 导航下拉菜单
.dropdown-menu {
  margin-top: -1px;
  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

// 三级以上菜单
.dropdown-submenu {
  .dropdown-menu {
    margin-top: 0;
    margin-left: -0.5rem;
  }
}
