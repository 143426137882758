.mu-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  padding: 0;
  background-color: $white;
  z-index: 2;

  .container {
    padding: 0;
  }

  .nav {
    font-size: 14px;
    width: 100%;

    .nav-link {
      flex: 1 1 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      color: $gray-800;
      border-top: 1px solid $border-color;
      border-left: 1px solid $border-color;
      i {
        padding-right: 5px;
      }
      &:first-child {
        border-left: 0;
      }
      @media (min-width: 576px) {
        &:first-child {
          border-left: 1px solid $border-color;
        }
      }
    }

    .nav-btn {
      flex: 1 1 40%;
      color: $white;
      background: $primary;
      border: 0;
    }
  }

  // 992px以上不显示
  @media (min-width: 992px) {
    display: none;
  }
}
